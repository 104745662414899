export default {
  'report.basicInfo.title': 'Cognitive Leap vCAT Report',
  'report.overview.title': 'Overview',
  'report.overview.api': 'Attention Performance Index',
  'report.overview.api.desc1': ' The API represents your overall attention performance in relation to your age and gender group. It is derived from the results of the 13 min Continuous Performance Test(CPT).',
  'report.overview.api.desc2': 'The higher the score, the better one performed at maintaining focus during the test.',
  'report.overview.api.desc3_1': 'The attention performance index is ',
  'report.overview.api.desc3_2': ', indicating that your attention performance is better than ',
  'report.overview.api.desc3_3': ' of your age and gender group.',
  'report.overview.mpi': 'Motion Performance Index',
  'report.overview.mpi.desc1': 'The MPI represents your degree of hyperactivity in relation to your age and gender group, and is calculated from the movement data of the head.',
  'report.overview.mpi.desc2': 'The higher the score, the less one moved during the test - meaning less hyperactivity.',
  'report.overview.mpi.desc3': 'The motion performance index is 83, indicating that your motion performance is better than 83% of your age and gender group.',
  'report.overview.mpi.desc3_1': 'The motion performance index is ',
  'report.overview.mpi.desc3_2': ', indicating that your motion performance is better than ',
  'report.overview.mpi.desc3_3': ' of your age and gender group.',
  'report.realTime.title': 'Real Time Performance',
  'report.realTime.chart.legend': 'How much the child moved during the test',
  'report.realTime.chart.xAxis': 'Minutes',
  'report.realTime.chart.yAxisl': 'Reaction Time (ms)',
  'report.realTime.chart.yAxisr': 'Motion Index',
  'report.realTime.chart.title': 'Attention and Motion Performance across time',
  'report.realTime.desc1': 'Correct Hits : ',
  'report.realTime.desc2': 'time the child didn\'t pay attention in the test.',
  'report.realTime.desc3': 'time the child was impulsive and made an answer even when there was nothing to answer.',
  'report.attentionIndex.title': 'Attention Index',
  'report.attentionIndex.chart.title': 'Brain areas involved in ATTENTION',
  'report.attentionSkill.title': 'Attention Skills',
  'report.attentionSkill.chart.legendW': 'Warning Line (50)',
  'report.attentionSkill.chart.legendU': 'You',
  'report.attentionSkill.chart.title': 'Six dimensions of attention skills',
  'report.attentionSkill.chart.attention': 'Attention',
  'report.attentionSkill.chart.self': 'Self-Control',
  'report.attentionSkill.chart.reactivity': 'Reactivity',
  'report.attentionSkill.chart.reactSta': 'Reaction Stability',
  'report.attentionSkill.chart.sensitivity': 'Sensitivity',
  'report.attentionSkill.chart.continuous': 'Continuous\nAttention',
  'report.attentionSkill.attention': 'How well you focused during the test. Higher score means higher accuracy.',
  'report.attentionSkill.self': 'How well you refrained from pushing the button when you are not supposed to. The higher the score, the stronger self-control you have.',
  'report.attentionSkill.reactivity': 'How quickly you managed to give the correct answer. The higher the score, the faster the reaction.',
  'report.attentionSkill.reactSta': 'How consistent one\'s accurate responses were. The higher the score, the more consistent one was in answering correctly.',
  'report.attentionSkill.sensitivity': 'How skillful you are in distinguishing the target from all other stimuli. The higher the score, the stronger your ability to distinguish the task from other disturbance stimuli.',
  'report.attentionSkill.continuous': 'How long you can stay focused on the test. The higher the score, the longer one is able to maintain a high level of attention.',
  'report.continuous.title': 'Continuous Attention',
  'report.continuous.chart.title': 'Changes in attention \nperformance over time',
  'report.continuous.chart.xAxis': 'Blocks',
  'report.continuous.chart.yAxis': 'Times',
  'report.continuous.chart.legendCA': 'Correct Answer',
  'report.continuous.chart.legendOE': 'Omission Error',
  'report.continuous.chart.legendCE': 'Commission Error',
  'report.continuous.notes': 'Notes:',
  'report.continuous.desc1': '3.25min per block, 4blocks x 3.25min = 13min test',
  'report.continuous.desc2': 'Correct Answer: Pressed the button that should be pressed',
  'report.continuous.desc3': 'Omission Error: Did not press the button that should be pressed',
  'report.continuous.desc4': 'Commission Error: Pressed the button that should not be pressed',
  'report.motionIndex.title': 'Motion Index',
  'report.motionIndex.chart.title': 'Brain areas involved in MOTION and EMOTION MANAGEMENT',
  'report.motionIndex.left.p1.title': 'Basal Ganglia:',
  'report.motionIndex.left.p1.desc1': 'Motion, action planning, motivational action, reward processing.',
  'report.motionIndex.left.p1.desc2': 'If the Basal ganglia dysfunction:',
  'report.motionIndex.left.p1.desc3': 'a) Cannot calculate risk-reward properly',
  'report.motionIndex.left.p1.desc4': 'b) Cannot find internal motivation to take action',
  'report.motionIndex.left.p2.title': 'Thalamus:',
  'report.motionIndex.left.p2.desc1': 'Control response inhibition. If this gate is broken, it\'s hard to suppress impulse.',
  'report.motionIndex.left.p3.title': 'Amygdala:',
  'report.motionIndex.left.p3.desc1': 'Emotional regulation, emotional stimulus processing.',
  'report.motionIndex.left.p3.desc2': 'If the Amygdala dysfunction:',
  'report.motionIndex.left.p3.desc3': 'a) cannot regulate emotion',
  'report.motionIndex.left.p3.desc4': 'b) can display callous, seemingly coldhearted traits',
  'report.motionIndex.right.desc1': 'The VR system allows us to measure a user’s movement during the test. Since inattention can be expressed along with hyperactivity and impulsivity, the user’s motion data helps us to identify how often the user was moving their body, fidgeting around, and not paying attention to the task at hand.',
  'report.motionIndex.right.desc2': "We collect the motion data from the head and hand, and comprehensively calculate the user's Motion Performance Index.",
  'report.movementPath.title': 'Movement Path',
  'report.movementPath.chart.title': 'Movement path of different positions changes over time',
  'report.movementPath.chart.des': 'The length of the green frame is the same as the length of the desk in the virtual reality classroom.',
  'report.movementPath.chart.head': 'Head',
  'report.movementPath.chart.hand': 'Hand',
  'report.movementPath.chart.xAxis': 'Blocks',
  'report.movementPath.chart.yAxis': 'Movement Path',
  'report.pathLength.title': 'Path Length',
  'report.pathLength.head': 'Head',
  'report.pathLength.hand': 'Hand',
  'report.pathLength.chart.subtitle': ' movement changes over time',
  'report.pathLength.chart.xAxis': 'Blocks',
  'report.pathLength.chart.yAxis': 'Path Length',
  'report.pathLength.chart.legendU': 'You',
  'report.pathLength.chart.legendM': 'Median',
  'report.pathLength.percentile': 'Percentile : ',
  'report.directionTracking.title': 'Direction Tracking',
  'report.directionTracking.chart.title': 'Where were you looking?',
  'report.directionTracking.p1.desc1': 'Your attention time on the Whiteboard is ',
  'report.directionTracking.p1.desc2': ' minutes, longer than ',
  'report.directionTracking.p1.desc3': ' of peers.',
  'report.directionTracking.p2': 'This graph shows where you were looking during the test.',
  'report.directionTracking.p3': 'The longer the blue lines, the more time you spent looking in that direction.',
  'report.directionTracking.p4': 'The wider the blue area, the further you looked away from the center whiteboard during the test.',
  'report.motionCharacter.title': 'Motion Characteristics',
  'report.motionCharacter.hyperactivity': 'Hyperactivity Index',
  'report.motionCharacter.motionArea': 'Motion Area Index',
  'report.motionCharacter.chart.title': 'How you compared with others in different motion Index',
  'report.motionCharacter.you': ' ',
  'report.motionCharacter.hyperactivity.desc': 'How much you moved during the test. Frequent movements with long periods will be closer to the red area.',
  'report.motionCharacter.motionArea.desc': 'Area of head movements. If the range of actions is concentrated, percentile will be closer to the green area.',
  'report.offlinePlanInfo.sure.title': 'Arrange the training plan for the subject?',
  'report.offlinePlanInfo.cancel.title': 'Are you sure to end the session ?',
}
