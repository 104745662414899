
export const RAF = {
    intervalTimer: null,
    timeoutTimer: null,
    rafSetTimeout(callback, interval) { // 实现setTimeout功能
        let now = Date.now
        let startTime = now()
        let endTime = startTime
        let loop = () => {
            RAF.timeoutTimer = requestAnimationFrame(loop)
            endTime = now()
            if (endTime - startTime >= interval) {
                callback()
                cancelAnimationFrame(RAF.timeoutTimer)
            }
        }
        RAF.timeoutTimer = requestAnimationFrame(loop)
        return RAF.timeoutTimer
    },
    rafClearTimeout() {
        cancelAnimationFrame(RAF.timeoutTimer)
    },
    rafSetInterval(callback, interval) { // 实现setInterval功能
        let now = Date.now
        let startTime = now()
        let endTime = startTime
        let loop = () => {
            RAF.intervalTimer = requestAnimationFrame(loop)
            endTime = now()
            if (endTime - startTime >= interval) {
                startTime = now()
                endTime = startTime
                callback()
            }
        }
        RAF.intervalTimer = requestAnimationFrame(loop)
        return RAF.intervalTimer
    },
    rafClearInterval() {
        cancelAnimationFrame(RAF.intervalTimer)
    }
}
