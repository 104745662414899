export default {
  'CoverPage.powered': 'Diseñado por Cognitive Leap Solutions Inc.',
  'CoverPage.title': 'Informe vCAT™ Edición para Padres',
  'CoverPage.two': 'TUS RESULTADOS DE ATENCIÓN',

  'WelcomeLetters.title': 'Carta de Bienvenida',
  'WelcomeLetters.parents': 'Estimados padres y madres de familia,',
  'WelcomeLetters.attention': 'Se ha evaluado el rendimiento de atención de su hijo/a utilizando el Test vCAT™, que se basa en la tecnología más actualizada utilizando realidad virtual (VR).',
  'WelcomeLetters.indicators': 'Este informe muestra 2 índices generales, 7 indicadores de atención cognitiva y 4 indicadores de atención motora.',
  'WelcomeLetters.discriminability': 'Al utilizar el Test vCAT™, se han medido varias variables: Atención Focalizada y Discriminabilidad, que están relacionadas con la atención a los detalles; Velocidad y Estabilidad de procesamiento, que están relacionadas con la impulsividad; y los Movimientos del niño durante la evaluación, especialmente los movimientos de las manos y la cabeza lejos del asiento frente a la pizarra en el aula virtual.',
  'WelcomeLetters.information': 'Esta información es muy importante en relación con la vida cotidiana, porque nos puede decir si su hijo/a presta atención a la tarea que tiene enfrente y si puede mantener su atención, y durante cuánto tiempo. Esto nos ayuda a entender cómo su hijo/a presta atención a los detalles, como al hacer tareas o proyectos en casa y en la escuela. Además, esta información nos dice si el estudiante tiende a responder de manera impulsiva, como interrumpiendo una conversación; o si responde a las preguntas de los exámenes sin tomarse el tiempo para pensar o leer, lo que indica falta de autocontrol. Esa información también nos indica si la hiperactividad hace que su hijo/a se mueva de manera inquieta y se aleje o deambule cuando debería estar sentado/a en su escritorio.',
  'WelcomeLetters.scores': 'Las puntuaciones informadas son percentiles, lo que indica la posición relativa del niño/a evaluado/a en comparación con su grupo de pares de la misma edad y género. Por lo tanto, si un niño/a obtiene una puntuación del 88 en el API (Índice de Rendimiento de Atención), significa que su nivel de atención está por encima del 88% de sus pares.',
  'WelcomeLetters.believe': 'Creemos que este informe será una herramienta valiosa en sus esfuerzos por apoyar el crecimiento y desarrollo de su hijo/a, y estamos aquí para responder cualquier duda que puedan tener. Si hay algo más que podamos hacer para apoyarlos, no duden en comunicarse con nosotros.',
  'WelcomeLetters.regards': 'Atentamente,',
  'WelcomeLetters.team': 'El Equipo vCAT™',

  'BottomDes.EDITION': 'vCAT™ REPORTE - EDICIÓN DE PADRES',

  'ChildInfo.centerTitle': 'Información del Evaluado',
  'ChildInfo.clientName': 'Nombre',
  'ChildInfo.birth': 'Fecha de Nacimiento',
  'ChildInfo.age': 'Edad',
  'ChildInfo.gender': 'Sexo',
  'ChildInfo.percentile': 'Grupo Percentil',
  'ChildInfo.date': 'Fecha de Evaluación',
  'ChildInfo.center': 'Centro del Test',
  'ChildInfo.id': 'ID del Caso',
  'ChildInfo.version': 'Versión del Test',
  'ChildInfo.report': 'Versión del Reporte',
  'ChildInfo.yrs': '{num} años',

  'FullScore.title': 'Informe de Resultados',
  'FullScore.overall': 'Índice General',
  'FullScore.scores': 'Puntuaciones de atención cognitiva',
  'FullScore.motor': 'Puntuaciones de atención motora',
  'FullScore.attention': 'Índice del Rendimiento de la Atención',
  'FullScore.motion': 'Índice del Rendimiento del Movimiento',
  'FullScore.focused': 'Atención Focalizada',
  'FullScore.self': 'Autocontrol',
  'FullScore.accuracy': 'Precisión',
  'FullScore.discriminability': 'Discriminabilidad',
  'FullScore.speed': 'Velocidad de Procesamiento',
  'FullScore.stability': 'Estabilidad de Procesamiento',
  'FullScore.endurance': 'Resistencia Mental',
  'FullScore.activityIndex': 'Índice de actividad',
  'FullScore.areaIndex': 'Índice del Área de Movimiento',
  'FullScore.direction': 'Seguimiento de Dirección',
  'FullScore.distance': 'Distancia de Movimiento',
  'FullScore.low': 'Bajo: 0-33',
  'FullScore.average': 'Promedio: 34-66',
  'FullScore.high': 'Alto: 67-100',
  'FullScore.distractions': 'La puntuación del API indica la capacidad para mantener el enfoque y filtrar las distracciones. ',
  'FullScore.hyperactivity': 'La puntuación MPI indica la capacidad de un niño/a para controlar la impulsividad y la hiperactividad.',
  'FullScore.due': 'La puntuación de Atención Focalizada indica la capacidad de un niño/a para prestar atención sin perder información importante debido a distracciones. ',
  'FullScore.errors': 'La puntuación de Autocontrol indica la capacidad de un niño/a para evitar errores impulsivos.',
  'FullScore.answers': 'La puntuación de Precisión indica la capacidad de un niño/a para dar respuestas correctas.',
  'FullScore.discriminabilitys': 'La puntuación de Discriminabilidad indica la capacidad de un niño/a para distinguir entre información relevante e irrelevante.',
  'FullScore.stimuli': 'La puntuación de Velocidad de Procesamiento indica la rapidez con la que un niño/a procesa y responde a los estímulos. ',
  'FullScore.processing': 'La puntuación de Estabilidad de Procesamiento indica la capacidad de un niño/a para regular la atención.',
  'FullScore.enduranceScore': 'La puntuación de Resistencia Mental indica la capacidad de un niño/a para mantener el foco de manera consistente durante un periodo de tiempo.',
  'FullScore.restlessness': 'La puntuación del Índice de Actividad indica la tendencia de un niño/a hacia la inquietud o la actividad excesiva.',
  'FullScore.movements': 'La puntuación del Índice de Área del Movimiento indica la capacidad de un niño/a para regular sus movimientos.',
  'FullScore.tracking': 'La puntuación de Seguimiento de Dirección indica la capacidad de un niño/a para ignorar estímulos distractores en el entorno.',
  'FullScore.position': 'La puntuación de Distancia de Movimiento indica la capacidad de un niño/a para mantener una posición constante. ',
  'FullScore.parameter': 'Resultados del Parámetro',
  'FullScore.explanation': 'Explicación del Parámetro',
  'FullScore.percentile': 'Puntuación Percentil',

  'OverallResults.title': 'Desempeño General',
  'OverallResults.performance': 'Tu desempeño general está dividido en dos índices de puntuación: ',
  'OverallResults.attentionIndex': 'Índice del Rendimiento de la Atención: {attentionPerformance}',
  'OverallResults.motionIndex': 'Índice del Rendimiento del Movimiento: {motionPerformance}',
  'OverallResults.attention': 'Índice de rendimiento de la atención',
  'OverallResults.motion': 'Índice de rendimiento del Movimiento',
  'OverallResults.attentionPerformance': 'El Índice de Rendimiento de Atención representa tu habilidad general para:',
  'OverallResults.motionPerformance': 'El Índice de Rendimiento del Movimiento representa tu habilidad general para:',
  'OverallResults.attentionIs': 'Tu Índice de rendimiento de Atención es de {attentionPerformance}',
  'OverallResults.motionIs': 'Tu Índice de Rendimiento del Movimiento es de {motionPerformance}',
  'OverallResults.attentionSkills': 'Esto significa que tus habilidades generales de atención mental se sitúan en el {attentionPerformance}% en comparación {sex} edad de {age} años.',
  'OverallResults.physically': 'Mantener físicamente quieto/a durante los 13 minutos mientras te concentras en la tarea que tienes.',
  'OverallResults.maintain': 'Mantener el autocontrol para evitar mirar o moverte hacia cosas que te distraen a tu alrededor.',
  'OverallResults.motorSkills': 'Esto significa que tus habilidades generales de atención motora se sitúan en el {motionPerformance}% en comparación con {sex} edad de {age} años',
  'OverallResults.mentally13': 'Mantener el enfoque mental durante un periodo de 13 minutos en el aula virtual.',
  'OverallResults.remember': 'Recordar las reglas de la tarea y hacer todo lo posible para completarla.',
  'OverallResults.mentally': 'Ignorar mentalmente las distracciones que ocurren a tu alrededor.',
  'OverallResults.boys': 'muchachos',
  'OverallResults.girls': 'muchachas',

  'AttentionScores.title': 'Puntuaciones de Atención Cognitiva',
  'AttentionScores.graphs': 'Este gráfico muestra seis aspectos de habilidades de atención cognitiva medidas por vCAT™. ',
  'AttentionScores.higher': ' Cuanto mayor sea la puntuación, mejor. Si la puntuación se encuentra en la zona blanca, esto significa que obtuviste una puntuación por encima del percentil 50. Si la puntuación se encuentra en la',
  'AttentionScores.red': ' zona roja',
  'AttentionScores.means': 'esto significa que obtuviste una puntuación por debajo del percentil.',
  'AttentionScores.reference': 'Cita paterna',
  'AttentionScores.omission': 'Atención Focalizada',
  'AttentionScores.focused': 'Puntuación de error de omisión (objetivo no detectado).',
  'AttentionScores.mental': 'Resistencia Mental',
  'AttentionScores.score': 'Puntuación de índice de variabilidad de rendimiento a lo largo del tiempo.',
  'AttentionScores.discriminability': 'Discriminabilidad',
  'AttentionScores.prime': 'Puntuación d’Prime.',
  'AttentionScores.stability': 'Estabilidad del Procesamient',
  'AttentionScores.reaction': 'Puntuación de variabilidad en el tiempo de reacción.',
  'AttentionScores.speed': 'Velocidad de Procesamiento',
  'AttentionScores.reactionScore': 'Puntuación del tiempo de reacción.',
  'AttentionScores.self': 'Autocontrol',
  'AttentionScores.commission': 'Puntuación de error por comisión (responder cuando no hay un objetivo).',

  'FirstScoresDes.scoresTitle': 'Sus Puntuaciones en Atención Cognitiva',
  'FirstScoresDes.titleDes': 'El vCAT™ contiene una gran cantidad de información que se puede interpretar a partir de los resultados del desempeño. Esta información se puede dividir en resultados de Atención Cognitiva y resultados de Atención Motora. A continuación, se presentan referencias rápidas que explican qué significa cada resultado del parámetro.',
  'FirstScoresDes.attention': 'Atención Focalizada',
  'FirstScoresDes.refers': 'Se refiere a qué tan bien puede concentrarse en la tarea en cuestión, mientras supera distracciones “internas” como pensamientos e impulsos, así como distracciones “externas” como sonidos y estímulos visuales en su entorno. El error de omisión (no respondió cuando debió hacerlo) es la medida de esto.',
  'FirstScoresDes.focused': 'Su puntuación de Atención Focalizada es de {attention}. Esto significa que obtuvo una puntuación {_Attention} para su grupo de edad y género.',
  'FirstScoresDes.mental': 'Resistencia Mental',
  'FirstScoresDes.represents': 'Esto representa qué tan bien puede mantener el foco durante un periodo de tiempo. Se ve afectado por su capacidad para prestar atención a lo que está sucediendo al frente y superar distracciones internas y externas, al mismo tiempo que recuerda las instrucciones de la tarea actual.',
  'FirstScoresDes.endurance': 'Su puntuación de Resistencia Mental es de {continuousAttention}. Esto significa que obtuvo una puntuación {_ContinuousAttention} para su grupo de edad y género. ',
  'FirstScoresDes.discriminability': 'Discriminabilidad',
  'FirstScoresDes.discriminate': 'Esto representa la capacidad para discriminar o diferenciar entre respuestas correctas e incorrectas. Se ve afectado por su concentración, su capacidad para ignorar distracciones, su capacidad para comprender las instrucciones de la tarea y la capacidad para tomar decisiones dentro del plazo requerido.',
  'FirstScoresDes.gender': 'Su puntuación de disciminabilidad es de {sensitivity}. Esto significa que obtuvo una puntuación {_Sensitivity} para su grupo de edad y género.',

  'TwoScoresDes.title': 'Continuación de sus puntuaciones en Atención Cognitiva',
  'TwoScoresDes.speed': 'Velocidad de Procesamiento ',
  'TwoScoresDes.represents': 'Esto representa su tiempo promedio para responder correctamente a un objetivo en todo el test. Se ve afectado por su Atención Focalizada, la Resistencia mental, la capacidad intelectual y su capacidad de procesamiento sensorial. ',
  'TwoScoresDes.secondDes': 'Tu puntuación de Velocidad de Procesamiento es {reactivity}. Esto significa que obtuvo una puntuación {_Reactivity} para su grupo de edad y género. ',
  'TwoScoresDes.stability': 'Estabilidad de Procesamiento',
  'TwoScoresDes.consistently': 'Esto representa su capacidad para responder de manera constante y correcta al mismo ritmo a lo largo del tiempo. Esto es una combinación de su resistencia mental junto con su velocidad de procesamiento. ',
  'TwoScoresDes.processing': 'Su puntuación de Estabilidad de Procesamiento es de {reactionStability}. Esto significa que obtuvo una puntuación {_ReactionStability} para su edad y género. ',
  'TwoScoresDes.self': 'Autocontrol',
  'TwoScoresDes.impulse': 'Esto representa su capacidad para superar el impulso o la urgencia de responder de manera incorrecta. Se ve afectado por su capacidad para concentrarse (Atención Focalizada), diferenciar entre respuestas correctas e incorrectas (Discriminabilidad), así como por su capacidad para inhibir cualquier impulso o urgencia de responder de manera incorrecta en un periodo de tiempo muy corto.',
  'TwoScoresDes.scored': 'Tu puntuación de Autocontrol es {selfControl}. Esto significa que obtuviste una puntuación {_SelfControl} para tu grupo de edad y género.',

  'MentalEndurance.title': 'Resistencia Mental',
  'MentalEndurance.progression': 'Este gráfico analiza la progresión de tu rendimiento de atención cognitiva a lo largo del tiempo. Aquí se divide el test de 13 minutos en cuatro bloques iguales a 3,25 minutos cada uno y muestra tres indicadores diferentes de habilidades de atención.',
  'MentalEndurance.attention': 'Cambios en el rendimiento de la atención cognitiva, en el tiempo.',
  'MentalEndurance.correct': 'Respuestas Correctas',
  'MentalEndurance.omission': 'Errores por Omisión',
  'MentalEndurance.commission': 'Errores por Comisión',

  'MentalEnduranceTwo.centerTitle': 'Resistencia Mental',
  'MentalEnduranceTwo.block1': 'Bloque 1',
  'MentalEnduranceTwo.block2': 'Bloque 2',
  'MentalEnduranceTwo.block3': 'Bloque 3',
  'MentalEnduranceTwo.block4': 'Bloque 4',
  'MentalEnduranceTwo.paid': 'Atención Prestada',
  'MentalEnduranceTwo.distracted': 'Distractibilidad',
  'MentalEnduranceTwo.lacked': 'Falta de Autocontrol',
  'MentalEnduranceTwo.green': 'Barras verdes',
  'MentalEnduranceTwo.yellow': 'Barras amarillas',
  'MentalEnduranceTwo.red': 'Barras rojas',
  'MentalEnduranceTwo.well': 'El % de puntuación representa qué tan bien prestó atención. Se calcula a partir de cuántas respuestas correctas dio en ese bloque.',
  'MentalEnduranceTwo.distracte': 'El % de puntuación representa qué tan distraído/a estuvo. Se calcula a partir de cuántos errores de omisión (respuestas correctas omitidas) realizó en ese bloque.',
  'MentalEnduranceTwo.much': 'El % de puntuación representa cuánto careció de autocontrol. Se calcula a partir de cuántos errores de comisión (respondió cuando no debía hacerlo) realizó en ese bloque.',

  'RealtimePerformance.title': 'Rendimiento en Tiempo Real',
  'RealtimePerformance.titleDes': 'Este gráfico Brinda una visión general de todo el test de 13 minutos. En el gráfico, la coordenada x de un punto representa cuándo el evaluado dio su respuesta. La coordenada y de un punto representa cuánto tiempo le tomó para dar su respuesta.',
  'RealtimePerformance.green': 'Puntos verdes',
  'RealtimePerformance.correct': 'representa cuándo dio una respuesta correcta.',
  'RealtimePerformance.yellow': 'Puntos amarillos',
  'RealtimePerformance.omission': 'representa cuándo cometió un error de omisión (omitir una respuesta correcta).',
  'RealtimePerformance.red': 'Puntos rojos',
  'RealtimePerformance.wrongly': 'representa cuándo cometió un error de comisión (presionar de manera incorrecta el botón, incluso cuando no hay un objetivo correcto).',
  'RealtimePerformance.blueLine': 'Línea azul',
  'RealtimePerformance.moving': 'representa cuánto se mueve su cabeza en cada momento. ',
  'RealtimePerformance.correctly': 'Respondió correctamente el {correctlyPercentile}% de todos los objetivos.',
  'RealtimePerformance.missPercentile': 'Falló el {missPercentile}% de todos los objetivos.',
  'RealtimePerformance.errorPercentile': '{errorPercentile}% de sus respuestas estaban relacionadas a la ausencia de autocontrol.',
  'RealtimePerformance.answer': 'Respuestas Correctas',
  'RealtimePerformance.err': 'Errores por Omisión',
  'RealtimePerformance.commission': 'Errores por Comisión',
  'RealtimePerformance.amplitude': 'Amplitud de Movimiento de la Cabeza',

  'RealtimePerformanceTwo.title': 'Continuación del Rendimiento en Tiempo Real',
  'RealtimePerformanceTwo.visual': 'Distractor Visual',
  'RealtimePerformanceTwo.auditory': 'Distractor Auditivo',
  'RealtimePerformanceTwo.mixed': 'Distractor Mixto',
  'RealtimePerformanceTwo.answer': 'Respuestas Correctas',
  'RealtimePerformanceTwo.err': 'Errores por Omisión',
  'RealtimePerformanceTwo.commission': 'Errores por Comisión',
  'RealtimePerformanceTwo.amplitude': 'Amplitud de Movimiento de la Cabeza',

  'MotorAttention.title': 'Índices de Atención Motora',
  'MotorAttention.measures': 'vCAT™ mide los movimientos físicos durante la evaluación para calcular las Puntuaciones de Atención Motora. El rendimiento general de Atención Motora se dividen en dos puntuaciones:',
  'MotorAttention.activity': 'Índice de Actividad: {hyperactivityIndex}',
  'MotorAttention.motion': 'Índice de Área de Movimiento: {areaIndex}',
  'MotorAttention.activityIndex': 'Índice de Actividad',
  'MotorAttention.motionArea': 'Índice de Área de Movimiento',
  'MotorAttention.represents': 'El índice de Actividad representa la proporción de tiempo dedicado a mover la cabeza. Puntuaciones más altas significan movimientos menos frecuentes durante periodos de tiempo más largos.',
  'MotorAttention.hyperactivityIndex': 'Su Índice de Actividad es  {hyperactivityIndex}.',
  'MotorAttention.movements': 'El índice de Área de Movimiento representa el rango o área de movimientos de la cabeza. Puntuaciones altas significan que el movimiento fue espacialmente limitado.',
  'MotorAttention.areaIndex': 'Su Índice de Área de Movimiento es {areaIndex}.',

  'Interpretation': 'Interpretación',
  'Interpretation.Api': 'API',
  'Interpretation.Api.Des': 'Una puntuación de percentil bajo en el API puede indicar que la persona tiene dificultad para mantener la atención sostenida y puede tener problemas para mantener el enfoque durante un período prolongado de tiempo. También pueden tener dificultades para filtrar distracciones y regular su atención. ',
  'Interpretation.Mpi': 'MPI',
  'Interpretation.Mpi.Des': 'Una puntuación de percentil bajo en el MPI puede indicar que la persona tiene dificultad con la impulsividad y la hiperactividad. Pueden tener dificultad para controlar sus movimientos y pueden parecer inquietos o nerviosos. ',
  'Interpretation.OmissionError': 'Error de Omisión',
  'Interpretation.OmissionError.Des': 'Una puntuación de percentil bajo en esta variable puede indicar que la persona tiende a perder información o estímulos importantes y puede tener dificultad con la atención sostenida y el enfoque. ',
  'Interpretation.CommissionError': 'Error de Comisión',
  'Interpretation.CommissionError.Des': 'Una puntuación de percentil bajo en esta variable puede indicar que la persona tiende a cometer errores impulsivos y puede tener dificultad para inhibir respuestas inapropiadas.',
  'Interpretation.Accuracy': 'Exactitud',
  'Interpretation.Accuracy.Des': 'Una puntuación de percentil bajo en la exactitud puede indicar que la persona tiende a cometer errores y puede tener dificultad para procesar información de manera eficiente.',
  'Interpretation.Prime': "d'Prime",
  'Interpretation.Prime.Des': 'Una puntuación de percentil bajo en d’Prime puede indicar que la persona tiene dificultad para distinguir entre estímulos objetivo y no objetivo, lo que puede indicar un déficit de atención y en el procesamiento sensorial. ',
  'Interpretation.ReactionTime': 'Tiempo de Reacción',
  'Interpretation.ReactionTime.Des': 'Una puntuación de percentil bajo en el tiempo de reacción puede indicar que la persona es lenta para procesar y responder a estímulos, lo que puede indicar un déficit en la atención, la velocidad de procesamiento o la memoria de trabajo. ',
  'Interpretation.Continuous': 'Índice de Atención Continua',
  'Interpretation.Continuous.Des': 'Una puntuación de percentil bajo en el índice de atención continua puede indicar que la persona tiene dificultad para mantener el enfoque de manera consistente durante un periodo de tiempo para cumplir con la tarea en cuestión mientras recuerda las reglas y el contexto en cada momento en desarrollo.',
  'Interpretation.ScanQrDes': 'Para los niños con dificultades de atención, hemos preparado soluciones profesionales.\nEscanea el Código QR de abajo para ver las recomendaciones para su caso.',
  'Interpretation.ScanQr.Failed': 'Generation Failed',
  'Interpretation.ScanQr.Failed.Des': 'Please refresh the page to try generating a new QR code',
  'MovementDistance': 'Distancia del Movimiento',
  'MovementDistance.DesBold': 'Estos gráficos muestran la distancia de los movimientos de la cabeza y las manos durante cada uno de los 4 bloques de la evaluación. ',
  'MovementDistance.BlueLine': 'líneas azules',
  'MovementDistance.GreenLine': 'líneas verdes',
  'MovementDistance.The': 'Las',
  'MovementDistance.FirstDes': ', indican la Distancia de Movimiento. Un índice más alto de la Distancia de Movimiento mostrada en el eje vertical significa que se realizaron más movimientos durante la evaluación.',
  'MovementDistance.TwoDes': ' indican la mediana de la Distancia de Movimiento para su grupo de edad y género. Una línea azul por encima de la línea verde indica que la Distancia de Movimiento en ese bloque es mayor que la mediana para su grupo de edad y género.',
  'MovementDistance.BottomDes': 'La Distancia de Movimiento muestra cuánto se estaba moviendo durante la evaluación, tanto en términos de cantidad de movimientos como en la amplitud de esos movimientos. ',
  'MovementDistance.HeadMovement': 'Cambios en el movimiento de la cabeza durante la prueba',
  'MovementDistance.HandMovement': 'Cambios en el movimiento de la mano durante la prueba',
  'Charts.PathLength': 'Longitud de la trayectoria',
  'Charts.You': 'Usted',
  'Charts.Median': 'Mediana',
  'MovementDistance.Percentile': 'Percentil',
  'Charts.Blocks': 'Bloques',
  'MovementDistance.Remained': 'El Movimiento de tu Cabeza se mantuvo dentro del rango de movimiento en los 4 bloques.',
  'MovementDistance.Exceeded': 'El Movimiento de tu Cabeza sobrepasó el rango de movimiento en los 4 bloques.',
  'MovementDistance.SomeExceeded': 'El Movimiento de tu Cabeza sobrepasó el rango de movimiento en el bloque {result}.',
  'FirstBlock': '1ro',
  'TwoBlock': '2do',
  'ThreeBlock': '3ro',
  'FourBlock': '4to',
  'MovementPath': 'Tendendencia del Movimiento',
  'MovementPath.DesBold': 'Estos gráficos muestran el recorrido y área de los movimientos de la cabeza y las manos durante cada uno de los 4 bloques de evaluación. ',
  'MovementPath.BlueLine': 'líneas azules',
  'MovementPath.GreenLine': 'línea puntuada de color verde',
  'MovementPath.FirstDes': 'Cuanto más pequeña sea el área y el rango de las',
  'MovementPath.TwoDes': ', más se mantuvo en el asiento. Cuanto más grande sea el área y el rango de las ',
  'MovementPath.ThreeDes': ', más se alejó de su asiento. Un rango de movimiento fuera de la ',
  'MovementPath.FourDes': ' significa que excede los 20 cm (7.9 in.). ',
  'MovementPath.Head': 'Cabeza',
  'MovementPath.Hand': 'Mano',
  'Charts.MovementRange': 'Rango de Movimiento (20cm, 7.9in.)',
  'MovementPath.BottomDes': 'La Tendencia de Movimiento muestra si permaneció sentado/a durante la evaluación o si no pudo mantenerse quieto/a y movió la cabeza y las manos. ',
  'MovementPath.Remained': 'El Movimiento de tu Cabeza se mantuvo dentro del rango de movimiento en los 4 bloques.',
  'MovementPath.Exceeded': 'El Movimiento de tu Cabeza sobrepasó el rango de movimiento en los 4 bloques.',
  'MovementPath.SomeExceeded': 'El Movimiento de tu Cabeza sobrepasó el rango de movimiento en el bloque {result}.',
  'DirectionTracking': 'Seguimiento de la Dirección del Movimiento',
  'DirectionTracking.TitleDes': 'Este gráfico muestra en qué dirección estaba mirando durante la evaluación. ',
  'DirectionTracking.BlueLine': 'líneas azules',
  'DirectionTracking.FirstDes': 'Cuanto más largas sean las',
  'DirectionTracking.TwoDes': ', más tiempo pasó mirando en esa dirección. ',
  'DirectionTracking.ThreeDes': 'Cuanto más anchas sean las ',
  'DirectionTracking.FourDes': ', más se alejó la mirada de la pizarra central durante la evaluación.',
  'DirectionTracking.WhereLooking': '¿A dónde estaba mirando?',
  'DirectionTracking.BottomDes': 'El seguimiento de dirección muestra si estaba prestando atención a la pizarra central o si se distraía con otras cosas del entorno que le hacían mirar en otra dirección.',
  'DirectionTracking.Bottom.FirstResult': 'Usted pasó el {minutes} del tiempo prestando atención a la pizarra. ',
  'DirectionTracking.Bottom.TwoResult': 'Su posición es del {percent} en comparación con aquellos de su grupo de edad y género.',

  'TableOfContents.centerTitle': 'Contenido',
  'TableOfContents.information': 'Información del Estudiante',
  'TableOfContents.summary': 'Informe de Resultados',
  'TableOfContents.overall': 'Desempeño General: Índices de Rendimiento de Atención y Movimiento',
  'TableOfContents.cognitive': 'Puntuaciones de Atención Cognitiva',
  'TableOfContents.mental': 'Resistencia Mental',
  'TableOfContents.real': 'Rendimiento en Tiempo Real',
  'TableOfContents.motor': 'Índices de Atención Motora',
  'TableOfContents.tracking': 'Seguimiento de la Dirección del Movimiento',
  'TableOfContents.movement': 'Tendencia al Movimiento',
  'TableOfContents.distance': 'Distancia del Movimiento',
  'TableOfContents.interpretation': 'Interpretación',

  'MuchBelowAverage': 'muy por debajo del promedio',
  'BelowAverage': 'por debajo del promedio',
  'AboveAverage': 'por encima del promedio',
  'MuchAboveAverage': 'muy por encima del promedio',

  'Charts.Times': 'Tiempos',
  'Charts.MotionIndex': 'Índice de movimiento',
  'Chats.Time.Minute': 'Tiempo (minutos)',
  'Charts.Minutes': 'Minutos',
  'Charts.Reaction.Time': 'Tiempo de Reacción (ms))',
  'TextNA': 'N/A',

  'radarChart.percentile': 'Percentil 50',
  'radarChart.focused': 'Atención Focalizada\n{_Attention}',
  'radarChart.mental': 'Resistencia Mental\n{_ContinuousAttention}',
  'radarChart.discriminability': 'Discriminabilidad\n{_Sensitivity}',
  'radarChart.stability': 'Estabilidad del Procesamiento\n{_ReactionStability}',
  'radarChart.speed': 'Velocidad de Procesamiento\n{_Reactivity}',
  'radarChart.self': 'Autocontrol\n{_SelfControl}',
}
