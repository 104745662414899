export default {
  'CoverPage.powered': 'Powered by Cognitive Leap Solutions Inc.',
  'CoverPage.title': 'vCAT™ Report Parents Edition',
  'CoverPage.two': 'YOUR ATTENTION RESULTS',

  'WelcomeLetters.title': 'Welcome Letters',
  'WelcomeLetters.parents': 'Dear Parents',
  'WelcomeLetters.attention': 'Your child’s attention performance has been assessed using the vCAT™ Test, which is based on the most updated technology using VR.',
  'WelcomeLetters.indicators': 'This report shows 2 Overall Indices, 7 indicators of Cognitive attention and 4 indicators of Motor attention.',
  'WelcomeLetters.discriminability': 'By using the vCAT™ Test, several variables have been measured:Focused Attention and Discriminability, which are related to attention to details; Processing Speed and Processing Stability,which are related to impulsivity; and the child’s movements during the assessment, particularly hand and head movement away from the seat in front of the whiteboard in our virtual classroom.',
  'WelcomeLetters.information': 'This information is very important in relating to everyday life because it can tell us if your child is attending to their task at hand, and if they can sustain their attention, and for how long. This helps us understand how your child pays attention to details,such as while doing homework or projects at home and in school.Additionally, such information tells us if the student tends to respond impulsively, like when they interrupt conversation; or if they respond to the teacher´s test questions without taking the time to think or read, thus lacking self-control. That information also tells us if hyperactivity is causing your child to fidget and to move or wander around when they are supposed to be sitting at his desk.',
  'WelcomeLetters.scores': 'The scores reported are percentiles, which indicate the relative position of the child being assessed compared to the peers of the same age and gender. Thus, if a child obtains a score of 88 in the API (Attention Performance Index) it means that their level of attention is above 88% of their peers.',
  'WelcomeLetters.believe': 'We believe that this report will be a valuable tool in your efforts to support your child`s growth and development, and we are here to answer any questions you may have. If there is anything else we can do to support you, please don`t hesitate to reach out to us.',
  'WelcomeLetters.regards': 'Best regards,',
  'WelcomeLetters.team': 'The vCAT™ Team',

  'BottomDes.EDITION': 'vCAT™ REPORT - PARENTS EDITION',

  'ChildInfo.centerTitle': 'Client Information',
  'ChildInfo.clientName': 'Client Name',
  'ChildInfo.birth': 'Date of Birth',
  'ChildInfo.age': 'Age at Test',
  'ChildInfo.gender': 'Gender',
  'ChildInfo.percentile': 'Percentile Group',
  'ChildInfo.date': 'Test Date',
  'ChildInfo.center': 'Test Center',
  'ChildInfo.id': 'Case ID',
  'ChildInfo.version': 'Test Version',
  'ChildInfo.report': 'Report Version',
  'ChildInfo.yrs': '{num} yrs',

  'FullScore.title': 'Summary of Results',
  'FullScore.overall': 'Overall Index',
  'FullScore.scores': 'Cognitive Attention Scores',
  'FullScore.motor': 'Motor Attention Scores',
  'FullScore.attention': 'Attention Performance Index',
  'FullScore.motion': 'Motion Performance Index',
  'FullScore.focused': 'Focused Attention',
  'FullScore.self': 'Self Control',
  'FullScore.accuracy': 'Accuracy',
  'FullScore.discriminability': 'Discriminability',
  'FullScore.speed': 'Processing Speed',
  'FullScore.stability': 'Processing Stability',
  'FullScore.endurance': 'Mental Endurance',
  'FullScore.activityIndex': 'Activity Index',
  'FullScore.areaIndex': 'Motion Area Index',
  'FullScore.direction': 'Direction Tracking',
  'FullScore.distance': 'Movement Distance',
  'FullScore.low': 'Low: 0-33',
  'FullScore.average': 'Average: 34-66',
  'FullScore.high': 'High: 67-100',
  'FullScore.distractions': 'API score indicates the ability to maintain focus and filter out distractions.',
  'FullScore.hyperactivity': 'MPI score indicates a child`s ability to restrain impulsivity and hyperactivity.',
  'FullScore.due': 'Focused Attention score indicates a child`s ability to pay attention without missing important information due to distractions.',
  'FullScore.errors': 'Self Control score indicates a child`s ability to avoid impulsive errors.',
  'FullScore.answers': 'Accuracy score indicates a child`s ability to give correct answers.',
  'FullScore.discriminabilitys': 'Discriminability score indicates a child`s ability to distinguish between relevant and irrelevant information.',
  'FullScore.stimuli': 'Processing Speed score indicates a child`s speed to process and respond to stimuli.',
  'FullScore.processing': 'Processing Stability score indicates a child`s ability to regulate attention.',
  'FullScore.enduranceScore': 'Mental Endurance score indicates a child`s ability to maintain focus over a period of time consistently.',
  'FullScore.restlessness': 'Activity Index score indicates a child`s tendency towards restlessness.',
  'FullScore.movements': 'Motion Area Index score indicates a child`s ability to regulate movements.',
  'FullScore.tracking': 'Direction Tracking score indicates a child`s ability to ignore distracting stimuli in the environment.',
  'FullScore.position': 'Movement Distance score indicates a child`s ability to maintain a consistent position.',
  'FullScore.parameter': 'Result Parameter',
  'FullScore.explanation': 'Parameter Explanation',
  'FullScore.percentile': 'Percentile Score',

  'OverallResults.title': 'Overall Performance',
  'OverallResults.performance': 'Your overall performance is divided into two index scores:',
  'OverallResults.attentionIndex': 'Attention Performance Index:{attentionPerformance}',
  'OverallResults.motionIndex': 'Motion Performance Index:{motionPerformance}',
  'OverallResults.attention': 'Attention Performance Index',
  'OverallResults.motion': ' Motion Performance Index',
  'OverallResults.attentionPerformance': 'The Attention Performance Index represents your overall ability to:',
  'OverallResults.motionPerformance': 'The Motion Performance Index represents you overall ability to:',
  'OverallResults.attentionIs': 'Your Attention Performance Index is {attentionPerformance}',
  'OverallResults.motionIs': 'Your Motion Performance Index is {motionPerformance}',
  'OverallResults.attentionSkills': 'This means that your overall mental attention skills rank at {attentionPerformance}% compared to {sex} aged {age} years old.',
  'OverallResults.physically': 'Stay physically still during the whole 13 minutes while focusing on the task at hand, and',
  'OverallResults.maintain': 'Maintain self-control to stop yourself from looking or moving towards distracting things that occur around you',
  'OverallResults.motorSkills': 'This means that your overall motor attention skills rank at {motionPerformance}% compared to {sex} aged {age} years old.',
  'OverallResults.mentally13': 'Mentally focus over a period of 13 minutes in the virtual classroom, and',
  'OverallResults.remember': 'Remember the rules of the task and do your best to complete it, and',
  'OverallResults.mentally': 'Mentally ignore distracting things that occur around you',
  'OverallResults.boys': 'boys',
  'OverallResults.girls': 'girls',

  'AttentionScores.title': 'Cognitive Attention Scores',
  'AttentionScores.graphs': 'This graphs shows six aspects of cognitive attention skills measured from vCAT™.',
  'AttentionScores.higher': 'The higher the score the better. If the score is in the white zone,this means that you scored above the 50th percentile. If the score is in the',
  'AttentionScores.red': ' red zone',
  'AttentionScores.means': 'this means that you scored below the 50th percentile.',
  'AttentionScores.reference': 'Parent`s Reference',
  'AttentionScores.omission': 'Focused Attention',
  'AttentionScores.focused': 'Omission error (missed target) score',
  'AttentionScores.mental': 'Mental Endurance',
  'AttentionScores.score': 'Index score of performance variability across time',
  'AttentionScores.discriminability': 'Discriminability',
  'AttentionScores.prime': 'd`Prime score',
  'AttentionScores.stability': 'Processing Stability',
  'AttentionScores.reaction': 'Reaction time variability score',
  'AttentionScores.speed': 'Processing Speed',
  'AttentionScores.reactionScore': 'Reaction time score',
  'AttentionScores.self': 'Self-control',
  'AttentionScores.commission': 'Commission error score (responding when there is no target)',

  'FirstScoresDes.scoresTitle': 'Your Cognitive Attention Scores',
  'FirstScoresDes.titleDes': 'The vCAT™ contains a rich amount of information that can be interpreted from the performance results. This information can be divided into Cognitive Attention results and Motor Attention results. Below are quick references that explain what each result parameter means.',
  'FirstScoresDes.attention': 'Focused Attention',
  'FirstScoresDes.refers': 'This refers to how well you can concentrate on the task at hand while overcoming "inner" distractions like thoughts and urges, as well as "outer" distractions like sounds and sights in your environment. Omission error (missed target) is how it is measured.',
  'FirstScoresDes.focused': 'Your Focused Attention score is {attention}. This means that you scored {_Attention} for your age and gender group.',
  'FirstScoresDes.mental': 'Mental Endurance',
  'FirstScoresDes.represents': 'This represents how well you can maintain focus over a period of time. It is affected by your ability to pay attention to what`s going on in front of you and overcome inner and outer distractions, while remembering your current task instructions all at the same time.',
  'FirstScoresDes.endurance': 'Your Mental Endurance score is {continuousAttention}. This means that you scored {_ContinuousAttention} for your age and gender group.',
  'FirstScoresDes.discriminability': 'Discriminability',
  'FirstScoresDes.discriminate': 'This represents your ability to discriminate or differentiate between correct and incorrect answers. It is affected by your concentration, your ability to ignore distractions, your capacity to understand the task instructions, and your ability to execute decisions in the required time frame.',
  'FirstScoresDes.gender': 'Your Discriminability score is {sensitivity}. This means that you scored {_Sensitivity} for your age and gender group.',

  'TwoScoresDes.title': 'Your Cognitive Attention Scores Cont`d',
  'TwoScoresDes.speed': 'Processing Speed',
  'TwoScoresDes.represents': 'This represents your average time for correctly responding to a target across the whole test. It is affected by your Focused Attention, Mental Endurance, your intellectual ability, as well as your sensory processing capacity.',
  'TwoScoresDes.secondDes': 'Your Processing Speed score is {reactivity}. This means that you scored {_Reactivity} for your age and gender group.',
  'TwoScoresDes.stability': 'Processing Stability',
  'TwoScoresDes.consistently': 'This represents your ability to consistently respond correctly at the same pace across time. This is a combination of your mental endurance together with your processing speed. ',
  'TwoScoresDes.processing': 'Your Processing Stability score is {reactionStability}. This means that you scored {_ReactionStability}, for your age and gender group.',
  'TwoScoresDes.self': 'Self Control',
  'TwoScoresDes.impulse': 'This represents your ability to overcome the urge or impulse to make a response that is incorrect. It is affected by your ability to concentrate (Focused Attention), to differentiate between correct and incorrect answers (Discriminability), as well as your ability to inhibit any urges or impulses to make an incorrect response within a very short timeframe.',
  'TwoScoresDes.scored': 'Your Self Control score is {selfControl}. This means that you scored  {_SelfControl} for your age and gender group.',

  'MentalEndurance.title': 'Mental Endurance',
  'MentalEndurance.progression': 'This graph takes a look at the progression of your cognitive attention performance across time. It divides the 13 minute test into four equal blocks of 3.25 minutes each, and shows three different indicators of attention skills.',
  'MentalEndurance.attention': 'Cognitive Attention Performance Change Over Time',
  'MentalEndurance.correct': 'Correct Answer',
  'MentalEndurance.omission': 'Omission Error',
  'MentalEndurance.commission': 'Commission Error',

  'MentalEnduranceTwo.centerTitle': 'Mental Endurance Cont`d',
  'MentalEnduranceTwo.block1': 'Block 1',
  'MentalEnduranceTwo.block2': 'Block 2',
  'MentalEnduranceTwo.block3': 'Block 3',
  'MentalEnduranceTwo.block4': 'Block 4',
  'MentalEnduranceTwo.paid': 'Paid Attention',
  'MentalEnduranceTwo.distracted': 'Distracted',
  'MentalEnduranceTwo.lacked': 'Lacked Self Control',
  'MentalEnduranceTwo.green': 'Green bars',
  'MentalEnduranceTwo.yellow': 'Yellow bars',
  'MentalEnduranceTwo.red': 'Red bars',
  'MentalEnduranceTwo.well': '% score represents how well you Paid Attention It is calculated from how many Correct Answers you made in that block',
  'MentalEnduranceTwo.distracte': '% score represents how Distracted you were It is calculated from how many Omission Errors (missed correct answers) you made in that block',
  'MentalEnduranceTwo.much': '% score represents how much you Lacked Self Control It is calculated from how many Commission Errors (wrongly pressed responses) you made in that block',

  'RealtimePerformance.title': 'Realtime Performance',
  'RealtimePerformance.titleDes': 'This graph gives you an overview of the whole 13 minute test. In the graph, the x-coordinate of a dot represents when the child made a response. The y-coordinate of a dot represents how long the child used to make the response.',
  'RealtimePerformance.green': 'Green dots',
  'RealtimePerformance.correct': 'represent when you made a Correct Answer.',
  'RealtimePerformance.yellow': 'Yellow dots',
  'RealtimePerformance.omission': 'represent when you made an Omission Error (missed a correct answer)',
  'RealtimePerformance.red': 'Red dots',
  'RealtimePerformance.wrongly': 'represent when you made a Commission Error (wrongly pressed the button even when there is no correct target).',
  'RealtimePerformance.blueLine': 'Blue line',
  'RealtimePerformance.moving': 'represents how much your head is moving around at each point in time.',
  'RealtimePerformance.correctly': 'You correctly answered {correctlyPercentile}% of all targets.',
  'RealtimePerformance.missPercentile': 'You missed {missPercentile}% of all targets',
  'RealtimePerformance.errorPercentile': '{errorPercentile}% of your responses were related to lack of self control',
  'RealtimePerformance.answer': 'Correct Answer',
  'RealtimePerformance.err': 'Omission Error',
  'RealtimePerformance.commission': 'Commission Error',
  'RealtimePerformance.amplitude': 'The amplitude of head movement during the test',

  'RealtimePerformanceTwo.title': 'Realtime Performance Cont`d',
  'RealtimePerformanceTwo.visual': 'Visual Distractor',
  'RealtimePerformanceTwo.auditory': 'Auditory Distractor',
  'RealtimePerformanceTwo.mixed': 'Mixed Distractor',
  'RealtimePerformanceTwo.answer': 'Correct Answer',
  'RealtimePerformanceTwo.err': 'Omission Error',
  'RealtimePerformanceTwo.commission': 'Commission Error',
  'RealtimePerformanceTwo.amplitude': 'The amplitude of head movement during the test',

  'MotorAttention.title': 'Motor Attention Indice',
  'MotorAttention.measures': 'vCAT™ measures your physical movements during the assessment to calculate your Motor Attention Scores. Your overall Motor Attention performance is divided into two scores:',
  'MotorAttention.activity': 'Activity Index:{hyperactivityIndex}',
  'MotorAttention.motion': 'Motion Area Index:{areaIndex}',
  'MotorAttention.activityIndex': 'Activity Index',
  'MotorAttention.motionArea': 'Motion Area Index',
  'MotorAttention.represents': 'The Activity Index represents the proportion of time spent moving the head around. Higher scores mean less frequent movements over longer periods of time.',
  'MotorAttention.hyperactivityIndex': 'Your Activity Index is {hyperactivityIndex}.',
  'MotorAttention.movements': 'The Motion Area Index represents the range or area of head movements made. Higher scores mean that movement was spatially limited.',
  'MotorAttention.areaIndex': 'Your Motion Area Index is {areaIndex}.',


  'Interpretation': 'Interpretation',
  'Interpretation.Api': 'API',
  'Interpretation.Api.Des': 'A low percentile score on the API may indicate that the individual has difficulty with sustained attention and may struggle to maintain focus over an extended period of time. They may also have trouble filtering out distractions and regulating their attention.',
  'Interpretation.Mpi': 'MPI',
  'Interpretation.Mpi.Des': 'A low percentile score on the MPI may indicate that the individual has difficulty with impulsivity and hyperactivity. They may have trouble controlling their movements and may appear restless or fidgety.',
  'Interpretation.OmissionError': 'Omission Error',
  'Interpretation.OmissionError.Des': 'A low percentile score on this variable may indicate that the individual is prone to missing important information or stimuli, and may struggle with sustained attention and focus.',
  'Interpretation.CommissionError': 'Commission Error',
  'Interpretation.CommissionError.Des': 'A low percentile score on this variable may indicate that the individual is prone to making impulsive errors and may struggle with inhibiting inappropriate responses.',
  'Interpretation.Accuracy': 'Accuracy',
  'Interpretation.Accuracy.Des': 'A low percentile score on accuracy may indicate that the individual is prone to making errors and may struggle with processing information efficiently.',
  'Interpretation.Prime': "d'Prime",
  'Interpretation.Prime.Des': "A low percentile score on d'Prime may indicate that the individual has difficulty distinguishing between target and non-target stimuli, which can indicate a deficit in attention and sensory processing.",
  'Interpretation.ReactionTime': 'Reaction Time',
  'Interpretation.ReactionTime.Des': 'A low percentile score on reaction time may indicate that the individual is slow to process and respond to stimuli, which can indicate a deficit in attention, processing speed, or working memory.',
  'Interpretation.Continuous': 'Continuous Attention Index',
  'Interpretation.Continuous.Des': 'A low percentile score on continuous attention index may indicate that the indiviaul has difficulty maintaining focus over a period of time consistently in order to accomplish the task at hand while remembering the rules and context in each unfolding moment.',
  'Interpretation.ScanQrDes': 'For children with attention difficulties, we have prepared professional solutions.\nScan the QR codes below to learn more about our products.',
  'Interpretation.ScanQr.Failed': 'Generation Failed',
  'Interpretation.ScanQr.Failed.Des': 'Please refresh the page to try generating a new QR code',
  'MovementDistance': 'Movement Distance',
  'MovementDistance.DesBold': 'These graphs show you the distance of head and hand movements during each of the 4 Blocks of the assessment.',
  'MovementDistance.BlueLine': 'blue lines',
  'MovementDistance.GreenLine': 'green lines',
  'MovementDistance.The': 'The',
  'MovementDistance.FirstDes': ', indicate your Movement Distance. A higher index of the Movement Distance shown on the vertical axis means more movements were made during the assessment. ',
  'MovementDistance.TwoDes': ' indicate the median Movement Distance for your age and gender group. A blue line above the green line indicates a Movement Distance in that block that is larger than the median for your age and gender group.',
  'MovementDistance.BottomDes': 'Movement Distance shows how much you were moving around during the assessment, both in terms of the number of movements as well as how big those movements were.',
  'MovementDistance.HeadMovement': 'Head movement changes over time',
  'MovementDistance.HandMovement': 'Hand movement changes over time',
  'Charts.PathLength': 'Path Length',
  'Charts.You': 'You',
  'Charts.Median': 'Median',
  'MovementDistance.Percentile': 'Percentile',
  'Charts.Blocks': 'Blocks',
  'MovementDistance.Remained': 'Your Head Movement remained in the movement range in all the 4 blocks.',
  'MovementDistance.Exceeded': 'Your Head Movement exceeded the movement range in all the 4 blocks.',
  'MovementDistance.SomeExceeded': 'Your Head Movement exceeded the movement range in the {result} block.',
  'FirstBlock': '1st',
  'TwoBlock': '2nd',
  'ThreeBlock': '3rd',
  'FourBlock': '4th',
  'MovementPath': 'Movement Path',
  'MovementPath.DesBold': 'These graphs show you the path and area of head and hand movements during each of the 4 Blocks of the assessment.',
  'MovementPath.BlueLine': 'blue lines',
  'MovementPath.GreenLine': 'green dotted line',
  'MovementPath.FirstDes': 'The smaller the area and range of the ',
  'MovementPath.TwoDes': ', the more you stayed in your seat. The bigger the area and range of the',
  'MovementPath.ThreeDes': ', the further you moved away from your seat. Movement range outside of the',
  'MovementPath.FourDes': ' means that it exceeds the range of 20 cm (7.9 in.).',
  'MovementPath.Head': 'Head',
  'MovementPath.Hand': 'Hand',
  'Charts.MovementRange': 'Movement Range (20 cm, 7.9 in.)',
  'MovementPath.BottomDes': 'Movement Path shows whether you were seated still during the assessment, or you were unable to sit still and moved your head and hands around.',
  'MovementPath.Remained': 'Your Head Movement remained in the movement range in all the 4 blocks.',
  'MovementPath.Exceeded': 'Your Head Movement exceeded the movement range in all the 4 blocks.',
  'MovementPath.SomeExceeded': 'Your Head Movement exceeded the movement range in the {result} block.',
  'DirectionTracking': 'Direction Tracking',
  'DirectionTracking.TitleDes': 'This graphs tells you which direction you were facing during the assessment.',
  'DirectionTracking.BlueLine': 'blue lines',
  'DirectionTracking.FirstDes': 'The longer the ',
  'DirectionTracking.TwoDes': ', the more time you spent facing in that direction.',
  'DirectionTracking.ThreeDes': 'The wider the ',
  'DirectionTracking.FourDes': ', the further you faced away from the central whiteboard during the assessment.',
  'DirectionTracking.WhereLooking': 'Where were you looking?',
  'DirectionTracking.BottomDes': 'Direction tracking shows whether you were paying attention to the central whiteboard, or were distracted by other things in the environment which caused you to face away.',
  'DirectionTracking.Bottom.FirstResult': 'You spent {minutes} of the time paying attention to the whiteboard.',
  'DirectionTracking.Bottom.TwoResult': 'Your rank is {percent} compared to those in your age and gender group.',

  'TableOfContents.centerTitle': 'Table of Contents',
  'TableOfContents.information': 'Client Information',
  'TableOfContents.summary': 'Summary of Results',
  'TableOfContents.overall': 'Overall: Attention & Motion Performance Indices',
  'TableOfContents.cognitive': 'Cognitive Attention Scores',
  'TableOfContents.mental': 'Mental Endurance',
  'TableOfContents.real': 'Overall: Real-time Performance',
  'TableOfContents.motor': 'Motor Attention Indices',
  'TableOfContents.tracking': 'Direction Tracking',
  'TableOfContents.movement': 'Movement Path',
  'TableOfContents.distance': 'Movement Distance',
  'TableOfContents.interpretation': 'Interpretation',

  'MuchBelowAverage': 'much below the average',
  'BelowAverage': 'below the average',
  'AboveAverage': 'above the average',
  'MuchAboveAverage': 'much above the average',
  'Charts.Times': 'Times',
  'Charts.MotionIndex': 'Motion Index',
  'Chats.Time.Minute': 'Time (minute)',
  'Charts.Minutes': 'Minutes',
  'Charts.Reaction.Time': 'Reaction Time (ms)',
  'TextNA': 'N/A',

  'radarChart.percentile': '50th Percentile',
  'radarChart.focused': 'Focused Attention\n{_Attention}',
  'radarChart.mental': 'Mental Endurance\n{_ContinuousAttention}',
  'radarChart.discriminability': 'Discriminability\n{_Sensitivity}',
  'radarChart.stability': 'Processing Stability\n{_ReactionStability}',
  'radarChart.speed': 'Processing Speed\n{_Reactivity}',
  'radarChart.self': 'Self-Control\n{_SelfControl}',
}
