export default {
  'focusEDTTraining': 'FocusEDTx训练',
  'keywords': '关键词',
  'trackedDays': '进程',
  'CBTClass': '核心课程-FocusEDTx',
  'SleepPdt': '睡眠',
  'NutritionPdt': '营养',
  'ExercisePdt': '运动',
  'programStartDate': '开始日期',
  'lastViewedDate': '最近查看日期',
  'whickWeekOrDay': '第{week}周 第{day}天',
  'StartFocusEDTx': '开启FocusEDTx',
  'StartFocusEDTxProgram': '开启FocusEDTx',
  'SetUpModules': '设置模块',
  'Stop': '已暂停',
  'AppStart': '进行中',
  'Emotion': '情绪-FocusEDTx',
  'ParentLearning': '家长学习-FocusEDTx',
  'ParentEmotion': '家长情绪-FocusEDTx',
  'recordStopTime': '最近暂停日期',
  'setUpProgramGoals': '设置目标',
  'submitSuccessfully': '提交成功！',
  'activatedOfficiallyStarted': '用户登录儿童端APP后，治疗内容将开始推送',
  'Modules': '模块',
  'Goals': '目标',
  'Exercise-PDT': '运动-Focus-FocusEDTx',
  'Sleep-PDT': '睡眠-Focus-FocusEDTx',
  'Nutrition-PDT': '营养-Focus-FocusEDTx',
  'Overview': '总览',
  'Weekly': '周',
  'CBTSessionsCompleted': 'CBT完成情况',
  'Week': '周',
  'OrderPdt': '进程',
  'SessionName': '标题',
  'CompletionTime': '完成时间',
  'AssignedDate': '推送时间',
  'whichWeekDay': '第{week}周 第{day}天',
  'activity': '活动',
  'video': '视频',
  'hasFinished': '已结束',
  'Start': '已创建',
  'assigned': '已推送',
  'completed': '已完成',
  'cbtCompleted': '已完成',
  'Analysis': '统计',
  'video_pdf': '视频+PDF',
  'pdf': 'PDF',
  'text': '文本',
  'DailyRecording': '记录',
  'NumberDailyRecording': '打卡天数',
  'ContinuousRecording': '连续打卡：{day} 天',
  'Dinner': '晚餐',
  'Snack': '零食',
  'Lunch': '午餐',
  'Breakfast': '早餐',
  'servings': '份',
  'serving': '份',
  'Suger unknow': '糖未知',
  'No suger': '无糖',
  'With suger': '有糖',
  'Additives unknow': '添加剂未知',
  'No additives': '有添加剂',
  'With additives': '有添加剂',
  'caughtup': '已推送',
  'FocusEDTxOn': 'FocusEDTx已开启',
  'pauseFocusTitle': '确定暂停{name}的FocusEDTx吗？',
  'pauseFocusContent': '一旦暂停，用户今天仍可训练，从明天开始将不可继续训练。历史数据仍可查看',
  'focusHasPause': '此儿童已暂停FocusEDTx',
  'focusPause': '暂停FocusEDTx',
  'continueFocuse': '继续FocusEDTx',
  'Operation record': '操作记录',
  'Custom Goal': '自定义目标',
  'Add Custom Goal': '添加自定义目标',
  'SetUpModules': '设置模块',
  'stopTime': '暂停时间',
  'hey': '您好',
  'trainingProcess': '培训进展',
  'recentChild': '最近的儿童记录',
  'commonFeatures': '常用功能',
  'todayClass': '今日课时',
  'centerClassNum': '本中心课时数',
  'centerChildNum': '本中心儿童数',
  'yourCenterClassNum': '您在本中心结束课时的累计量',
  'yourCenterChildNum': '您在本中心管理儿童的数量',
  'yourAllClassNum': '您在多个中心结束课时的累计量',
  'yourAllChildNum': '您在多个中心管理儿童的累计量',
  'allClassNum': '全部课时数',
  'allChildNum': '全部儿童数',
  'notQualified': '未获资质',
  'qualificationExpired': '资质已过期',
  'qualified': '已获资质',
  'trainingStudy': '培训学习中',
  'trainingNotPay': '培训待支付',
  'trainingNotStart': '培训未开始',
  'trainingExpired': '培训已过期',
  'trainingStop': '培训已停止',
  'collapse': '收起',
  'unfold': '展开',
  'more': '更多',
  'goAdd': '去添加',
  'getDataFail': '获取数据失败',
  'trainingNotEdit': '培训申请表未填',
  'sessions_VRAT': '数据趋势',
  'HFSOverview': 'HFS消费概况',
  'HFSSpending': 'HFS消费趋势',
  'VRATStatistics': 'vCAT数据统计',
  'HFSProportion': 'HFS消费类别占比',
  'HFSBalance': 'HFS费用余额',
  'amount': '金额',
  'spending': '消费额',
  'thisYear': '本年',
  'thisMonth': '本月',
  'totalSession': '累计耗课数',
  'todaySession': '今日耗课数',
  'usedSessions': '耗课数',
  'quantity': '数值',
  'date': '日期',
  'exportList': '导出表格',
  'emptyData': '暂无数据',
  'jan': '1月',
  'feb': '2月',
  'mar': '3月',
  'apr': '4月',
  'may': '5月',
  'jun': '6月',
  'jul': '7月',
  'aug': '8月',
  'sept': '9月',
  'oct': '10月',
  'nov': '11月',
  'dec': '12月',
  'OnlinePlan': '训练-线上课',
  'OfflinePlan': '训练-线下课',
  'VRATNum': 'vCAT报告数',
  'sessionsCompleted': '已结束的课时，包括训练课时和班级的课时',
  'TotalVRATNum': '累计vCAT报告数',
  'TodayVRATNum': '今日vCAT报告数',
  'VRATReportsNum': 'vCAT报告已生成的数量',
  'todayFASNum': '今日FAS报告数',
  'totalFASNum': '累计FAS报告数',
  'FASQuestionnaires': 'FAS哈氏基础能力问卷数量',
  'todaySpending': '今日HFS消费额',
  'HFSDes': 'HFS的消费额，包括测评、训练、班级、课程',
  'totalSpending': '累计HFS消费额',
  'onlineSessions': '线下耗课数',
  'offlineSessions': '线上耗课数',
  'totalSessions': '总课数',
  'recentFinishingTime': '最近结课时间',
  'charts_feedback': '图表和反馈记录',
  'assessmentDetails': '测评详情',
  'scheduleAssessment': '安排测评',
  'terminateThePlan': '结束计划',
  'mid_assessment': '中测',
  'pre_assessment': '前测',
  'planDetails': '计划详情',
  'HFSMusicRecords': '优脑音乐收听情况',
  'FASReportObtained': 'FAS尚未获取报告',
  'obtainReport': '去获取报告',
  'reportedOther': '已获取报告',
  'trainingListeningRecords': '训练和收听记录',
  'nameMusicRecord': '{name}的班级训练和收听记录',
  "ToBeGenerated": "待生成",
  "ToBeActivated": "待开启",
  'assessmentSubmission': '提交测评',
  'exerciseActivated': '开启了运动',
  'sleepActivated': '开启了睡眠',
  'nutritionActivated': '开启了营养',
  'trainingScheduled': '安排培训',
  'Certified': '获得证书',
  "Online": "线上",
  "Offline": "线下",
  "Absent": "请假",
  "OccupiedClass": "其他班级上课中",
  'HFSMusicRecord': '优脑音乐收听记录',
  'musicFeedback': '音乐反馈',
  'accountSetting': '账户设置',
  'classList': '班级列表',
  'className': '班级名称',
  'classType': '班级类型',
  'numberOfChildren': '儿童人数',
  'maximum': '最多人数',
  'currentTrainingPlan': '当前训练计划',
  'classCreateTime': '创建时间',
  'participationTime': '最近入班时间',
  'toddler': '幼儿',
  'preschool': '学前',
  'gradeSchool': '学龄',
  'teenage': '青少年',
  'adult': '成人',
  'grade_teenage': '学龄／青少年',
  'trainingPlanName': '训练计划名称',
  'addClass': '添加班级',
  'OpiOpii': '最优表现（OPI + OPII）',
  'sureConfirm': '确定',
  'classRule': '2-50个字符，本中心内不可同名',
  'maxChildren': '最多人数',
  'GoldenAge': '黄金时代',
  'classDes': '最多人数为1人时，为一对一，否则为一对多班级',
  'OneToOne': '一对一',
  'OneToTwo': '一对二',
  'OneToThree': '一对三',
  'OneToFour': '一对四',
  'OneToFive': '一对五',
  'OneToSix': '一对六',
  'assignCoach': '分配训练师',
  'allTrainer': '全部训练师',
  'later': '稍后设置',
  'manageChildren': '管理儿童',
  'manageTips': '最多可选{num}人',
  'manageDes': '新加入的儿童跟随班级当前的训练课时进度，优脑音乐保持儿童原有进度',
  'associatePlan': '关联训练计划',
  'selectPlan': '选择训练计划',
  'canNotEdit': '不可二次修改',
  'trainingPlan': '训练计划',
  'editClass': '编辑班级',
  'classRule2': '最少人数不得小于当前已有儿童人数',
  'trainingOverview': '训练总览',
  'dailySubscriptions': '今日新增训练计划数',
  'monthlySubscriptions': '本月新增训练计划数',
  'totalPlans': '累计训练计划数',
  'totalUsedClass': '累计耗课数',
  'goingPlans': '进行中训练计划数',
  'newTrainingPlans': '新增训练计划数',
  'completedTrainingPlans': '结束训练计划数',
  'dailyNewClassNum': '今日新增课程数',
  'monthlyNewClassNum': '本月新增课程数',
  'totalClass': '累计课程数',
  'totalSuspendedSessions': '累计停授课程数',
  'recharge_Charge': '充值/扣款',
  'orderNum_products_name': '订单号／商品名称／用户姓名',
  'exxportListLoading': '导出列表中，请稍等...',
  'whichHours': '{num}小时',
  'productTypes': '商品类型',
  'onlineTraining': '训练-线上课',
  'offlineTraining': '训练-线下课',
  'oneToMore': '一对多',
  'appPrice': '线上单价',
  'webPrice': '线下单价',
  'oneToMoreOnlineCourse': '班级-线上课',
  'oneToMoreOfflineCourse': '班级-线下课',
  'OneToOneOnlinePlanCourse': '一对一-线上课',
  'OneToOneOfflinePlanCourse': '一对一-线下课',
  'OneToTwoOnlinePlanCourse': '一对二-线上课',
  'OneToTwoOfflinePlanCourse': '一对二-线下课',
  'OneToThreeOnlinePlanCourse': '一对三-线上课',
  'OneToThreeOfflinePlanCourse': '一对三-线下课',
  'OneToFourOnlinePlanCourse': '一对四-线上课',
  'OneToFourOfflinePlanCourse': '一对四-线下课',
  'OneToFiveOnlinePlanCourse': '一对五-线上课',
  'OneToFiveOfflinePlanCourse': '一对五-线下课',
  'OneToSixOnlinePlanCourse': '一对六-线上课',
  'OneToSixOfflinePlanCourse': '一对六-线下课',
  'course': '课程',
  'orderNumber': '订单号',
  'copy': '复制',
  'name_phoneNum': '姓名/手机号',
  'successful': '复制成功',
  'productsName': "	商品名称",
  'section': "内容",
  'spending': '实付金额',
  'orderStatus': '订单状态',
  'orderDate': "成交时间",
  'signOut': '登出',
  'timetable': '课程表',
  'unprocessed': '未审核',
  'approved': '审核通过',
  'rejected': '审核拒绝',
  'allApplications': '全部申请',
  'applicationTime': '申请时间',
  'Review': '审核',
  'Reviewed': '已审核',
  'Unreviewed': '未审核',
  'Reviewer': '审核人',
  'ReviewTime': '审核时间',
  'ReviewStatus': '审核状态',
  'reviewStatus': "审核情况",
  'batchApprove': '批量通过',
  'batchReject': '批量拒绝',
  'applicationForm': '培训申请表',
  'availablePlaces': '可用名额数{time}',
  'usedPlaces': '已用名额数{time}',
  'totalPlaces': '名额共{time}',
  'privatePayment': '自主缴费',
  'CoachCode': '训练师',
  'GuardianCode': '家长',
  'qualifiedTrainees': '生效中证书{num}人',
  'arrangeTime': '安排时间',
  'totalPrivatePayment': '自主缴费价格',
  'learningStatus': '学习状态',
  'proceeding': '学习中',
  'paused': '已停止',
  'issued': '已颁发',
  'unissued': '未颁发',
  'from': '来源',
  'centerArranged': '中心安排',
  'weixinAppplied': 'H5申请',
  'availabilityOfPlaces': '名额情况',
  'centerPayment': '中心名额',
  'privatePayment': '自助缴费',
  'paymentMethods': "名额情况",
  'totalPayment': "实付金额($)",
  'learnStatus': '学习状态',
  'startlearnDate': '开始学习时间',
  'pausinglearnDate': '停止学习时间',
  'endLearnDate': "结束学习时间",
  'qulification': "证书",
  'arrange': '安排',
  'training': '培训',
  'searchName': '搜索姓名',
  'supervisionReport': '见习督导记录',
  'supervisorRecords': '督导师跟踪表',
  'coachRecords': '训练师小结',
  'submitQuestion': '提交问卷',
  'submitDes': '确定提交吗？',
  'submitted': '提交成功',
  'changeChildMusic': '{name}当前为{oldStatus}，确定切换为{newStatus}吗？',
  'certificationList': '证书列表',
  'dateOfIssue': '获得日期',
  'nameOther': '名称',
  'badge': '勋章',
  'certificateNumber': '证书编号',
  'Training and Classes': '培训带课情况',
  'The Training and Classes of': '{name}的培训带课情况',
  'Offline Classes (hrs)': '线下带课小时数',
  'Online Classes (hrs)': '线上带课小时数',
  'Supervision Score': '督导得分',
  'Exams and Certificates': '培训考核及证书',
  'certifier': '认证师',
  'vaild': '生效中',
  'Cancelled': '已取消',
  'trainfeedback': '培训反馈',
  'downloadTheCertificate': '下载证书',
  'trainingApplicationForm': '培训申请表',
  'certified': '已获资质',
  'expectedLearningTime': '预计学习时长',
  'learningPeriod': '学习时间',
  'joinExam': '参加考试',
  'certificate': '证书',
  'testDes': '测试，只对内部测试人员开设',
  'expire': '{time}到期',
  'retake': '参加补考',
  'trainingDes': '请先联系培训负责人',
  'sesResults': '查看成绩',
  'submitOther': '提交',
  'notificationCenter': '消息中心',
  'newAuthorization': '新增授权',
  'trainingCheckIn': '打卡训练',
  'deauthorization': '取消授权',
  'twoDaysAgo': '两天前',
  'thereIsNoData': '暂无消息',
  'today': '今天',
  'yesterday': '昨天',
  'searchClinicNumber': '搜索儿童门诊号',
  'clinicNumber': '门诊号',
  'guardianDetails': '家长详情',
  'childrenList': '家中儿童',
  'QRCode': '扫码授权',
  'addedByCenter': '中心添加',
  'creationStartTime': '开通时间',
  'leftHander': '左利手',
  'rightHander': '右利手',
  'courseName': '课程名称',
  'sessionNumber': '课时数',
  'createdByThisCenter': '本中心开通',
  'availablePeriod': '有效期',
  'deauthorizationTime': '停止授权时间',
  'testResult': '测试结果',
  'Order': '次序',
  'ScoreAdd': '计分',
  'Grade': '评分',
  'Comments': '评语',
  'Total Score': '累计学分',
  'PracticalExam': '实操',
  'OralExam': '口试',
  'WrittenExam': '笔试',
  'Training Module': '培训模块',
  'The Arranged Center': '模块开启中心',
  'Certificate Status': '证书状态',
  'Certificate Name': '证书名称',
  'Exam Type': '考试类型',
  'Make-up Exam': '是否补考',
  'OrderTimes': '第几次提交',
  'Submitter': '提交人',
  'Score': '分数',
  'dateDes': '从开始日期的00:00到结束日期的24:00；如不填，则为“不限期”',
  'dateError': '结束日期应大于开始日期',
  'parentInterviews': '家长访谈',
  'fillNameProfile': '请先填写{name}的档案的必要信息',
  'informationDes': '档案的必要信息：【诊断信息】【孩子的优势和挑战】',
  'fillOut': '去填写',
  'class': '班级',
  'HFSMusic': '优脑音乐',
  'ILSMusic': 'iLS音乐',
  'playHistory': '收听记录',
  'editHFSMusic': '编辑优脑音乐',
  'generatioType': '生成方式',
  'basedOnHFSResults': '根据HFS结果生成',
  'otherType': '其他方式',
  'pending': '待生成',
  'noHFSDes': '无当前年龄段FAS结果，请您给家长安排FAS',
  'editClinicNumber': '修改门诊号',
  'generationHFSMusic': '生成优脑音乐',
  'batchDownloadForm': '批量下载课程反馈表',
  'batchDownloadFormDes': '请输入要下载的课程反馈表的课时序号',
  'adjustSessionTime': '课时调整',
  'scheduleSession': '排课',
  'sessionFeedback': '课时反馈',
  'sessionFeedbackForm': '课程反馈表',
  'preview': '预览',
  'volumeDes': '音量的设置应使人能聆听音乐，同时能轻松捕捉正常谈话的内容',
  'trainingActivities': '训练项目',
  'Difficulty': '难度',
  'adjustSessionDifficulty': '调整课时难度',
  'adjustActivitDes': '仅可调整存在更低难度动作的类型',
  'adjustActivityDifficulty': '调整训练项目难度',
  'Adjust Difficulty': '调整难度',
  'Add One Difficulty': '增加1个难度',
  'Reduce One Difficulty': '降低1个难度',
  'Only one difficulty': '每个课时仅可增加或降低1个难度',
  'If there is feedback': '若当前课时已有反馈内容，调整提交后反馈将被删除',
  'feedbacksubmitted': '已提交',
  'feedbackincompleted': '未提交',
  'bottomLine': '我也是有我的底线的',
  'loadMore': '加载更多',
  'toAdd': '去添加',
  'toBeOpened': '待开启',
  'switchClassStatus': '切换上课状态',
  'startClass': '开始上课',
  'continueClass': '继续上课',
  'finishClass': '结束上课',
  'finishedTrainingPlan': '已结束的计划',
  'activationTime': '开启时间',
  'endTime': '结束时间',
  'startFrom': '建于',
  'goingTime': '已进行',
  'onlineSession': '线上课时',
  'offlineSession': '线下课时',
  "logout": "退出登录",
  'online': '线上',
  'offline': '线下',
  'markAsComplete': '标记已学完',
  'markDes': '确认标记为已学完吗?',
  'studyCompleted': '已学完',
  'backToThePresent': '回到当前课',
  'pass': '已通过',
  'accuracyRate': '正确率',
  'allSubmit': '共提交',
  'downLoadWhichFeedback': '{fileBaseName}第{num}课时课程反馈',
  'downLoadWhichToWhichFeedback': '{fileBaseName}第{startNum}至{endNum}课时课程反馈',
  'HFSMusicPlayingTime': '优脑音乐收听统计',
  'personal': '本人',
  'thisCenter': '本中心',
  'totalUsedSessions': '总耗课数',
  'previousClass': '过去的排课',
  'Monday': '周一',
  'Tuesday': '周二',
  'Wednesday': '周三',
  'Thursday': '周四',
  'Friday': '周五',
  'Saturday': '周六',
  'Sunday': '周日',
  'onlyCenter': '中心',
  'randomName': '随机姓名',
  'startTimefrom': '开始上课时间',
  'durationHour': '上课时长为1小时',
  'type': '方式',
  'repeat': '重复排课',
  'never': '不重复',
  'oneWeek': '每周',
  'twoWeeks': '每两周',
  'oneMonth': '每月',
  'numberOfRepetitions': '重复次数',
  'enterAnIntegerGreater': '请输入大于0 的整数',
  'textReminder': '短信提醒',
  'guardian': '家长',
  'reminderDes': '约提前24小时发送短信提醒与系统内通知。',
  'startMoreEnd': '开始上课时间应大于当前时间',
  'scheduleASession': '建排课，同步到课程表',
  'scheduleDes': '提示：已创建的排课在上课时间之前可编辑/删除',
  'downloadConsentForm': '下载知情同意书',
  'cogleap': '思欣跃儿童优脑',
  'anySuggestions': '您希望对今天的课程进度做调整：',
  'absentRepeatOne': '请假缺课，重复最近的1节课：',
  'absentRepeatTwo': '请假缺课，重复最近的2节课：',
  'calmingLessonDes': '孩子今天情绪不佳/压力过大，临时换成1节Calming平静课程',
  'pleaseSelect': '请选择',
  'adjustmentDes': '若当前课时已有反馈内容，调整后反馈将被删除',
  'enterEmail': '请输入邮箱',
  'emailUserd': '此邮箱已被当前中心或者其他中心使用',
  'enterRightEmail': '请输入正确的邮箱',
  'twoPasswordDiff': '新密码和确认新密码不一致！',
  'accountRegistSucess': '账号注册成功',
  'lessOneChild': '请至少添加一名儿童',
  'phoneError': '手机号填写错误，请重新填写',
  'sendCodeFirst': '请先发送验证码',
  'notSameOld': '新邮箱账号不能与原邮箱账号一致',
  'passwordError': '密码输入错误',
  'submitFeedBackDes': '请先提交全部反馈方可结束课程',
  'checked': '已阅',
  'addAssessment': '添加测评',
  'selectAssessment': '选择测评',
  'HFSServiceInvalidDes': 'HFS服务无效，请联系思欣跃工作人员',
  'figure': '图表',
  'checkInRecords': '打卡记录',
  'totalItem': '总共 {num} 条',
  'downloadSeconds': '预计{time}秒内完成下载',
  'totalQuestions': '共{num}题',
  'correctQuestions': '正确{num}题',
  'worryQuestions': '错误{num}题',
  'totalQuizs': '{num}个测试',
  'QrCodeOfThisCenterAPP': '中心二维码 (APP)',
  'QRCodeofThisCenterWeChat': '中心二维码（微信）',
  'subjectTrainPlan': "{userName}的{trainPlanName}总览",
  'trainingApplicationCode': '中心培训申请码',
  'QRCodeOfReport': '查看测评报告二维码',
  'saveImage': '保存图片',
  'centerCode': '中心码：',
  'WechatScan': '微信扫一扫',
  'anthorizeCenter': '授权到本中心',
  'noUnreadMessage': '暂无未读消息',
  'enterMessage': '进入消息中心',
  'allMarkedAsRead': '全部标为已读',
  'markAsRead': '标为已读',
  'viewTainingFeedback': '查看培训反馈',
  'administratorType': '管理员类型：',
  'administrator': '管理员',
  'valid': '有效',
  'invalid': '无效',
  'remainingTimes': '剩余次数：',
  'remainingVRAT': 'vCAT剩余次数',
  'remainingTrainingSessions': '课程剩余次数',
  'remainingAssessments': '测评剩余次数',
  'RemainingHFSTraining': '一对多课程剩余次数',
  'unlimitedTimes': '不限次数',
  'attendance': '上课状态',
  'confirmTheAttendanceOfStudents': '请确认上课状态',
  'selectTheAttendanceOfStudents': '请选择上课状态',
  'HFSMusicToBeGenerated': '优脑音乐待生成',
  'scheduleSessionsChild': '为{name}的{trainName}排课',
  'trainingPeriodEnd': '训练课时已全部结束，是否关闭训练计划',
  'cancelLater': '暂时不',
  'createSchedule': '创建排课',
  'editSingleSchedule': '编辑单次排课',
  'editMultipleSchedules': '编辑多次排课',
  'repeatEveryDay': '每周的{day}重复',
  'repeatFortnightly': '每两周的{day}重复',
  'RepeatEachMonth': '每月的{day}号重复',
  'phoneNumberIncorrect': '手机号码格式错误',
  'lisenMoreThanFive': '如线下收听大于等于50%，则不可更换为线上',
  'whoHFSMusicListeningRecord': '的优脑音乐收听记录',
  'OnlyRecordFirst': '只记录第一次播放记录',
  'whichSession': '第{num}次课',
  'whichSessionOther': '第{num}次课',
  'nSession': '{num}节',
  'nTest': '{num}个测评',
  'CMSMO': '培优音乐(CM+SMO)',
  'SMG': '学龄培优音乐(SMG)',
  'allTimesWereSubmitted': '共提交了{num}次',
  'whichMins': '{num}分钟',
  'expiredOther': '已失效',
  'assessmentSuccessfully': '测评安排成功！并已发送到用户APP',
  'resultHasSent': '已发送审核结果给用户！',
  'image': '图片',
  'notStarted': '未做',
  'session': '课时',
  'Incompleted': '未完成',
  'submittedOther': '已提交',
  'adjust': '调整',
  'trial': '试用',
  'activateNextSession': '开启下一课时',
  'finish': '结束',
  'finishedCanNotPlay': '已结束不可播放',
  'Volume': '骨传导音量：',
  'staticActivities': '静态活动',
  'personalFeedback': '个人反馈',
  'sessionIsNotTakingPlace': '确定本课未进行吗？',
  'sessionWillBeUndo': '一经提交，本课的音乐及训练项目将全部“未做”',
  'musicIsNotTakingPlace': '确定音乐未进行吗？',
  'mandatoryField': '是必填项',
  'noFeedbackData': '无反馈数据',
  'viewFASReports': '查看最新FAS结果',
  'FASReported': 'FAS哈氏基础能力测评 已生成报告',
  'continuePreviousProgress': '继续之前的进度',
  'editMusicForName': '为{child}编辑的优脑音乐为{music}?',
  'startFromFirst': '从01开始',
  'restartFromFirst': '是否从01重新开始',
  'EditedHFSAs': '编辑的优脑音乐为',
  'music': '音乐',
  'modulePreviouslyUpload': '编辑的优脑音乐为{newMusic}，{name}之前此模块已上传到{oldMusic}，是否从01重新开始',
  'pleaseSelectDateAndTime': '请选择日期和时间',
  'feedbackTime': '反馈时间：',
  'paymentAmount': '支付金额',
  'userInformation': '用户信息',
  'currentFileCannotplayed': '音乐获取失败！请检查您的网络或稍后重试',
  'tableOfContent': '目录',
  'listened': '已听：',
  'noMusicPlayed': '暂无已听音乐',
  'newlyArrangedTraining': '您有新安排的培训，快去查看吧',
  'viewTheTraining': '查看培训',
  'notForNow': '暂不',
  'goTrainingCenter': '可稍后去【培训中心】查看培训',
  'nameToEdited': '姓名待编辑',
  'trainingActivityAllChecked': '训练项目：全部已打卡',
  'WechatScanPay': '微信扫码支付',
  'amountPayable': '应付金额：',
  'orderClosed': '订单已关闭',
  'orderRefunded': '订单已退款',
  'jumpingToLearn': '正在跳转去学习',
  'trainingBeenPaidSuccessfully': '您的{trainingModuleName}培训已支付成功',
  'unpaid': '未缴费',
  'pleaseSelectPaymentMethod': '请选择支付方式：',
  'Wechat': '微信',
  'pleasePayYourTraining': '请支付您的{trainingModuleName}培训金额',
  'pleasePayYourTrainingTwo': '，即可开启培训啦',
  'functionIsNotOpen': '功能暂未开放，敬请期待',
  'pay': '去支付',
  'selectTheTrainee': '请选择批量通过的培训人员！',
  'resultsSubmitted': '已提交审核结果！正在跳转去给这些用户安排培训...',
  'hasStarted': '已开始',
  'continueTrainingSupervisor': '，如想继续学习，请联系您的培训负责人',
  'testInformationFail': '获取考试信息失败，请联系管理员',
  'generateRetestPaperFail': '生成补考试卷失败，请联系管理员',
  'testInstructions': '考前须知',
  'examDuration': '考试时长（分钟）',
  'numberOfQuestions': '题目量（题）',
  'testScore': '考试总分（分）',
  'outstandingStudentProgram': '使用培优音乐：',
  'firstTimeExam': '首次{name}笔试不收取任何费用，您有且仅有一次免费答题机会，请慎重决定是否立即开始答题。',
  'examResults': '考试成绩{score}分以上（含）通过此次考试。',
  'thisTimeExamRetakeFee': '本次{name}笔试补考费用{price}元，支付后即可开始答题',
  'joinNow': '立即参加',
  'continueWithLastExam': '继续上次考试',
  'takeExam': '去考试',
  'wait': '我再想想',
  'Issue': '颁发',
  'retakeExamsNotTimes': '补考次数已用完，请联系培训负责人',
  'completeFirst': '请先完成{name}所有小节的学习',
  'systemMessageDetails': '系统消息详情',
  'unauthorizedStatus': '未授权状态，如需开启此模块，请联系思欣跃',
  'clickBlankAreaBothSide': '点击文档两旁空白区域及可进行上下翻页',
  'promptForAction': '操作提示',
  'uploadFilePDFFormat': '请上传PDF格式文件',
  'serialNumberEditing': '序号编辑（数字表示排在第X个的后面，0表示置顶）',
  'bluetoothEarphone': '蓝牙耳机',
  'confirmBluetoothIsConnected': '确认蓝牙耳机是否已连接？',
  'adjustVolumeBeforePlaying': '播放前请连接蓝牙骨传导耳机，并调节至合适的音量',
  'clickToAudition': '点击试听',
  'correct': '正确击中',
  'miss': '漏击',
  'wrong': '错击',
  'headMovement': '测试过程中的头部运动幅度',
  'warningLine': '警示线 (50)',
  'yourScore': '您的分数',
  'goalFocus': '目标专注力',
  'downloading': '正在下载中...',
  'activateNextSessionDes': '是否开启下一课时',
  'archivesCompleted': '已按要求填写',
  'fixationDirectionTracking': '注视方向追踪',
  'selfMotionControl': '动作自控力',
  'totalSessionsOther': '共{num}次课',
  'whichMusic': '第{num}次课音乐',
  'continuousAttention': '持续注意力',
  'resolution': '分辨力',
  'reactionStability': '反应稳定性',
  'reactivity': '反应力',
  'behavioralSelfControl': '行为自控力',
  'sureCloseMusic': '确定关闭优脑音乐播放栏吗？',
  'close': '关闭',
  'score': '分数：',
  'summaryOfProbationSupervision': '见习督导小结',
  'editCV': '编辑简历',
  'reviewTrainingApplications': '审核培训申请',
  'decline': '拒绝',
  'phoneNumError': '手机号格式不正确',
  'pass': '通过',
  'resultOfTrainingEmail': '您对用户{email}的培训申请的审核结果是：',
  'useRole': '用户角色：',
  'batchReviewApplications': '批量审核培训申请',
  'rolesPeopleAreInconsistent': '注：若其中某些人的角色不一致，请取消后单独处理！',
  'batchThesePeople': '您正在批量通过{name}人的培训申请，请为这些人安排角色',
  'review': '审核',
  'sureSubmitReviewResults': '确定提交审核结果吗？',
  'selectRejectedBatches': '请选择批量拒绝的培训人员！',
  'unselectedRole': '未选择角色',
  'confirmedBatchesRejecting': '您正在批量拒绝{name}人的培训申请，拒绝后用户需重新申请，是否确认？',
  'searchForName_email_phone': '搜索姓名/邮箱/手机',
  'everyday': '每天',
  'repeat': '重复',
  'youNotPermission': '您暂无权限',
  'chooseTheCourseAsTheLatest': '最晚选择20：00的课程',
  'unknownCoach': '未知训练师',
  'past': '过去的',
  'coursewareDetails': '课件详情',
  'introduction': '介绍',
  'five_stepCycle': '五步循环法',
  'detailsDescription': '详情说明',
  'coursewareName': '课件名称',
  'coursewareNumber': '课件编号',
  'estimatedTrainingDuration': '预计训练时长(分钟)',
  'suitableAge': '适合年龄',
  'suitableNumberPeople': '适合人数',
  'trainingGoals': '训练目标',
  'trainingMaterials': '教具',
  'orderNumber': '序号',
  'trainingMaterialsName': '教具名称',
  'minutesLeftInExam': '考试还剩余3分钟',
  'theExamEnds': '考试结束',
  'automaticallySubmitted': '考试结束，已自动帮您提交试卷，感谢您的参加',
  'failedSubmitAnswer': '提交答案失败',
  'clickStartTest': '点击开始考试，考试正式开始',
  'activateClass': '开启课时',
  'startTheExam': '开始考试',
  'failedStartExam': '开始考试失败',
  'autoSubmitted': '超时自动提交了',
  'getExamInformation': '获取考试信息失败',
  'sureSubmitQuestionnaire': '您还有题目没有填写，确定提交问卷吗？',
  'closePrompt': '关闭提示',
  'confirmExitExam': '确定退出考试',
  'answersWillSubmitted': '退出后，请及时返回继续考试，考试将会持续计时！考试时间截止时，将直接提交本次答案。',
  'examHasEnded': '考试已结束',
  'startBeforeOperation': '请先开始上课后进行操作',
  'musicPlayedStartNew': '当前音乐模块已播完，如需继续下一阶段训练，可进行测评后，开启新音乐模块',
  'musicCannotSureEnd': '若结束课时则无法继续听，是否确定结束？',
  'AppContinueListen': '为保证训练效果，可建议家长让孩子在app端继续听',
  'HFSMusicListening': '的优脑音乐收听进度小于',
  'notScheduledNextSession': '下节课暂未安排课程',
  'trainingPlanOver': '训练计划已结束',
  'shouleParentInterview': '您尚未完成{name}在本阶段的家长访谈，完成后才可开启本课时。',
  'selectChildDownloadLesson': '请选择儿童下载课时反馈表',
  'selectChildPreview': '请选择一名儿童预览课时反馈表',
  'reasonablyAccording': '请依据您的待督导次数，合理使用。',
  'noTrialsAvailable': '暂无可试用次数，请联系您的项目负责人',
  'NoteParentsInterview': '注：需定期与家长预约一次访谈',
  'completeInterviewPoperly': '请与家长预约完成本阶段的访谈，以确保后续课程正常进行',
  'currentDevicePlayable': '当前设备不可播放',
  'otherChildrenMusicPlaying': '当前设备有其他儿童的音乐正在播放中',
  'confirmChange': '确认更换',
  'replaceHFSmusic': '确定更换为{name}的优脑音乐吗？',
  'onlineSessionCannotPlayed': '本课为线上课，不可在训练师端播放',
  'switchCurrentLatest': '切换至当前最新课程操作',
  'notPlayable': '不可播放',
  'leave': '离开',
  'thisSessionEnded': '当次课已结束',
  'musicPlayingAgain': '当前儿童音乐播放中，关闭音乐后重试',
  'musicPlayingProceed': '优脑音乐播放中，离开会停止播放。确认离开吗？',
  'playingCurrentDevice': '当前设备播放中',
  'playingOtherDevice': '其他设备播放中',
  'finished': '已结束',
  'finishedPre': '已完成',
  'toGetPreReport': '请先去获取前测报告',
  'afterPlanCanPerformed': '结束计划后，不可再对计划进行操作',
  'completeAllSessionsEndingPlan': '建议完成全部课时并进行充分的效果评估后再结束计划',
  'authorisingChildren': '关联儿童',
  'confirmAttendanceChildren': '请确定儿童出席情况',
  'endClass': '结束课时',
  'presentClass': '正常上课',
  'absent': '缺席/请假',
  'absentOther': '请假',
  'downloadedTips': '最多可连续下载20页',
  'selectAChild': '请选择一名儿童',
  'sureDownload': '确定下载吗？',
  'enterSessionDownload': '请输入要下载的课程反馈表的课时序号：',
  'selectOneChild': '选择一名学生',
  'children': '学生',
  'reminder': '提示',
  'continue': '继续',
  'trainingPlanDetails': '训练计划详情',
  'sessionFirstGiveFeedback': '请先结束上课后进行反馈',
  'orderDetails': '订单详情',
  'signOnChildrenToday': '今日暂无签到儿童',
  'basicIntroduction': '基本介绍',
  'courseware': '课件',
  'numberOrder': '编号',
  'numberOrderOther': '序号',
  'numberOfSessions': '课程次数',
  'feedbackTrainingPlan': '反馈（训练项目）',
  'feedback_session': '反馈（次课）',
  'file': '文件',
  'trainingIntroduction': '训练介绍',
  'TrainingInstructions': '训练须知',
  'noTestScheduled': '暂无测试安排',
  'getReport': '获取报告',
  'viewReport': '查看报告',
  'details': '查看详情',
  'assessmentOnlyWechat': '该问卷只能微信扫码填写',
  'ageNotMatch': '年龄不符',
  'unfinishedAssessments': '存在未完成测评',
  'scheduleTrainingPlan': '安排训练计划',
  'afterendPlanNotPossible': '结束计划后，不可再进行课时调整、反馈及安排测评',
  'completeAllSessionsEndingPlan': '建议完成全部课时并进行充分的测评评估后再结束计划',
  'test': '测验',
  'untilFinishedStudying': '已学完之后才可以开始测验',
  'whetherStartTraining': '是否以此前测结果为基础，开启本次训练计划？',
  'activateTrainingPlan': '确定开启本次训练计划吗？',
  'addCalmingCourses': '增加Calming平静课程作为训练导入（共10课时）（请与您的督导师确认是否需要增加）',
  'preAssessmentResult': '前测结果',
  'Finished': '已完成',
  'noPreAssessment': '无前测',
  'preAssessmentList': '前测列表',
  'pleaseSelectLeast': '请至少选择一个课',
  'sureDeleteIt': '确定删除吗？',
  'childCannotViewed': "该儿童{status}，本模块内容无法查看",
  'failed': '未通过',
  'whetherMarkFinished': '是否标记为已学完',
  'attachments': '附件',
  'learning': '正在学',
  'unfinished': '未学完',
  'viewResult': '看结果',
  'OneToMany': '一对多',
  'xTrainingDetails': '{name}的培训详情',
  'confirmedHimSubmission': '确认为{name}开通{title}有效期：{time}吗？提交后不可修改',
  'notPermission': '您没有查看孩子详情权限',
  'openCourses': '开通课程',
  'correctRateCorrentRate': '做对{correntCount}题，正确率{correntRate}%',
  'trackNumber': '音轨编号',
  'trackNumberChant': '音轨编号（吟唱）',
  'trackDuration': '音轨时长',
  'activityName': '活动名称',
  'coverImage': '封面图',
  'recommendedActivitiesList': '推荐活动列表',
  'trainingMaterials': '训练器材',
  'actionSummary': '动作概要',
  'trainingTimesPrecautions': '训练次数和注意事项',
  'possibleDifficultiesChildren': '孩子完成活动可能出现的困难',
  'groupTeachingSuggestions': '团体带课建议',
  'offlineTrainingPlan': '线下训练计划',
  'onlineTrainingPlan': '线上训练计划',
  'sendToGuardian': '发送报告给家长',
  'sureSendReportGuardian': '确定发送此报告给儿童的家长吗？',
  'parentsCanInAPP': '家长可在APP查看报告',
  'selectPeople': '选择人员',
  'arrangeMethod': '安排方式',
  'notStartedFillFeedback': '如需填写整体反馈请先点击确定按钮结束上课',
  'selfPaymentStudents': '中心名额：使用中心的名额，学员无需缴费；自主缴费：学员需自己缴费',
  'selectedEmployees': '选择人员数量已超过中心可用名额',
  'sureArrangementsSuccessful': '确定安排吗？安排成功后，此模块即发放给用户',
  'arrangingTraining': '您正在给{name}人使用【中心名额】安排培训模块，是否确认？',
  'arrangingTrainingModuleSelfPayment': '您正在给{name}人使用【自主缴费】安排培训模块，是否确认？',
  'goGuardianOkText': '去【家长列表页】',
  'goGuardianContentText': '{phone} 已注册，请去【家长列表页】添加儿童',
  'coachModalTitle': '该家长已在本中心添加，负责训练师为{coach}',
  'coachModalContent': '若需为该家长名下儿童新建测试或新添加儿童，可联系您所在中心的管理员或前台，为儿童重新分配训练师。',
  'anotherCenterTitle': '该家长已在其他中心添加',
  'anotherCenterContent': '如需添加该家长到本中心，请扫下图本中心二维码完成授权。',
  'downloadQRCodeOther': '下载二维码',
  'iknow': '知道了',
  'currentlyAvailable': '目前可用中心名额',
  'centerAdmin': '中心管理员',
  'selfPaymentUnavailable': '自主缴费功能未开启',
  'selfPaymentPrices': '自主缴费价格',
  'multiSensoryTraining': '多感官音智训练',
  'HallowellFocusTraining': '哈氏优脑音智训练',
  'confirmPersonalInformation': '请确定您的个人信息',
  'clickEnter': '请点击进入',
  'centerSide': '机构端',
  'FASBasicCompetency': 'FAS哈氏基础能力测评统计图',
  'childrenAttention': '儿童注意力评估表统计图',
  'assignVratTest': '确定为{name}添加vCAT测试吗？',
  'childStrengthsChallenges': '孩子的优势和挑战',
  'question': '题目',
  'musicHasEnd': '当前课优脑音乐已全部播放，是否重播？',
  'replay': '重播',
  'Apply for FocusEDTx Cancellation': '申请注销FocusEDTx',
  'detailsComparison': '详情对比',
  'printDetails': '打印',
  'examPaperReviewed': '正在批阅试卷，请稍后',
  'totalScore': '总分 ',
  'submissionTime': '提交时间',
  'retakeExamAdministrator': '如需补考，请联系培训负责人',
  'noTestResult': '暂无考试成绩信息',
  'selectEmployees': '选择员工',
  'sendInvitationEmail': '发送邀请邮件',
  'switchingAccountRole': '您正在将账号角色切换为{value}，每个机构有且仅能有一个管理员，请选择一位员工作为新的管理员：',
  'editRemainingTimes': '编辑剩余次数',
  'remainingVRAT': '剩余vCAT',
  'remainingAssessment': '剩余测评',
  'remainingSession': '剩余课时',
  'multiCoursesRemaining': '剩余一对多课程',
  'availablePDFFormat': '支持格式PDF',
  'letStartLearning': '开始学习吧',
  'studentName': '学员姓名',
  'playingDurationMins': '聆听时长',
  'groupOne': '第一组',
  'groupTwo': '第二组',
  'groupThere': '第三组',
  'levelOfDifficulty': '完成难度',
  'levelOfLikeability': '喜爱程度',
  'observedPerformance': '观察到的课堂表现',
  'followInstructions': '遵从指令',
  'attention': '注意力',
  'hyperactivity_impulsivity': '多动/冲动',
  'performanceTraining': '本次训练总体表现',
  'strengths_advancementsChild': '孩子的优点/进步',
  'aspectsImproved': '需要提升的方面',
  'guardianFeedback_questions': '家长反馈/提问',
  'HFSTrainingRecord': 'HFS哈氏儿童优脑训练课程记录表',
  'NoteLevelDifficulty': '注：完成难度',
  'levelCompetency': '能力情况',
  'bodyCobordination': '身体协调性',
  'coreStrength': '核心力量',
  'leftAndRight': '左右交叉',
  'handEyeCoordination': '手眼协调',
  'balance': '平衡',
  'classSituation': '上课情况',
  'coordination_core_balance': '身体协调性&核心&左右交叉&手眼协调&平衡',
  'indicates': '代表',
  'allIndicate': '均代表',
  'printTimetable': '打印课程记录表',
  'fillTrainingFeedback': '填写培训反馈',
  'certifiedTraining': '认证培训',
  'know': '知道了',
  'subjectNotFound': '小孩未找到',
  'HFSCoach': 'HFS优脑训练师',
  'apply': '申请',
  'parentControlConsole': '家长操作台',
  'coachControlConsole': '训练师操作台',
  'progressNotSelected': '尚未选择完成情况',
  'musicPlaybackRecord': '本次课程音乐的收听情况',
  'failedGetOrder': '获取订单信息失败，请联系管理员',
  'failedGenerateExam': '生成考试试卷失败，请联系管理员',
  'onlyTrialsLeft': '您仅剩{tryCount}次可试用课时数',
  'giveFeedbackUnless': '请在开始上课之后进行反馈',
  'sureEndName': '确定结束{name}的{trainPlanName}吗？',
  'Apply for Cancellation': '申请注销',
  'reviewTheApplication': '审核FocusEDTx注销申请',
  'reviewResult': '您对{name}的FocusEDTx注销申请的审核结果是：',
  'Allow': '允许注销',
  'Reject': '拒绝注销',
  'ReasonAndNotes': '理由及备注',
  'FocusEDTx is Cancelled': '已注销FocusEDTx',
  'Cancelled Time': '注销时间: ',
  'giveFeedbackSession': '请先开始上课后进行反馈',
  'projectNotProgress': '确定本项目未进行吗？',
  'sureScheduleFollowing': '确定为{name}安排如下测评吗？',
  'sureDataCleared': '确定删除吗？删除后数据将清除',
  'courseDuration': '课程时长',
  'addVratError': '暂无可用 vCAT 测试，请联系思欣跃工作人员',
  'viewComparison': '看对比',
  'checkPhoneNumber': '验证手机号',
  'addOrChiceChild': '添加家长和儿童',
  'addAndCode': '添加家长和儿童并生成vCAT测试码',
  'numberIsEnroll': '{number} 已注册，请去【儿童列表页】添加vCAT测试。',
  'gotoChildList': '去儿童列表页',
  'trainingDate': '训练日期',
  'extremelyEasyDifficult': '非常简单、简单、一般、困难、非常困难',
  'extremelyDislikeLike': '很不喜欢、不喜欢、适中、喜欢、很喜欢',
  'extremelyInattention': '很不专注、不专注、一般、专注、很专注',
  'veryUncooperative': '非常不配合、不配合、一般、配合、非常配合',
  'highImpulsive': '非常多动冲动、多动冲动、一般、自控力好、自控力很好',
  'needImprove': '亟待提升、需要提升、一般、良好、很好',
  'name': '姓名',
  'userId': '用户ID',
  'gender': '性别',
  'age': '年龄',
  'grade': '年级',
  'cv': "个人简历",
  'center': "所属中心",
  'birthday': '出生日期',
  'previous': '上一步',
  'next': '下一步',
  'testDate': '测试日期',
  'testId': '测试ID',
  'loginSecurity': '安全设置',
  'testLocation': '测试地点',
  'testVersion': '测试版本',
  'reportVersion': '报告版本',
  'comments': '备注',
  'none': '无',
  'continueFocuseTitle': '确定继续{name}的FocusEDTx吗？',
  'poweredBy': '由CognitiveLeap提供技术支持',
  'userDetail': '用户信息',
  'testDetail': '测试信息',
  'unknown': '未知',
  'selectDeletionType': '请选择删除类型',
  'thisSchedule': '此排课',
  'subsequentSchedules': '此排课及后续排课',
  'allSchedules': '所有排课',
  'createSchedule': '创建日程',
  'submitTheTest': '提交测试',
  'unlimitedTime': '无限期',
  'male': '男',
  'female': '女',
  'other': '其他',
  'loading': '加载中',
  'changeEmail': '更改邮箱',
  'editProfile': '编辑个人信息',
  'inputPhoneNum': '请填写手机号',
  'coachName': '搜索训练师姓名',
  'changPhoneNum': '更改手机号',
  'codeSendSuccess': '验证码发送成功',
  'changePassword': '更改密码',
  'clear': '取消筛选',
  'clearTwo': '清除',
  'titleTwo': '请设置手机号和登录密码',
  'areaCodeMessage': '请输入电话区号和手机号',
  'passwordMessage': '请确认密码和确认密码保持一致',
  'getFailedChildInfo': '获取小孩信息失败',
  'scheduleEditingRange': '编辑生效',
  'oneTimeClass': '仅编辑本次排课',
  'followUpClass': '编辑本次及后续排课',
  'accountActive': '账号激活成功',
  'activeFailMsg': '激活链接错误或者失效，replacement秒之后自动返回登录页',
  'accountActived': '该账户已激活，replacement秒之后自动返回登录页',
  'accountExist': '激活成功！您的邮箱之前在我们系统已注册并设置过个人信息及密码，可直接登录, replacement秒之后自动返回登录页',
  'accountActiveFail': '激活失败，replacement秒之后自动返回登录页',
  'confirm': '确定',
  'exportPDF': '导出PDF',
  'reportingWait': '获取报告中，请稍后...',
  'passwordRule': '密码为8-12位，必须数字+字母组合，允许特殊字符',
  'total': '总计',
  'items': '条',
  'action': '操作',
  'detail': '详情',
  'search': '搜索',
  'back': '返回',
  'edit': '编辑',
  'freeze': '冻结',
  'activate': '重新启用',
  'download': '下载',
  'medicalVratView': '基础版',
  'downloadPDF': '下载PDF',
  'view': '标准版',
  'see': '查看',
  'success': '成功',
  'failure': '失败',
  'untested': '未测试',
  'testing': '测试中',
  'whichFeedback': '第{num}次课反馈',
  'tested': '已测试',
  'reportReady': '已生成报告',
  'reportGenerating': '报告生成中',
  'parentalAdvice': '获取家长建议',
  'parentalAdviceTime': '获取家长建议时间',
  'reportGenerationFailed': '报告生成失败',
  'testStatus': '测试状态',
  'dataUploading': '数据上传中',
  'testAbnormal': '测试异常',
  'testFailed': '测试失败',
  'reportAcquired': '已获取报告',
  'reportedDate': "报告生成时间",
  'failed': '失败',
  'deleted': '已删除',
  'deletedSuccess': '删除成功',
  'engTest': '内部测试',
  'realTest': '真实测试',
  'dataCollect': '数据收集',
  'business': '商业',
  'inActive': '无效',
  'active': '有效',
  'unReleased': '未发布',
  'released': '已发布',
  'archive': '归档',
  'email': '邮箱',
  'submit': '确认',
  'cancel': '取消',
  'role': '角色',
  'profilePhoto': '头像',
  'change': "更改",
  'phone': '手机号',
  'delete': '删除',
  'china': '中国',
  'israel': '以色列',
  'mexico': '墨西哥',
  'unitedState': '美国',
  'mother': '母亲',
  'father': '父亲',
  'grandfather': '(外)祖父',
  'grandmother': '(外)祖母',
  'otherLegalGuardian': '其他法定监护人',
  'countryCode': '电话区号',
  'save': '保存',
  'saveAndBack': '保存退出',
  'company': '公司官网',
  'accountSystem': '账户系统',
  'centerName': '中心名称',
  'locationName': '测试地点名称',
  'caseId': '测试ID',
  'subjectName': '儿童姓名',
  'guardianName': '家长姓名',
  'dataError': '--',
  'locationAddress': '测试地点地址',
  'locationStatus': '测试地点状态',
  'centerLogo': '中心Logo',
  'beforeSchool': '幼儿园',
  'notAttendingSchool': '未上学',
  'statisticsTotal': '全部',
  'isRequired': '是必填项',
  'pleaseSelectDuration': '请选择时长',
  'pleaseSelectDate': '请选择日期',
  'uploadAImage': '上传图片',
  'uploadAVideo': '上传视频',
  'relationShipRequired': '关系是必填项',
  'subjectRequired': '请选择一名儿童',
  'newEmail': '新邮箱',
  'deleteGuardianConfirm': '你确定要删除该家长吗？',
  'smsCode': '验证码',
  'send': '发送',
  'sureAdd': '添加',
  'sendCode': "发送验证码",
  'currentPassword': '当前密码',
  'newPassword': '新密码',
  'confirmPassword': '确认新密码',
  'backLowercase': '返回',
  'verifyPassword': '验证密码',
  'y': '岁',
  'm': '个月',
  'testAge': '测试年龄',
  'home': '主页',
  'photo': '照片',
  'checkInStatus': '签到状态',
  'notCheckedIn': '未签到',
  'checkedIn': '已签到',
  'checkIn': '签到',
  'checkInList': '签到列表',
  'scanCheck': '扫码签到',
  'addTime': '添加时间',
  'authorized': '已授权',
  'trainingPlan': '训练计划',
  'vartTest': 'vCAT测评',
  'developTest': '发展性测评',
  'all': '全部',
  'activityFeedback': '训练项目反馈',
  'timetable': '课程表',
  'switchRoles': '切换角色',
  'changeDes': '训练师切换为其他角色后，该账号名下的儿童需重新分配训练师',
  'creatTime': '创建时间',
  'trainingPlanName': '训练计划名称',
  'status': '状态',
  'currentSession': '当次课',
  'allSession': '总课数',
  'preStauts': '前测状态',
  'realStartTime': '正式开启时间',
  'endTime': '结束时间',
  'arrangeTrainingPlan': '安排训练计划',
  'classEnd': '结束课时',
  'feedback': '反馈',
  'notRealStart': '未正式开启',
  'realStart': '正式开启',
  'testPartName': '测试地点名称',
  'testStatus': '测试状态',
  'attentionNum': '注意力指数',
  'actionNumber': '动作指数',
  'lookDetail': '详情',
  'lookReport': '查看报告',
  'addTest': '添加测评',
  'testName': '测评名称',
  'assessmentStatus': '测评状态',
  'reported': '已生成报告',
  'ilsMusic': 'iLs音乐',
  'thisClassEnd': '本课程已结束',
  'lookMoreActivity': '查看更多推荐活动',
  'addMoreActivity': '选择推荐活动',
  'haveChoiceActivity': '已选择的推荐活动',
  'trainBeforeAllCompelete': '完成前测后，可正式开启计划',
  'sureNotDo': '确定该训练未进行吗？',
  'cannotUpdate': '提交后将无法修改',
  'progress': '进度',
  'ok': '确认',
  'yes': '是',
  'no': '否',
  'done': '已完成',
  'notDone': '未完成',
  'ongoing': '进行中',
  'notStart': '未开始',
  'checkInPeopleList': '签到的儿童如下',
  'sureCheckIn': '确认签到',
  'checkInSuccess': '签到成功！',
  'checkInFailed': '签到失败',
  'growthTrack': '的成长轨迹',
  'thisChildHasProgram': '该儿童已经有正在进行中的训练计划了',
  'start': '开始',
  'currentTime': '当次',
  'arrangeSuccess': '安排成功!',
  'isSureThisProPlan': '确定开启本次训练计划吗？',
  'tips': '提示',
  'checkoutThisChild': '确定要签出该儿童吗？',
  'noLongerAuthorized': '已解绑',
  'startExerise': '开启训练',
  'created': '已创建',
  // 'completed': '已成交',
  'doneFeek': '已反馈',
  'sureInfoAgain': '请再次确认儿童信息',
  'areYouSureTo': '你确定要',
  'staff': '员工',
  'trainer': '训练师',
  'volumeOnly': '音量',
  'logoutUser': '注销',
  'assignTrainers': '分配训练师',
  'sureActivate': '是',
  'teamManagement': '团队管理',
  'addNewEmployee': '添加新员工',
  'sendActivationEmail': '发送激活邮件',
  'resend': '重新发送',
  'mailSentSuccessfully': '发送邮件成功',
  'failedToSendMail': '发送邮件失败',
  'desktop': '工作台',
  'statisticalOverview': '统计概况',
  'trainerDatabase': '训练师资料库',
  'database': "资料库",
  'account': "个人中心",
  'profileSetting': "个人设置",
  'trainCenter': "培训中心",
  'trainManagement': "培训人员管理",
  'trainApplyReview': "培训申请审核",
  'trainSupervison': "训练督导",
  'reset': '重置',
  'pleaseEnterKeys': '请输入名称/关键字',
  'noCourses': '没有课程',
  'sleep': '睡眠',
  'nutrition': '营养',
  'exercise': '训练',
  'chartsAndFeedbackRecords': '图表和反馈记录',
  'preDetail': '前测详情',
  'chart': '图表',
  'personalInformation': '请填写您的个人信息',
  'trainerFeedback': '训练师反馈',
  'overallFeedback': '课时整体反馈',
  'parentFeedback': '家长反馈',
  'noOpenTraining': '暂无开启训练',
  'viewRecord': '查看打卡记录',
  'notOpen': '未开启',
  'startTime': '开始时间',
  'punchTotalDay': '累计打卡天数',
  'continueClockDay': '连续打卡天数',
  'isActive': '是否已激活',
  'isSend': '是否已发送',
  'effective': '有效',
  'invalid': '无效',
  'roleName': '角色名',
  'picture': '图片',
  'switchStatus': '状态切换',
  'evaluationList': '测评列表',
  'questionnaireEvaluationList': '问卷测评列表',
  'childUserAnalysis': '儿童用户分析',
  'parentUserAnalysis': '家长用户分析',
  'VRATAnalysis': 'vCAT分析',
  'brainTrainAnalysis': '优脑训练分析',
  'usedSessionsDuration': '耗课数时间',
  'courseAnalysis': '课程分析',
  'messageCenter': '消息中心',
  'school': '学校',
  'bound': '已绑定',
  'cancelled': '已注销',
  'unauthorized': '未绑定',
  'canUseTestCount': '可用测试量',
  'consumptionTest': '消耗测试量',
  'testName': '测试名称',
  'startDate': '开始日期',
  'endDate': '结束日期',
  'sendToParents': '发送给家长',
  'hasSend': '已发送',
  'keyIndicators': '关键指标',
  'trendAnalysis': '趋势分析',
  'newChildrenToday': '今日新增儿童数',
  'signedChildrenToday': '今日签到儿童数',
  'newChildrenMonth': '本月新增儿童数',
  'trainChildCount': '累计训练儿童数',
  'unboundChildCount': '累计解绑儿童数',
  'anthorChildCount': '累计授权儿童数',
  'newChildren': '新增儿童数',
  'unboundChildNumber': '解绑儿童数',
  'year': '年',
  'month': '月',
  'cycle': '周期',
  'pleaseSelectMonth': '请选择月份',
  'InviteEmployee': '邀请员工',
  'WhoAccountSettings': '{name}的个人设置',
  'pleaseSelectYear': '请选择年份',
  'evaluationQrCode': '测评二维码',
  'addEvaluationQrCode': '添加测评二维码',
  'selectOption': '请选择需要生成测评的选项',
  'useWechatSee': '微信扫一扫做测评',
  'courseName': '课程名称',
  'keyword': '关键字',
  'openingTime': '开通时间',
  'classHour': '课时数',
  'exportList': '导出列表',
  'trainingProgramType': '训练计划类型',
  'user': '用户',
  'ageDistribution': '年龄分布图',
  'genderDistribution': '性别分布图',
  'designation': '名称',
  'designationOther': '名称',
  'number': '数量',
  'proportion': '占比',
  'newParentToday': '今日新增家长数',
  'newParentMonth': '本月新增家长数',
  'anthorParentCount': '累计授权家长数',
  'newParent': '新增家长数',
  'trainingPlanList': '训练计划列表',
  'trainingProgramStatistics': '训练计划统计',
  'open': '展开',
  'putItAway': '收起',
  'newReportToday': '今日新增报告获取数',
  'newReportMonth': '本月新增报告获取数',
  'anthorReportGenerationCount': '累计报告获取数',
  'reportGeneration': '报告获取数',
  'userMessage': '用户消息',
  'systemNotification': '系统通知',
  'closed': '已关闭',
  'expired': '已过期',
  'copyTestCode': '显示测试码',
  'copyed': '已复制',
  'testCode': '测试码',
  'testFailed': '测试失败',
  'spain': '西班牙',
  'stage': '阶段',
  'city': '所在城市',
  'recreate': '重新创建',
  "availableTest": '可用测试量',
  "usedTest": '已用测试量',
  "expiredDate": '有效期至',
  "courseDetail": '课时详情',
  "planDetail": '训练总览',
  'chargingMethod': 'HFS结算方式',
  "noYet": '暂无',
  "unlimited": '不限',
  "sendCodeFirst": '请先发送验证码',
  "pleaseCheckCarefully": '请务必如实填写',
  "locatedCity": '所在城市',
  "schoolNature": '学校性质',
  "kindergarten": '幼儿园',
  "public": '公立',
  "private": '私立',
  "international": '国际',
  "sureCenterAddSameChild": "该中心曾添加过同名儿童，请再次确认他们不是同一个小孩",
  "sureCenterAddSameChildContent": '小孩信息可能家长已经添加过，并授权给我们中心',
  "sameNamePrompt": '同名提示!',
  "Confirm Add": '确认添加',
  "overPlan": '结束计划',
  "return": '返回编辑',
  "pleaseResureInfo": '请再次确认家长与儿童信息',
  "childrenInformation": '儿童信息',
  "parentInformation": '家长信息',
  "contactInformation": '联系方式',
  "archives": '档案',
  "questionnaireEvaluation": '问卷测评',
  "motion": '运动',
  "HFSFees": 'HFS费用',
  "HFSFeeOverview": 'HFS费用概况',
  "OrderList": "订单列表",
  "Balance": "余额",
  "AccumulativeConsumption": "累计消费",
  "Recharge": '充值',
  "assessment": '测评',
  "ConsumptionThisMonth": '本月消费',
  "course": '课程',
  "LastSubmissionTime": '最近提交时间',
  "validity": '有效期',
  'ReminderThreshold': '提醒临界金额',
  'AddCreditCharge': '充值/扣款',
  "Consumption": '消费',
  "DeactivationTime": '停用时间',
  'UnitPrice': '单价',
  'TrainingName': '训练名称',
  "NotActive": '未生效',
  "Effectiveing": '生效中',
  "Terminated": '已结束',
  "Suspended": '已停用',
  "canada": '加拿大',
  "LevelOfDifficultyOneDes": '表示非常简单，对孩子来说过于简单；',
  "LevelOfDifficultyTwoDes": '表示比较简单，孩子能轻松完成，稍有难度；',
  "LevelOfDifficultyThreeDes": '表示难度一般，孩子完成起来不那么轻松，有一定难度；',
  "LevelOfDifficultyFourDes": '表示比较困难，对孩子较有难度，但仍可以完成；',
  "LevelOfDifficultyFiveDes": '表示非常困难，孩子几乎完成不了。',
  "LevelOfLikeabilityOneDes": '非常不喜欢；',
  "LevelOfLikeabilityTwoDes": '有些不喜欢；',
  "LevelOfLikeabilityThreeDes": '适中，不反感；',
  "LevelOfLikeabilityFourDes": '比较喜欢；',
  "LevelOfLikeabilityFiveDes": '非常喜欢。',
  "AttentionOneDes": '非常不专注，孩子几乎全程走神；',
  "AttentionTwoDes": '比较不专注，75%的时间会走神；',
  "AttentionThreeDes": '专注度一般，50%的时间能专注；',
  "AttentionFourDes": '比较专注，75%的时间能专注；',
  "AttentionFiveDes": '非常专注，孩子几乎全程专注。',
  "FollowTheInstructionsOneDes": '非常不配合，孩子几乎全程不听指令；',
  "FollowTheInstructionsTwoDes": '比较不配合，75%的时间不听指令；',
  "FollowTheInstructionsThreeDes": '配合度一般，50%的时间听从指令；',
  "FollowTheInstructionsFourDes": '比较配合，75%的时间听从指令；',
  "FollowTheInstructionsFiveDes": '非常配合，孩子全程听从指令。',
  "HyperactivityOneDes": '非常多动冲动，孩子几乎全程多动/冲动；',
  "HyperactivityTwoDes": '比较多动冲动，75%的时间孩子有多动/冲动；',
  "HyperactivityThreeDes": '自控力一般，50%的时间孩子有自控力；',
  "HyperactivityFourDes": '自控力比较好，75%的时间孩子有自控力；',
  "HyperactivityFiveDes": '自控力非常好，孩子几乎全程有自控力。',
  'note': '注：',
  'User Configuration': '用户使用配置',
  "Training": '训练',
  'Remaining Sessions': '剩余课时数',
  'Used Sessions': '已使用课时数',
  'Total Sessions': '已配置课时数',
  "Training Sessions": '训练课时数',
  'Add Sessions': '添加课时数',
  'Add Training Sessions': '添加儿童训练课时数',
  'Select Child': '选择儿童',
  'Enter and search to select': '可输入搜索后选择',
  'Child NameAssessment': '儿童姓名/测评',
  'Remaining Times': '剩余次数',
  'Used Times': '已使用次数',
  'Total Times': '已配置次数',
  'Amount of Assessment': '测评次数',
  'Add Times': '添加测评次数',
  'TheAmountAssessmentNotSet': '该测评次数未设置',
  'Add assessment count': '添加儿童测评次数',
  'The amount of sessions for this child is not set': '此儿童的训练课时数未设置！',
  'The amount of this assessment is not set': '此儿童的此测评次数未设置！',
  'Please contact the admin': '请联系中心管理员',
  'Settings': '去设置',
  'The assessments has been used up': '该测评次数已用完',
  'The assessments for this child has been used up': '此儿童的此测评次数已用完！',
  'The sessions for this child has been used up': '此儿童的训练课时数已用完！',
  'The remaining sessions for this child is': '此儿童的训练剩余课时数{time}，即将用完。',
  'Add Sessions Class': '续课',
  'Add sessions for': '为{childName}的{trainPlanName}续课：',
  'Add sessions for Code': '新增当前{code}音乐模块小时',
  'Added hours limit': '{minNum}≤可续小时数≤{maxNum}',
  'FAS assessment result': '根据FAS测评最新结果加小时数：{musicText}小时',
  'The music for the next session': '下一节课音乐将直接替换为新音乐模块。提交后无法撤回，请谨慎操作!',
  'The actual amount of additional': '注：实际新增课时数根据每课时优脑音乐时长而定',
  'Are you sure to add sessions': '确定续课吗？',
  'Please operate with caution': '提交后无法撤回，请谨慎操作!',
  'There is already an active one for this project': '此计划已有进行中的，请结束后再开启新的',
  'Note that the replaced': '请注意，要替换的新音乐模块{courseCode}与当前的相同。',
  'Please input numbers': '请填写小时数',
  'Hours': '小时数',
  'serverError': '服务器内部错误',
  'taiwan': '中国台湾',
  'deleteTrainDes': '确定删除这个计划吗？删除后无法恢复',
  'musicPause': '音乐已暂停',
  'clickContinue': '点击可继续播放＞',
  'musicBuffering': '网络不稳定，音乐缓冲中{audioLoadProgress}%',
  'gotIt': '知道了',
  'musicRequestFailed': '请求出问题了，请检查网络设置或刷新',
  'Refresh': '刷新',
  'codeSendSucess': '验证码发送成功！',
  'Contact Us': '联系我们',
  'Title': '标题',
  'New Message': '发送消息',
  'suggestions and questions': '如有对于系统使用的意见和建议，请发送给我们。我们将尽快联系或反馈您。',
  'Sent time': '发送时间',
  'Failed Please try again later': '发送失败。请稍后重试',
  'Detailed Description Allow image pasting': '详细描述，可粘贴图片',
  'themeSubject': '主题',
  'sent': '已发送',
  'sureSendMes': '确定发送消息吗？',
  'sure': '确定',
  'Hong Kong': '中国香港',
  'serverError': '服务器内部错误',
  'classAnalysis': '班级训练分析',
  'classNum': '班级数量',
  'monthlyUsedClass': '本月耗课数',
  'DeviceManagement': '设备管理',
  'EnterDeviceSN': '搜索设备序列号',
  'EnterCenterName': '搜索中心名称',
  'DeviceSN': '设备序列号',
  'DeviceName': '设备名称',
  'PendingRegistration': '待注册',
  'UnderReview': '审核中',
  'Registered': '已注册',
  'TimeOfRegistration': '注册时间',
  'Clinician Edition': '临床版',
  'Educator Edition': '教育版',
  'Parent Edition': '家长版',
  'Generate the ID code': '创建身份码',
  'Refresh the ID code': '刷新身份码',
  'Schedule Reminder': '预约上课消息',
  'Guardians Punch Reminder': '家长上课打卡消息',
  'Coach Configuration': '训练师配置',
  'Coach Name': '训练师姓名',
  'Customize': '自定义',
  'Custom Amount': '自定义课时数',
  'Configure training session': '用于对中心独立训练师训练课时数的配置。中心的独立训练师在激活成功后会同步到此列表中。',
  'Personal Configuration': '个人配置',
  'Here is the configuration': '呈现中心为您的训练课时数的配置及使用情况。',
  'watched too many': '此视频已看多次，不可再看',
  'ClassHFSMusic': '班级优脑音乐',
  'planAlreadyInProgress': '此计划当前已有正在进行中的，不可再开启新的',
  'sessionsUsedUp': '您的训练课时数已用完！请联系中心管理员设置',
  'sessionsAmount': '您的训练剩余课时数为{time}，即将用完，请联系中心管理员及时配置',
  'Dont enter Zero': '不可输入0',
  'musicCountMoreThree': '本节课音乐已多次收听，请开启下一节课',
  'sureEndSession': '确定结束本课时吗？',
  'sureEnd': '确定结束',
  'VratFeeOverView': 'VRAT费用概况',
  'VratOrderHistory': 'VRAT订单列表',
  'VratRecharge': '充值金额(¥)',
  'VratSpending': '消费(¥)',
  'FreeTime': '免费次数',
  'FreeTimeAmount': '剩余免费次数',
  'VratUnitPrice': 'VRAT单价(¥)',
  'VratRecharged': '已充值',
  'TestsRemaining': '折合{time}次',
  'VaildUntil': '有效期至',
  'orderNumberName': '订单号/用户姓名',
  'Deduction successful': '扣费成功',
  'Deduction failed': '扣费失败',
  'To be deducted': '待扣费',
  'Deducting': '扣费中',
  'NegativeBalanceDisabled': '余额为负或服务已停用，请及时联系我司工作人员',
  'Get Report failed': '报告获取失败',
  'Deactivated frozen': '已停用冻结',
  "ConnectedPlayFormalMusic": '已连接，播放正式音乐',
  "NotIntoFormalMusic": '暂不播正式音乐',
  'Date of issue': '颁发时间',
  'willLogout': '，即将退出登录.....',
  "Core session Library": 'Core Session库',
  "Week Order": '周次序',
  "Week Topic": '周主题',
  "Day Order": '天次序',
  "Session Title": '标题',
  "Type": '类型',
  "Action": '操作',
  "Day": '第{day}天',
  "Video": '视频',
  "Activity": '活动',
  "Add Thoughts": '添加评论',
  "Load More": '更多',
  "Loaded all": '已加载全部',
  "core-Submit": '提交',
  "Submitted": '提交成功！',
  "Show More": '展开',
  "Show Less": '收起',
  "No data yet": '暂无内容.',
  "core-Details": '详情',
  "Thoughts": '评论',
  "your Thoughts": '你的评论',
  "core-weekDay": '对第{week}周第{day}天的评论',
  "core-week": '第{week}周',
  "add Thought": '添加评论',
  "require thought": '评论是必填的',
  'telMessage': '手机号格式错误，请重新填写',
  'startPlan': '重新开启',
  'successStart': '已重新开启',
  'Guardian Name': '姓名',
  'backTop': '返回顶部',
  'Operation Management': '运营管理',
  'Manage Centers': '中心管理',
  'Add Center': '新建中心',
  'Please enter center/enterprise': '请输入中心/企业',
  'Clear': '清空',
  'Display Name': '中心名称',
  'Enterprise': '所属企业',
  'vCAT Status': 'vCAT状态',
  'Rocket Status': 'Rocket状态',
  'PDT Status': 'PDT状态',
  'vCAT Method': 'vCAT结算方式',
  'HFS Method': 'HFS结算方式',
  'Month': '人工按月',
  'Contract recharge' : '合同充值',
  'Is Active': '是否已激活',
  'Is Send': '是否已发邀请邮件',
  'Edit Center': '编辑中心',
  'Authorize': '授权内容',
  'Change Email': '修改邮箱',
  'Send An Invitation Email': '发送邀请邮件',
  'Name': '中心code',
  'Logo': 'Logo',
  'Used for app': '用于app扫码，200x200px',
  'brand promotion': '呈现于各类报告中，长方形，需设计师确定样式',
  'Address': '地址',
  'Admin Email': '邮箱',
  'Admin Phone': '电话',
  'Institution Type': '中心类型',
  'Education': '教育',
  'Education_US': '教育_US',
  'Select the US version': '选择US版会显示基础版本中文VRAT报告',
  'vCAT Report Type': 'vCAT报告类型',
  'Please select': '请选择为该中心开通权限的报告类型',
  'vCAT QR code': 'vCAT二维码',
  'vCAT report': '选择是否显示vCAT新版报告末尾的产品网站二维码',
  'Project Status': '业务状态',
  'Perform on/off operations': '对vCAT和FocusEDTx的业务进行开关操作。Vrat=vCAT，PDT=FocusEDTx',
  'Is Show Cl Logo': '是否显示Cl Logo',
  'Is Show Center Logo': '是否显示中心 Logo',
  'Whether the CL Logo': 'CL logo在FAS报告、VRAT基础版和标准版报告中的显示',
  'Whether the Center Logo': '中心 logo在FAS报告、VRAT基础版和标准版报告中的显示',
  'Verification Code': '发送验证码',
  'TheraLeap': 'TheraLeap',
  'vCAT_Assessment': 'vCAT问卷',
  'vCAT_Amount': 'vCAT测试量',
  'Log Off': '注销',
  'Reject': '驳回',
  'Export': '导出注册设备列表',
  'Device Information': '设备信息',
  'Device Registration Record': '设备注册记录',
  'Device Usage Records': '设备使用记录',
  'Operation Record': '历史操作',
  'Application approved': '通过申请',
  'Apply to register a device': '申请注册设备',
  'Revoked on the web': '在Web端被撤销',
  'Cancel the application on the client side': '在客户端注销申请',
  'Operation Source' : '操作来源',
  'Operation Time': '操作时间',
  'Device Management List': '设备管理列表',
  'Test List': 'vCAT测试列表',
  'Date created': '测试日期',
  'Case IP': '测试IP',
  'IP Location': 'IP 归属地',
  'IP Address': 'IP地址',
  'Date created times': '测试时间',
  'Available Test(s)': '剩余次数',
  'Valid Until': '有效期',
  'Statistics': '数据统计',
  'Analysis': '指标分析',
  'State': '状态',

  'DelDisplayName': '你确定要删除{displayName}吗？删除后将无法恢复！',
  'Consultation methods': '咨询方式',
  'Can fill in address, etc': '可填写地址、联系方式等信息，会呈现于测评报告中',
  'Database is a database': 'Training为培训、Database为资料库',
  'timesSetting': '次数限制',
  'Frequency limit': '儿童测评和训练次数的限制',
  'Training courses': '训练课达8次发邮件',
  'Training hours': '儿童的训练课时累计=8次时，给中心管理员发提醒邮件',
  'Class class': '班级课达8次发邮件',
  'Accumulated class hours': '儿童的班级课时累计=8次时，给中心管理员发提醒邮件',
  'Counting and settlement': '合同充值每月自动扣除，人工需每月自行清点结算',
  'Limiters': '必须是字母，并且中间不能加空格或者其他符号',
  'valid email address': '请输入有效邮箱',
  'Cover Image': '封面图',
  'UserList': '用户列表',
  'Number of trained children': '训练儿童数',
  'Number of trained children (total)': '训练儿童数（总）',
  'intensive classes': '训练儿童数（30天有耗课)',
  'Period': '周期',
  'searchSchool': '搜索学校',
  'Freeze Users': '冻结用户',
  'birthDay': '出生日期',
  'nickName': '姓名',
  'appVersion': 'App版本',
  'emailConfirmed': '邮件已验证',
  'phoneConfirmed': '电话已验证',
  'vipStatus': 'VIP状态',
  'upgrade VIP': '升级VIP',
  'confirm upgrade VIP': '确认将该用户升级VIP吗?',
  'current state': '当前状态',
  'Device IP': '设备IP',
  'IP Home': 'IP归属地',
  'Return': '返回',
  'Answer results': '做对{correntCount}题，正确率{correntRate}%',
  'Edit Authorization': '编辑授权',
  'Test quantity': '测试量',
  'Billing method': '计费方式',
  'available': '是否可用',
  'School Name': '学校名称',
  'Choose school': '选择学校',
  'new contract': '确定新建合同信息吗？',
  'modify contract': '确定修改合同信息吗？',
  'Deduction': '扣款',
  'Add by quantity': '按数量添加',
  'unlimited quantity': '定时不限数量',
  'Edit Notes': '编辑备注',
  'Disable': '禁用',
  'New test volume': '新建测试量',
  'Current validity period': '当前有效期至：',
  'Reminder value': '提醒值：',
  'reminder values': '修改提醒值',
  'childDetail': '儿童详情页',
  'creationTime': '创建时间',
  'assessmentName': '评估名称',
  'Add New Assessment': '添加新测评',
  'agent': '代理商',
  'Create vCAT test': '新建vCAT测试',
  'attentionIndex': '注意力指数',
  'motionIndex': '情绪指数',
  'less than': '请输入不小于1的正整数',
  'Elapsed class hours': '已耗课时',
  'MissingPatientID': '[无门诊号]',
  'MissingName': '[无姓名]',
  'telMessage': '手机号格式错误，请重新填写',
  'backTop': '回到顶部',
  'sendReport': '发送报告',
  'selectLanguage' : '切换语言 >',
  'downLoadReport': '下载PDF',
  'sure': '确定',
  'getAssessmentQrCode': '获取测评二维码',
  'assessmentExpiryDate':	'测评有效期至：',
  'Acquire Report': '获取报告'
}
