export default {
  'focusEDTTraining': 'FocusEDTx Training',
  'keywords': 'Keywords',
  'trackedDays': 'Tracked(days)',
  'CBTClass': 'Core Session-FocusEDTx',
  'SleepPdt': 'Sleep',
  'NutritionPdt': 'Nutrition',
  'ExercisePdt': 'Exercise',
  'programStartDate': 'Program Start Date',
  'lastViewedDate': 'Last Viewed Date',
  'whickWeekOrDay': 'Week {week} Day {day}',
  'StartFocusEDTx': 'Start FocusEDTx',
  'ReviewStatus': 'Review Status',
  'Reviewed': 'Reviewed',
  'Reviewer': 'Reviewer',
  'ReviewTime': 'Review Time',
  'Review': 'Review',
  'Unreviewed': 'Unreviewed',
  'StartFocusEDTxProgram': 'Start FocusEDTx Treatment Program',
  'SetUpModules': 'Set Up Modules',
  'Emotion': 'Children Emotion-FocusEDTx',
  'ParentLearning': 'Parent Learning-FocusEDTx',
  'ParentEmotion': 'Parent Emotion-FocusEDTx',
  'guardianName': 'Guardian',
  'setUpProgramGoals': 'Set Up Program Goals',
  'submitSuccessfully': 'Successfully!',
  'activatedOfficiallyStarted': 'After the APP for child is activated, it will be officially started.',
  'Modules': 'Modules',
  'Goals': 'Goals',
  'Exercise-PDT': 'Exercise-FocusEDTx',
  'Sleep-PDT': 'Sleep-FocusEDTx',
  'Nutrition-PDT': 'Nutrition-FocusEDTx',
  'Overview': 'Overview',
  'Weekly': 'Weekly',
  'hasFinished': 'Finished',
  'Start': 'Created',
  'whichWeekDay': 'Wk {week} Day {day}',
  'CBTSessionsCompleted': 'Core Session Completed',
  'Week': 'Week',
  'OrderPdt': 'Order',
  'AppStart': 'Ongoing',
  'recordStopTime': 'Last Pause Date',
  'SessionName': 'Session Name',
  'CompletionTime': 'Completion Time',
  'AssignedDate': 'Assigned Date',
  'video_pdf': 'Video+PDF',
  'pdf': 'PDF',
  'text': 'Text',
  'activity': 'Activity',
  'video': 'Video',
  'assigned': 'Assigned',
  'expired': 'Expired',
  'completed': 'Completed',
  'Analysis': 'Analysis',
  'DailyRecording': 'Daily Recording',
  'NumberDailyRecording': 'Number of daily recordings',
  'ContinuousRecording': 'Continuous recording for: {day} days',
  'Dinner': 'Dinner',
  'Snack': 'Snack',
  'Lunch': 'Lunch',
  'Breakfast': 'Breakfast',
  'servings': 'servings',
  'serving': 'serving',
  'Suger unknow': 'Suger unknow',
  'No suger': 'No suger',
  'With suger': 'With suger',
  'Additives unknow': 'Additives unknow',
  'No additives': 'No additives',
  'With additives': 'With additives',
  'caughtup': 'Caughtup',
  'FocusEDTxOn': 'FocusEDTx is on.',
  'Custom Goal': 'Custom Goal',
  'Add Custom Goal': 'Add Custom Goal',
  'SetUpModules': 'Set Up Modules',
  'pauseFocusTitle': 'Are you sure to pause FocusEDTx for {name}?',
  'continueFocuseTitle': 'Are you sure to Resume FocusEDTx for {name}?',
  'pauseFocusContent': "After being suspended, users can still train today, and won't be able to train from tomorrow. Historical data can still be viewed.",
  'focusHasPause': 'FocusEDTx is paused.',
  'focusPause': 'Pause FocusEDTx',
  'continueFocuse': 'Resume FocusEDTx',
  'stopTime': 'Stop time',
  'hey': 'Hi',
  'trainingProcess': 'Training Progress',
  'recentChild': 'Latest Activate Children',
  'commonFeatures': 'Commonly Used Functions',
  'todayClass': 'Session of Today',
  'centerClassNum': 'Total Sessions (This Center)',
  'centerChildNum': 'Total Children (This Center)',
  'yourCenterClassNum': 'Total completed sessions in this center',
  'yourCenterChildNum': 'Total managed children in this center',
  'yourAllClassNum': 'Total completed sessions in multiple centers',
  'yourAllChildNum': 'Total managed children in multiple centers',
  'allClassNum': 'Total Sessions',
  'allChildNum': 'Total Children',
  'notQualified': 'Uncertified',
  'qualificationExpired': 'Certificetion Expired',
  'qualified': 'Certified',
  'trainingStudy': 'Training Proceeding',
  'trainingNotPay': 'Training Unpaid',
  'Adjust Difficulty': 'Adjust Difficulty',
  'Add One Difficulty': 'Add One Difficulty',
  'Reduce One Difficulty': 'Reduce One Difficulty',
  'Only one difficulty': 'Only one difficulty can be added or reduced for each session.',
  'If there is feedback': 'If there is feedback for this session, the feedback will be deleted after submission.',
  'trainingNotStart': 'Training Assigned',
  'trainingExpired': 'Training Expired',
  'trainingStop': 'Training Paused',
  'collapse': 'Fold',
  'unfold': 'Unfold',
  'more': 'More',
  'Stop': 'Paused',
  'goAdd': 'Add',
  'getDataFail': 'Failed to obtain data',
  'trainingNotEdit': 'The training application form is not completed',
  'selectOption': 'Select the option to generate the assessment',
  'useWechatSee': 'Using Wechat to scan and take assessments',
  'VRATStatistics': 'vCAT Statistics',
  'sessions_VRAT': 'Data Trend',
  'HFSOverview': 'Overview of HFS spending',
  'HFSSpending': 'Trend of HFS spending',
  'HFSProportion': 'Proportion of HFS spending',
  'HFSBalance': 'HFS Balance',
  'amount': 'Amount',
  'spending': 'Spending',
  'thisYear': 'This Year ',
  'thisMonth': 'This Month',
  'totalSession': 'Total Used Session(s)',
  'todaySession': 'Used Sessions (daily)',
  'usedSessions': 'Used Session(s)',
  'quantity': 'Quantity',
  'emptyData': 'There is no data yet',
  'date': 'Date',
  'jan': 'Jan',
  'feb': 'Feb',
  'mar': 'Mar',
  'apr': 'Apr',
  'may': 'May',
  'jun': 'Jun',
  'jul': 'Jul',
  'aug': 'Aug',
  'sept': 'Sept',
  'oct': 'Oct',
  'nov': 'Nov',
  'dec': 'Dec',
  'OnlinePlan': 'Training-Online',
  'OfflinePlan': 'Training-Offline',
  'VRATNum': 'Total vCAT Report(s)',
  'sessionsCompleted': 'Completed sessions, including Training sessions and Class sessions.',
  'TotalVRATNum': 'Total vCAT Report(s)',
  'TodayVRATNum': 'vCAT Reports (daily)',
  'VRATReportsNum': 'Total Generated vCAT Reports',
  'todayFASNum': 'FAS Reports (daily)',
  'totalFASNum': 'Total FAS Report(s)',
  'FASQuestionnaires': 'Total FAS Questionnaires',
  'todaySpending': 'HFS Spending (daily)',
  'HFSDes': 'HFS spending including assessments, trainings, classes, and courses',
  'totalSpending': 'Total Hfs Spending',
  'exportList': 'Export List',
  'onlineSessions': 'Used Online Sessions',
  'offlineSessions': 'Used Offline Sessions',
  'totalSessions': 'Total Sessions',
  'recentFinishingTime': 'Recent Finishing Time',
  'charts_feedback': 'Records',
  'assessmentDetails': 'Assessment Details',
  'mid_assessment': 'Mid-assessment',
  'pre_assessment': 'Pre-assessment',
  'planDetails': 'Plan Detail',
  'HFSMusicRecords': 'HFS Music Playing Records',
  'musicFeedback': 'HFS Music Feedback',
  'classList': 'Class List',
  'className': 'Class Name',
  'classType': 'Type',
  'numberOfChildren': 'Number Of Children',
  'maximum': 'Maximum',
  'currentTrainingPlan': 'Current Training Plan ',
  'classCreateTime': 'Creation Time',
  'participationTime': 'Participation Time',
  'toddler': 'Toddler',
  'preschool': 'Preschool',
  'gradeSchool': 'School Age',
  'teenage': 'Adolescent',
  'adult': 'Adult',
  'grade_teenage': 'Grade School/Teenage',
  'trainingPlanName': 'Training Plan',
  'addClass': 'Add Class',
  'sureAdd': 'Add',
  'classRule': '2-50 characters, repeat name are not permitted in the center',
  'maxChildren': 'Max Children',
  'classDes': 'Maximum children of one-to-one class equals to 1, otherwise it is a one-to-multiple class',
  'OneToOne': 'OneToOne',
  'OneToTwo': 'OneToTwo',
  'OneToThree': 'OneToThree',
  'OneToFour': 'OneToFour',
  'OneToFive': 'OneToFive',
  'OneToSix': 'OneToSix',
  'assignCoach': 'Assign Coach',
  'allTrainer': 'All Trainer',
  'later': 'Later',
  'manageChildren': 'Manage Children',
  'clinicNumber': 'Clinic Number',
  'manageTips': 'No more than {num} children',
  'manageDes': "The new added children follow the current training progress, and the HFS music maintains it's original progress",
  'associatePlan': 'Associate a training plan',
  'selectPlan': 'Select training plan',
  'Operation record': 'Operation record',
  'canNotEdit': 'Can not be modified',
  'trainingPlan': 'Training plan',
  'editClass': 'Edit class',
  'classRule2': 'The minimum number of children must not be less than the current number.',
  'trainingOverview': 'Training overview',
  'dailySubscriptions': 'New subscriptions (daily)',
  'monthlySubscriptions': 'New subscriptions (monthly)',
  'totalPlans': 'Total training plans ',
  'totalUsedClass': 'Total Used Session(s)',
  'copyed': 'Copied',
  'goingPlans': 'Total ongoing training plans',
  'newTrainingPlans': 'Total new training plans',
  'completedTrainingPlans': 'Completed training plans',
  'dailyNewClassNum': 'New subscriptions (daily)',
  'monthlyNewClassNum': 'New subscriptions (monthly)',
  'totalClass': 'Total sessions',
  'totalSuspendedSessions': 'Total suspended sessions',
  'recharge_Charge': 'Recharge/Charge',
  'orderNum_products_name': 'Order number／Products／Name',
  'exxportListLoading': 'Exporting list, please wait...',
  'productTypes': 'Types',
  'whichHours': '{num}hr(s)',
  'onlineTraining': 'Online training',
  'offlineTraining': 'Offline training',
  'oneToMore': 'One To More',
  'appPrice': 'Online Price',
  'webPrice': 'Offline Price',
  'oneToMoreOnlineCourse': 'Class - Online',
  'oneToMoreOfflineCourse': 'Class - Offline',
  'OneToOneOnlinePlanCourse': 'One To One - Online',
  'OneToOneOfflinePlanCourse': 'One To One - Offline',
  'OneToTwoOnlinePlanCourse': 'One To Two - Online',
  'OneToTwoOfflinePlanCourse': 'One To Two - Offline',
  'OneToThreeOnlinePlanCourse': 'One To Three - Online',
  'OneToThreeOfflinePlanCourse': 'One To Three - Offline',
  'OneToFourOnlinePlanCourse': 'One To Four - Online',
  'OneToFourOfflinePlanCourse': 'One To Four - Offline',
  'OneToFiveOnlinePlanCourse': 'One To Five - Online',
  'OneToFiveOfflinePlanCourse': 'One To Five - Offline',
  'OneToSixOnlinePlanCourse': 'One To Six - Online',
  'OneToSixOfflinePlanCourse': 'One To Six - Offline',
  'orderNumber': 'Order number',
  'productsName': "Products",
  'section': "Section",
  'spending': 'Spending',
  'logoutUser': 'Logout',
  'copy': 'Copy',
  'InviteEmployee': 'Invite employee',
  'WhoAccountSettings': "{name}'s Account Settings",
  'name_phoneNum': 'Name/Phone number',
  'successful': 'Successful',
  'orderStatus': 'Order status',
  'orderDate': "Order date",
  'unprocessed': 'Unprocessed',
  'approved': 'Approved',
  'rejected': 'Rejected',
  'allApplications': 'All application(s)',
  'applicationTime': 'Application time',
  'reviewStatus': "Review status",
  'batchApprove': 'Batch approve',
  'batchReject': 'Batch reject',
  'applicationForm': 'Application form',
  'timetable': 'Timetable',
  'availablePlaces': 'Available place {time}(s)',
  'usedPlaces': 'Used place {time}(s)',
  'totalPlaces': 'Total place {time}(s)',
  'privatePayment': 'Private payment',
  'qualifiedTrainees': 'Certificated trainee(s) {num}',
  'availablePeriod': 'Available day(s)',
  'arrangeTime': 'Date created',
  'Training and Classes': 'Training and Classes',
  'The Training and Classes of': 'The Training and Classes of {name}',
  'Offline Classes (hrs)': 'Offline Classes (hrs)',
  'Online Classes (hrs)': 'Online Classes (hrs)',
  'Supervision Score': 'Supervision Score',
  'totalPrivatePayment': 'Total private payment',
  'Exams and Certificates': 'Exams and Certificates',
  'Order': 'Order',
  'ScoreAdd': 'Score',
  'Total Score': 'Total Score',
  'PracticalExam': 'Practical Operation',
  'OralExam': 'Oral  Exam',
  'WrittenExam': 'Written Exam',
  'Grade': 'Grade',
  'Comments': 'Comments',
  'Training Module': 'Training Module',
  'The Arranged Center': 'The Arranged Center',
  'Certificate Status': 'Certificate Status',
  'Certificate Name': 'Certificate Name',
  'Exam Type': 'Exam Type',
  'Make-up Exam': 'Make-up Exam?',
  'OrderTimes': 'Order',
  'Submitter': 'Submitter',
  'Score': 'Score',
  'learningStatus': 'Learning status',
  'proceeding': 'Proceeding',
  'paused': 'Paused',
  'issued': 'Issued',
  'unissued': 'Unissued',
  'from': 'Assignee',
  'centerArranged': 'Center arranged',
  'weixinAppplied': 'H5 applied',
  'availabilityOfPlaces': 'Availability of places',
  'centerPayment': 'Center payment',
  'privatePayment': 'Private payment ',
  'paymentMethods': "Payment method",
  'totalPayment': "Total payment",
  'learnStatus': 'Status',
  'startlearnDate': 'Start date',
  'pausinglearnDate': 'Pausing date ',
  'endLearnDate': "End date",
  'qulification': "Certificate",
  'arrange': 'Arrange',
  'training': 'Training',
  'searchName': 'Name',
  'supervisionReport': 'Supervision Report',
  'supervisorRecords': 'Supervisor records',
  'coachRecords': 'Coach records',
  'submitQuestion': 'Submit',
  'projectNotProgress': 'Are you sure this project is not in progress?',
  'submitDes': 'Are you sure to submit?',
  'fillNameProfile': "Please fill in the necessary information of {name}'s profile",
  'informationDes': "Essential information for profile: [Diagnostic information ][children's strengths and challenges]",
  'submitted': 'Submitted',
  'fillOut': 'Fill out',
  'certificationList': 'Certificate(s) list',
  'dateOfIssue': 'Date of issue',
  'nameOther': 'Name',
  'badge': 'Badge',
  'certificateNumber': 'Certificate number',
  'certifier': 'Certifier',
  'vaild': 'Vaild',
  'trainfeedback': 'Feedback',
  'downloadTheCertificate': 'Download the certificate',
  'trainingApplicationForm': 'Training Application Form',
  'certified': 'Certified',
  'expectedLearningTime': 'Expected learning time ',
  'learningPeriod': 'Learning period',
  'joinExam': 'Take the exam',
  'testDes': 'Test (staff only)',
  'certificate': 'Certificate',
  'retake': 'Retake',
  'trainingDes': 'Please contact the person in charge of training',
  'sesResults': 'Results',
  'notificationCenter': 'Notification center',
  'deauthorization': 'Deauthorization',
  'thereIsNoData': 'There is no data yet',
  'twoDaysAgo': 'Two days ago',
  'today': 'Today',
  'yesterday': 'Yesterday',
  'searchClinicNumber': 'Search clinic number',
  'guardianDetails': 'Guardian detail',
  'childrenList': 'Children',
  'QRCode': 'QR code',
  'addedByCenter': 'Center added',
  'creationStartTime': 'Date created',
  'leftHander': 'Left-hander',
  'rightHander': 'Right-hander',
  'courseName': 'Course name ',
  'sessionNumber': 'Session number',
  'createdByThisCenter': 'Created by this center',
  'deauthorizationTime': 'Deauthorization Time',
  'testResult': 'Test result',
  'Cancelled': 'Cancelled',
  'dateDes': 'From 00:00 of the start date to 24:00 of the end date, if not filled in will be "Indefinite"',
  'dateError': 'The end date should be later than the start date',
  'parentInterviews': 'Parent Interviews',
  'class': 'Class',
  'HFSMusic': 'HFS music ',
  'playHistory': 'Play history',
  'editHFSMusic': 'Edit HFS music',
  'generatioType': 'Generation type',
  'basedOnHFSResults': 'Based on HFS results',
  'otherType': 'Other',
  'noHFSDes': 'No suitable FAS results, please arrange FAS for parents',
  'pending': 'Pending',
  'editClinicNumber': 'Edit clinic number',
  'generationHFSMusic': 'Generation HFS Music',
  'batchDownloadForm': 'Batch Download Feedback Form',
  'batchDownloadFormDes': 'Please enter the sequence number of the session feedback form to download',
  'adjustSessionTime': 'Adjust Plan',
  'scheduleSession': 'Schedule',
  'activateNextSessionDes': 'Are you sure to activate the next session',
  'archivesCompleted': 'Completed',
  'sessionFeedback': 'Feedback',
  'sessionFeedbackForm': 'Feedback form',
  'preview': 'Preview',
  'volumeDes': 'The volume should be set at the level that children can listen to the music while easily capturing the conversation',
  'trainingActivities': 'Training activity',
  'adjustActivitDes': 'Only the type of activities with lower difficulty can be adjusted',
  'Difficulty': 'Difficulty',
  'adjustActivityDifficulty': 'Adjust activity difficulty',
  'adjustSessionDifficulty': 'Adjust Program Difficulty',
  'feedbacksubmitted': 'Submitted',
  'feedbackincompleted': 'Incompleted',
  'bottomLine': 'Bottom line',
  'scheduleAssessment': 'Schedule assessment',
  'terminateThePlan': 'Terminate the plan',
  'loadMore': 'Load more',
  'toAdd': 'Add',
  'toBeOpened': 'To be opened',
  'switchClassStatus': 'Switch status',
  'startClass': 'Start',
  'continueClass': 'Continue',
  'finishClass': 'End',
  'finishedTrainingPlan': 'Finished training plan',
  'activationTime': 'Activation time',
  'startFrom': 'Start from',
  'goingTime': 'Time',
  'onlineSession': 'Online session',
  'offlineSession': 'Offline session',
  "logout": "Sign Out",
  'online': 'Online',
  'offline': 'Offline',
  'markAsComplete': 'Mark as complete',
  'markDes': 'Are you sure to mark it  as complete?',
  'studyCompleted': 'Completed',
  'whichMins': '{num}min(s)',
  'backToThePresent': 'Back to the present',
  'pass': 'Pass',
  'accuracyRate': ' ',
  'allSubmit': 'Submit',
  'HFSMusicPlayingTime': 'HFS music playing time',
  'personal': 'Personal',
  'thisCenter': 'This center',
  'totalUsedSessions': 'Total Used Session(s)',
  'previousClass': 'Previous',
  'Monday': 'Monday',
  'Tuesday': 'Tuesday',
  'Wednesday': 'Wednesday',
  'Thursday': 'Thursday',
  'Friday': 'Friday',
  'Saturday': 'Saturday',
  'Sunday': 'Sunday',
  'onlyCenter': 'Center',
  'randomName': 'Random',
  'startTimefrom': 'Start from',
  'durationHour': 'The duration is 1 hour',
  'type': 'Types',
  'repeat': 'Repeat',
  'never': 'Never',
  'oneWeek': '1 week',
  'twoWeeks': '2 weeks',
  'oneMonth': '1 month',
  'numberOfRepetitions': 'Number of repetitions',
  'enterAnIntegerGreater': 'Please enter an integer greater than 0',
  'textReminder': 'Text	reminder',
  'guardian': 'Guardian',
  'reminderDes': 'Text reminder and in-system notifications are sent approximately 24 hours in advance.',
  'startMoreEnd': 'The start time should be later than the current time',
  'scheduleASession': 'Schedule a session and synchronize to the timetable',
  'scheduleDes': 'Tips: Scheduled sessions can be edited/deleted before it starts',
  'downloadConsentForm': 'Download consent form',
  'cogleap': 'Cogleap',
  'anySuggestions': "Are there any suggestions for improvement of today's session: ",
  'absentRepeatOne': 'Absent, repeat 1 most recent lesson: ',
  'absentRepeatTwo': 'Absent, repeat 2 most recent lesson: ',
  'calmingLessonDes': 'The child is in a bad mood/overly stressed today, temporarily adjust 1 Calming calming lesson',
  'pleaseSelect': 'Please select',
  'adjustmentDes': 'If there is feedback in the current session, it will be deleted after adjustment',
  'enterEmail': 'Please enter your email address',
  'emailUserd': 'This email address is already in use by the current center or another center',
  'enterRightEmail': 'Please enter the correct email address',
  'twoPasswordDiff': 'The new password is inconsistent with the confirm password',
  'accountRegistSucess': 'Account registered successfully',
  'lessOneChild': 'Please add at least one child',
  'phoneError': 'The phone number is incorrect, please fill in again',
  'sendCodeFirst': 'Please send a SMS code first',
  'notSameOld': 'The new email account cannot be the same as the original email account',
  'passwordError': 'Incorrect  password',
  'submitFeedBackDes': 'Please submit all feedback before end this session',
  'checked': 'Checked',
  'addAssessment': 'Add assessment',
  'selectAssessment': 'Select assessment',
  'HFSServiceInvalidDes': 'HFS service is invalid, please contact the cogleap staff',
  'figure': 'Figure',
  'checkInRecords': 'Check in records',
  'totalItem': 'Total {num} items',
  'downloadSeconds': 'The download is expected to complete within {time} seconds',
  'totalQuestions': '{num} question(s)',
  'correctQuestions': '{num} correct',
  'worryQuestions': '{num} incorrect',
  'totalQuizs': '{num} quizs',
  'accountSetting': 'Account Setting',
  'QrCodeOfThisCenterAPP': 'Center QR Code (APP)',
  'CoachCode': 'Coach Panel',
  'GuardianCode': 'Guardian Panel',
  'QRCodeofThisCenterWeChat': 'Center QR Code (WeChat)',
  'trainingApplicationCode': 'Training Application',
  'QRCodeOfReport': 'View Report (Guardian)',
  'saveImage': 'Save image',
  'centerCode': 'Center code:',
  'WechatScan': 'Scan the QR code',
  'noUnreadMessage': 'There is no unread message',
  'enterMessage': 'Enter the message center',
  'allMarkedAsRead': 'All marked as read',
  'markAsRead': 'Mark as read',
  'viewTainingFeedback': 'View training feedback',
  'administratorType': 'Administrator Type: ',
  'FASReportObtained': 'FAS report was not obtained',
  'obtainReport': 'Obtain report',
  'reportedOther': 'Obtained',
  'trainingListeningRecords': 'Training and listening records',
  'nameMusicRecord': "{name}'s training and listening record",
  "ToBeGenerated": "To be generated",
  "ToBeActivated": "To be activated",
  "Online": "Online",
  "Offline": "Offline",
  'close': 'Close',
  "Absent": "Absent",
  "OccupiedClass": "Occupied",
  'HFSMusicRecord': 'HFS music playback record',
  'newAuthorization': 'New Authorization',
  'trainingCheckIn': 'Training Check-in',
  'assessmentSubmission': 'Assessment Submission',
  'exerciseActivated': 'Exercise Activated',
  'sleepActivated': 'Sleep Activated',
  'nutritionActivated': 'Nutrition Activated',
  'trainingScheduled': 'Training Scheduled',
  'Certified': 'Certified',
  'administrator': 'Administrator ',
  'valid': 'Valid',
  'pleaseSelectDuration': 'Please select duration',
  'pleaseSelectDate': 'Please select the date',
  'uploadAImage': 'Upload a image',
  'uploadAVideo': 'Upload a video',
  'invalid': 'Invalid',
  'remainingTimes': 'Remaining times: ',
  'remainingVRAT': 'Remaining vCAT',
  'image': 'Image',
  'remainingTrainingSessions': 'Remaining training sessions',
  'remainingAssessments': 'Remaining assessments',
  'RemainingHFSTraining': 'Remaining HFS training',
  'unlimitedTimes': 'Unlimited times',
  'attendance': 'Attendance',
  'confirmTheAttendanceOfStudents': 'Please confirm the attendance of student(s)',
  'selectTheAttendanceOfStudents': 'Please select the attendance of student(s)',
  'HFSMusicToBeGenerated': 'HFS music to be generated',
  'lisenMoreThanFive': 'If the offline music listening progress is >=50%, it cannot be replaced by online',
  'whoHFSMusicListeningRecord': "'s HFS music listening record",
  'OnlyRecordFirst': 'Only record the first playback record',
  'whichSession': 'Session {num}',
  'whichSessionOther': 'session {num}',
  'nSession': '{num} session(s)',
  'nTest': '{num} Test(s)',
  'expiredOther': 'Expired',
  'OneToMany': 'OneToMany',
  'GoldenAge': 'Golden Age',
  'OpiOpii': 'Best Performance (OPI+OPII)',
  'assessmentSuccessfully': "Assessment successfully scheduled! It has been sent to the user's APP side.",
  'resultHasSent': 'Result has been sent to the user！',
  'allTimesWereSubmitted': 'Submitted {num} time(s)',
  'notStarted': 'Undo',
  'Incompleted': 'Incompleted',
  'letStartLearning': "Let's start learning",
  'submittedOther': 'Submitted',
  'whichFeedback': 'Feedback of session {num}',
  'adjust': 'Adjusted',
  'trial': 'Trial',
  'activateNextSession': 'Activate next session',
  'sureActivate': 'Activate',
  'finish': 'Finish',
  'finishedCanNotPlay': 'Finished, can not play',
  'Volume': 'Volume：',
  'usedSessionsDuration': 'Used Sessions Duration',
  'staticActivities': 'Static activity',
  'personalFeedback': 'Personal feedback',
  'sessionIsNotTakingPlace': 'Are you sure this session is not taking place?',
  'sessionWillBeUndo': 'Once submitted, the HFS music and training items for this session will be "undo"',
  'musicIsNotTakingPlace': 'Are you sure this HFS music is not taking place?',
  'mandatoryField': ' Mandatory field',
  'noFeedbackData': 'No feedback data',
  'scheduleSessionsChild': "Schedule sessions for {name}'s {trainName}",
  'trainingPeriodEnd': ' The training sessions has all ended, are you sure to close the training plan?',
  'cancelLater': 'Cancel',
  'createSchedule': 'Create a schedule',
  'editSingleSchedule': 'Edit single schedule',
  'editMultipleSchedules': 'Edit multiple schedules',
  'repeatEveryDay': 'Repeat every {day}',
  'repeatFortnightly': 'Repeat every {day} fortnightly Friday',
  'RepeatEachMonth': 'Repeat on the {day}th of each month',
  'phoneNumberIncorrect': 'The phone number format is incorrect',
  'notStartedFillFeedback': 'Please end the session first, otherwise you will not be able to fill in the overall feedback',
  'viewFASReports': 'View the latest FAS reports',
  'FASReported': 'FAS Basic Competency Assessment Reported',
  'CMSMO': 'Outstanding Student Program(CM+SMO)',
  'SMG': 'Grade school Outstanding Student Program (SMG)',
  'continuePreviousProgress': 'Continue with the previous progress ',
  'startFromFirst': 'start from 01',
  'restartFromFirst': 'Whether to restart from 01',
  'EditedHFSAs': 'Edited the HFS music as',
  'modulePreviouslyUpload': "Edited HFS music is {newMusic}, {name}'s former module has been upload to {code}",
  'pleaseSelectDateAndTime': 'Please select the date and time',
  'feedbackTime': 'Feedback time：',
  'paymentAmount': 'Payment amount',
  'userInformation': 'User information',
  'currentFileCannotplayed': 'Request failed. Check network or retry later',
  'tableOfContent': 'Table of content',
  'listened': 'Listened：',
  'music': 'Music',
  'noMusicPlayed': 'No music has been played',
  'newlyArrangedTraining': ' You have a newly arranged training, go check it out',
  'viewTheTraining': 'View the training',
  'notForNow': 'Not for now ',
  'goTrainingCenter': 'You can go to the [Training Center] later to check the training',
  'nameToEdited': 'Name to be edited',
  'trainingActivityAllChecked': 'Training activity: all checked in',
  'WechatScanPay': 'Wechat scan to pay ',
  'amountPayable': 'Amount payable：',
  'orderClosed': 'Order closed',
  'orderRefunded': 'Order refunded',
  'jumpingToLearn': 'Jumping to learn',
  'trainingBeenPaidSuccessfully': 'Your {trainingModuleName} training has been paid successfully',
  'unpaid': 'Unpaid',
  'pleaseSelectPaymentMethod': 'Please select payment method：',
  'Wechat': 'Wechat',
  'pleasePayYourTraining': 'Please pay your {trainingModuleName} Training payment',
  'pleasePayYourTrainingTwo': ', you can start the training',
  'functionIsNotOpen': 'The function is not open, please wait',
  'pay': 'Pay',
  'selectTheTrainee': 'Select the trainee to be approved in batches!',
  'resultsSubmitted': 'Review results submitted! Jumping to schedule training for these users...',
  'hasStarted': 'Started',
  'continueTrainingSupervisor': ', If you would like to continue, please contact your training supervisor',
  'testInformationFail': 'Failed to obtain test information. Please contact your administrator',
  'generateRetestPaperFail': 'Failed to generate a retest paper, contact your administrator ',
  'testInstructions': 'Test instructions',
  'examDuration': 'Exam Duration (minutes)',
  'numberOfQuestions': 'Number of question(s)',
  'testScore': 'Test score',
  'firstTimeExam': 'There is no charge for the first time {name} written exam. You will only have one opportunity to answer the exam free of charge. Please carefully decide whether to start immediately',
  'examResults': 'Exam results who gets {score} or higher will pass this exam',
  'thisTimeExamRetakeFee': 'The {name} written test retake fee is {price} RMB, after the payment you can begin to answer',
  'joinNow': 'join now',
  'continueWithLastExam': 'Continue with the last exam',
  'takeExam': 'Take exam',
  'wait': 'Wait',
  'Issue': 'Issue',
  'retakeExamsNotTimes': 'The number of retake exams has been used up, please contact the training supervisor',
  'completeFirst': 'Please complete first {name} Study all the sections',
  'systemMessageDetails': 'System Message Details',
  'unauthorizedStatus': 'Unauthorized status. If you want to start this module, please contact Cogleap staff',
  'clickBlankAreaBothSide': 'Click the blank area on both sides of the document to scroll up and down',
  'promptForAction': 'Prompt for action',
  'uploadFilePDFFormat': 'Please upload the file in PDF format',
  'serialNumberEditing': 'Serial number editing (number means after X,0 means pinned)',
  'bluetoothEarphone': 'Bluetooth earphone',
  'confirmBluetoothIsConnected': 'Confirm if the Bluetooth headset is connected?',
  'adjustVolumeBeforePlaying': 'Please connect the  bone conduction headphones and adjust it to the suitable volume before playing',
  'clickToAudition': 'Click to audition',
  'correct': 'Correct Answer',
  'miss': 'Omission Error',
  'wrong': 'Commission Error',
  'headMovement': 'The amplitude of head movement during the test',
  'warningLine': 'Warning Line (50)',
  'yourScore': 'Your score',
  'goalFocus': 'Goal focus',
  'downloading': 'Downloading...',
  'totalSessionsOther': 'Total {num} sessions',
  'ILSMusic': 'iLSMusic',
  'fixationDirectionTracking': 'Fixation direction tracking',
  'selfMotionControl': 'Self-motion control',
  'continuousAttention': 'Continuous attention ',
  'resolution': 'Resolution',
  'reactionStability': 'Reaction stability',
  'reactivity': 'Reactivity',
  'behavioralSelfControl': 'Behavioral self-control',
  'whichMusic': '{num} session(s) music ',
  'sureCloseMusic': 'Are you sure you close the HFS music playback bar? ',
  'score': 'Score：',
  'summaryOfProbationSupervision': 'Summary of probation supervision',
  'editCV': 'Edit CV',
  'reviewTrainingApplications': 'Review training applications',
  'decline': 'Decline',
  'pass': 'Pass',
  'resultOfTrainingEmail': 'The result of your training request review for user {email} is: ',
  'useRole': 'User role:',
  'batchReviewApplications': 'Batch review training applications',
  'rolesPeopleAreInconsistent': 'Note: if the roles of some people are inconsistent, please cancel and deal with them separately!',
  'batchThesePeople': 'You are batch reviewing the training applications of {name} , please assign roles for these people ',
  'review': 'Review',
  'sureSubmitReviewResults': 'Are you sure to submit review results?',
  'selectRejectedBatches': 'Please select trainees to be rejected in batch!',
  'unselectedRole': 'Unselected role',
  'confirmedBatchesRejecting': 'You are rejecting the training application of {name} in batches, and the user needs to re-apply after the rejection, is it confirmed? ',
  'searchForName_email_phone': 'Search for name/email/phone',
  'downLoadWhichFeedback': '{fileBaseName} {num} session(s) Feedback',
  'downLoadWhichToWhichFeedback': '{fileBaseName} Session {startNum}-{endNum} Feedback',
  'everyday': 'Everyday',
  'repeat': 'Repeat',
  'youNotPermission': 'You do not have permission',
  'chooseTheCourseAsTheLatest': 'Choose the 20:00 course as the latest',
  'unknownCoach': 'Unknown coach',
  'past': 'Past',
  'coursewareDetails': 'Courseware detail',
  'introduction': 'Introduction',
  'five_stepCycle': 'The five-step cycle',
  'detailsDescription': 'Details description',
  'coursewareName': 'Courseware name',
  'coursewareNumber': 'Courseware number',
  'estimatedTrainingDuration': 'Training duration',
  'suitableAge': 'Suitable	age',
  'suitableNumberPeople': 'Suitable number of people',
  'trainingGoals': 'Training goals',
  'trainingMaterials': 'Training materials',
  'orderNumber': 'Order number',
  'trainingMaterialsName': 'Training materials name',
  'minutesLeftInExam': 'There are 3 minutes left in the exam',
  'theExamEnds': 'The exam ends',
  'automaticallySubmitted': ' The exam is over, we have automatically submitted your paper. Thank you for your participation',
  'failedSubmitAnswer': 'Failed to submit answer',
  'clickStartTest': 'Click to start the test',
  'expire': 'Valid until {time}',
  'signOut': 'Sign Out',
  'activateClass': 'Activate',
  'startTheExam': 'Start the exam ',
  'failedStartExam': 'Failed to start the exam',
  'autoSubmitted': 'Timed out, auto-submitted',
  'getExamInformation': 'Failed to get exam information',
  'sureSubmitQuestionnaire': 'There are still unfinished question(s), are you sure to submit the questionnaire?',
  'closePrompt': 'Close the prompt',
  'trainingPlanOver': 'The training plan is over',
  'confirmExitExam': 'Confirm to exit the exam',
  'answersWillSubmitted': 'After exiting, please return to continue the exam in time, the exam will continue to be timed! The answers will be submitted directly after the deadline',
  'examHasEnded': 'The exam has ended',
  'startBeforeOperation': 'Please start this session before operation',
  'musicPlayedStartNew': 'The current music module has all been played. If you need to continue the next stage of training, you can start the new music module after assessment.',
  'musicCannotSureEnd': 'The music cannot continue to play after the end of the class, are you sure to end it?',
  'AppContinueListen': 'To ensure the training effect, you can advise parents to let their children continue to listen on the app',
  'HFSMusicListening': "'s HFS music listening progress is less than ",
  'notScheduledNextSession': 'Not scheduled for the next session',
  'shouleParentInterview': "You will need to complete the {name}'s parent interview at this stage, otherwise you will not be able to start this session.",
  'selectChildDownloadLesson': 'Please select a child to download the lesson feedback form',
  'selectChildPreview': 'Please select a child to preview the class feedback form',
  'reasonablyAccording': 'Please use it reasonably according to the number of times you need to be supervised',
  'noTrialsAvailable': 'There are no trials available, please contact your project administrator',
  'NoteParentsInterview': 'Note: Parents interview should be scheduled regularly',
  'completeInterviewPoperly': 'Please make an appointment with parents to complete interview to ensure that the follow-up training is proceeding properly',
  'currentDevicePlayable': 'The current device is not playable',
  'otherChildrenMusicPlaying': "Other children's music is playing on the device",
  'confirmChange': 'Confirm the change',
  'replaceHFSmusic': "Are you sure to replace {name}'s  HFS music?",
  'onlineSessionCannotPlayed': 'This is online session and cannot be played at the coach side.',
  'switchCurrentLatest': 'Switch to the current latest course action',
  'notPlayable': 'Not playable',
  'leave': 'Leave',
  'thisSessionEnded': 'This session has ended',
  'musicPlayingAgain': "Children's music is playing, close the music and try again",
  'musicPlayingProceed': 'HFS music is playing, leaving will stop the music. Are you sure to leave?',
  'playingCurrentDevice': 'Playing on the current device',
  'playingOtherDevice': 'Playing on the other device',
  'afterPlanCanPerformed': 'Once the plan is end, no operation can be performed on it',
  'completeAllSessionsEndingPlan': 'Please complete all sessions and have a full evaluation before ending the plan',
  'authorisingChildren': 'Authorising children',
  'confirmAttendanceChildren': 'Please confirm the attendance of children',
  'endClass': 'End',
  'presentClass': 'Present',
  'absent': 'Absent',
  'absentOther': 'Absent',
  'downloadedTips': 'Up to 20 consecutive pages can be downloaded',
  'selectAChild': 'Please select a child',
  'sureDownload': 'Are you sure to download?',
  'anthorizeCenter': 'Authorize to the center',
  'enterSessionDownload': 'Please enter the session order to download: ',
  'outstandingStudentProgram': 'Outstanding Student Program：',
  'editMusicForName': 'Modify HFS music {music} for {child}?',
  'selectOneChild': 'Select one child',
  'goGuardianOkText': '[Guardian List]',
  'centerAdmin': 'Admin',
  'iknow': 'OK',
  'downloadQRCodeOther': 'Download',
  'goGuardianContentText': '{phone} has been registered, please go to [Guardian List] to add chlidren.',
  'coachModalTitle': 'The parent is already in the center, and the coach is {coach}',
  'coachModalContent': 'If you need to create a new test or add a new child to the parent, you can contact the admin or front desk to reassign coaches.',
  'anotherCenterTitle': 'The parent is already in another center.',
  'anotherCenterContent': 'To add the parent to the center, please scan the QR Code.',
  'children': 'Children',
  'reminder': 'Reminder',
  'musicHasEnd': 'The HFS music of this session has all been played. Do you want to replay it?',
  'replay': 'Replay',
  'toGetPreReport': 'Please go to get the the pre-assessment report first.',
  'continue': 'Continue',
  'trainingPlanDetails': 'Training Plan Details',
  'sessionFirstGiveFeedback': 'Please end the session first and then give feedback',
  'orderDetails': 'Order detail',
  'signOnChildrenToday': 'There are no sign-in children today',
  'basicIntroduction': 'Basic Introduction',
  'courseware': 'Courseware',
  'numberOrder': 'Number',
  'numberOrderOther': 'No.',
  'numberOfSessions': 'Number of sessions',
  'feedbackTrainingPlan': 'Feedback (Training plan)',
  'feedback_session': 'Feedback (session)',
  'file': 'File',
  'session': 'Session',
  'volumeOnly': 'Volume',
  'trainingIntroduction': 'Training introduction',
  'TrainingInstructions': 'Training instructions',
  'noTestScheduled': 'No test is scheduled at present',
  'getReport': 'Get report',
  'viewReport': 'View report',
  'details': 'Details',
  'assessmentOnlyWechat': 'The assessment can only be filled in by scanning code on wechat',
  'ageNotMatch': 'Age does not match',
  'unfinishedAssessments': 'There are unfinished assessments',
  'scheduleTrainingPlan': 'Schedule a training plan',
  'afterendPlanNotPossible': 'After the end of the plan, it is not possible to adjust the session time, feedback and arrange the assessment',
  'completeAllSessionsEndingPlan': 'Please complete all sessions and have a full evaluation before ending the plan',
  'test': 'Test',
  'untilFinishedStudying': 'Do not begin the test until you have finished studying',
  'whetherStartTraining': 'Whether to start this training plan based on the pre-assessment results?',
  'activateTrainingPlan': 'Are you sure to activate this training plan?',
  'addCalmingCourses': 'Add Calming courses as training introduction (10 sessions) (Please check with your supervisor if it is necessary to add)',
  'preAssessmentResult': 'Pre-assessment result',
  'noPreAssessment': 'No pre-assessment',
  'preAssessmentList': 'Pre-assessment list',
  'pleaseSelectLeast': 'Please select at least one session',
  'sureDeleteIt': 'Are you sure you want to delete it?',
  'childCannotViewed': "The child has {status}, the contents of this module cannot be viewed",
  'failed': 'Failed',
  'whetherMarkFinished': 'Whether to mark as finished',
  'attachments': 'Attachment(s)',
  'learning': 'Learning ',
  'unfinished': 'Unfinished',
  'viewResult': 'View result',
  'xTrainingDetails': "{name}'s training details",
  'confirmedHimSubmission': ' Confirmed for {name} to open {title} validity period: {time}? Cannot be modified after submission',
  'notPermission': "You don't have permission to view child detail",
  'openCourses': 'Open courses',
  'correctRateCorrentRate': '{correntCount} right, correct rate {correntRate}%',
  'trackNumber': 'Track number',
  'trackNumberChant': 'Track Number (Chant)',
  'trackDuration': 'Track duration ',
  'activityName': 'Activity name ',
  'coverImage': 'Cover',
  'recommendedActivitiesList': 'Recommended activities list',
  'trainingMaterials': 'Training materials',
  'actionSummary': 'Action Summary ',
  'trainingTimesPrecautions': 'Training time(s) and precautions',
  'possibleDifficultiesChildren': 'Possible difficulties children may have in completing activities',
  'groupTeachingSuggestions': 'Group teaching suggestions',
  'offlineTrainingPlan': 'Offline training Plan',
  'onlineTrainingPlan': 'Online training plan',
  'sendToGuardian': 'Send to guardian',
  'sureSendReportGuardian': 'Are you sure to send this report to the guardian?',
  'parentsCanInAPP': 'Parents can view the report in the APP',
  'selectPeople': 'Select People',
  'arrangeMethod': 'Arrange method',
  'selfPaymentStudents': 'Center places: people can use the place without paying fees; Self-payment: Students need to pay by themselves',
  'selectedEmployees': 'The number of selected employees has exceeded the number of  available places at the centre',
  'sureArrangementsSuccessful': 'Are you sure about the arrangements? When the arrangement is successful, the module is issued to the user',
  'arrangingTraining': 'You are arranging training module for {name} to use [Center places], please confirm',
  'arrangingTrainingModuleSelfPayment': 'You are arranging a training module for {name} to use [Self-Payment], please confirm?',
  'currentlyAvailable': 'Currently available centre places',
  'selfPaymentUnavailable': 'The self-payment function is unavailable',
  'selfPaymentPrices': 'Self-payment prices',
  'multiSensoryTraining': 'Multi-sensory Focus Training Plan',
  'HallowellFocusTraining': 'Hallowell Focus Training Plan',
  'confirmPersonalInformation': 'Please confirm your personal information',
  'clickEnter': 'Please click to enter',
  'centerSide': 'Center side',
  'FASBasicCompetency': 'Fundamental Abilities Survey Chart',
  'childrenAttention': "Children's attention assessment chart",
  'childStrengthsChallenges': "Child's strengths and challenges",
  'question': 'Question',
  'subjectTrainPlan': "Overview of {userName}‘s {trainPlanName}",
  'detailsComparison': 'Details comparison',
  'printDetails': 'Print',
  'examPaperReviewed': 'The exam paper is being reviewed, please wait a minute',
  'totalScore': 'Total score: ',
  'submissionTime': 'Submission time',
  'retakeExamAdministrator': 'If you need to retake the exam, please contact the training administrator',
  'noTestResult': 'No test result information is available',
  'selectEmployees': 'Select Employees',
  'sendInvitationEmail': 'Send an Invitation Email',
  'switchingAccountRole': 'You are switching the account role to {value}. Each center can only have one administrator, select a new one: ',
  'editRemainingTimes': 'Edit remaining times',
  'remainingVRAT': 'Remaining vCAT',
  'remainingAssessment': 'Remaining assessment',
  'remainingSession': 'Remaining session',
  'multiCoursesRemaining': '1 to multi courses remaining',
  'availablePDFFormat': 'Available in PDF format',
  'studentName': 'Name',
  'playingDurationMins': 'Playing duration',
  'groupOne': 'Group 1',
  'groupTwo': 'Group 2',
  'groupThere': 'Group 3',
  'levelOfDifficulty': 'Level of difficulty',
  'levelOfLikeability': 'Level of likeability',
  'observedPerformance': 'Observed Performance',
  'followInstructions': 'Follow the instructions',
  'attention': 'Attention',
  'hyperactivity_impulsivity': 'Hyperactivity/impulsivity',
  'performanceTraining': 'The overall performance of this training',
  'strengths_advancementsChild': 'Strengths/advancements of child',
  'aspectsImproved': 'Aspects need to be improved',
  'guardianFeedback_questions': "Guardian's feedback/question(s)",
  'HFSTrainingRecord': 'HFS  training Record Sheet',
  'NoteLevelDifficulty': 'Note：level of difficulty',
  'levelCompetency': 'Level of competency',
  'bodyCobordination': 'Body coordination',
  'coreStrength': 'Core strength',
  'leftAndRight': 'Left and right crossover',
  'handEyeCoordination': 'Hand-eye coordination',
  'balance': 'Balance',
  'classSituation': 'Class situation',
  'coordination_core_balance': 'Body coordination & core & left/right cross & Hand eye coordination & balance',
  'indicates': 'Indicates',
  'allIndicate': 'All indicate',
  'printTimetable': 'Print timetable',
  'fillTrainingFeedback': 'Fill in training feedback',
  'certifiedTraining': 'Certified training',
  'HFSCoach': 'HFS coach',
  'apply': 'Apply',
  'parentControlConsole': 'parent control console',
  'coachControlConsole': 'Coach control console',
  'progressNotSelected': 'The progress has not been selected',
  'musicPlaybackRecord': 'Music playback record of this session',
  'failedGetOrder': 'Failed to get order information, please contact your administrator',
  'failedGenerateExam': 'Failed to generate exam papers. Please contact your administrator',
  'onlyTrialsLeft': 'Only {tryCount} trials left',
  'giveFeedbackUnless': 'Do not give feedback unless you start the session first',
  'sureEndName': 'Are you sure to terminate the {trainPlanName} of {name}?',
  'giveFeedbackSession': 'Do not give feedback unless you end the session first',
  'sureScheduleFollowing': 'Are you sure to schedule the following assessment for {name}',
  'sureDataCleared': 'Are you sure? Data will be cleared after deletion',
  'courseDuration': 'Course duration',
  'viewComparison': 'View comparison',
  'trainingDate': 'Training date',
  'extremelyEasyDifficult': 'Extremely easy、Easy、Average、Difficult、Extremely difficult',
  'extremelyDislikeLike': 'Extremely dislike、Dislike、Average、Like、Extremely like',
  'extremelyInattention': 'Extremely inattention、Inattention、Average、Attention、High attention',
  'veryUncooperative': 'Very uncooperative、Uncooperative、Average、Cooperative、Very cooperative',
  'highImpulsive': 'High impulsive、Impulsive、Average、Self-controlled 、High self-controlled ',
  'needImprove': 'Need improve、Better to improve、Average、Good、Very good ',
  'name': 'Name',
  'userId': 'User ID',
  'gender': 'Gender',
  'age': 'Age',
  'grade': 'Grade',
  'birthday': 'DOB',
  'testDate': 'Test Date',
  'testId': 'Test ID',
  'testLocation': 'Manage Locations',
  'testVersion': 'Test Version',
  'sendCode': "Send",
  'send': 'Send',
  'currentPassword': 'Current Password',
  'newPassword': 'New Password',
  'confirmPassword': 'Confirm New Password',
  'reportVersion': 'Report Version',
  'comments': 'Comments',
  'next': 'Next',
  'personalInformation': 'Personal Information',
  'newEmail': 'New Email',
  'verifyPassword': 'Verify Password',
  'none': 'None',
  'passwordRule': 'Password must be 8-12 characters, including both numbers and letters, special characters are allowed.',
  'poweredBy': 'Powered by Cognitive Leap Solutions Inc.',
  'userDetail': 'User Detail',
  'testDetail': 'Test Details',
  'unknown': 'Unknown',
  'male': 'Male',
  'female': 'Female',
  'other': 'Other',
  'loading': 'Loading',
  'clearTwo': 'Clear',
  'clear': 'Clear',
  'total': 'Total',
  'items': 'items',
  'action': 'Action',
  'detail': 'Details',
  'search': 'Search',
  'back': 'BACK',
  'saveAndBack': 'BACK & SAVE',
  'edit': 'Edit',
  'freeze': 'Freeze',
  'activate': 'Activate',
  'download': 'Download',
  'medicalVratView': 'Basic edition',
  'downloadPDF': 'Download PDF',
  'view': 'View',
  'see': 'View',
  'phoneNumError': 'Incorrect phone number format',
  'desktop': 'Desktop',
  'cv': "CV",
  'center': "Center",
  'change': "Change",
  'profilePhoto': 'Profile Photo',
  'loginSecurity': 'Login Security',
  'statisticalOverview': 'Statistics',
  'success': 'Successful!',
  'inputPhoneNum': 'Please input your mobile phone',
  'coachName': 'Search coach name',
  'failure': 'Failed!',
  'changeEmail': 'Edit Email Address',
  'changPhoneNum': 'Edit Phone Number',
  'changePassword': 'Change Password',
  'editProfile': 'Edit Profile',
  'untested': 'Untested',
  'testing': 'Testing',
  'tested': 'Tested',
  'reportReady': 'Report Ready',
  'testStatus': 'TestStatus',
  'dataUploading': 'Data Uploading',
  'testAbnormal': 'Test Abnormal',
  'testFailed': 'Test Failed',
  'reportAcquired': 'Report Acquired',
  'reportGenerating': 'Report Generating',
  'parentalAdvice': 'Parent suggested',
  'parentalAdviceTime': 'Parent suggested time',
  'reportGenerationFailed': 'Report Generation Failed',
  'reportedDate': "Reported date",
  'failed': 'Failed',
  'deleted': 'Deleted',
  'engTest': 'Trial Test',
  'database': "Resource Center",
  'account': "Account",
  'trainManagement': "Manage Trainees",
  'trainApplyReview': "Training Application Review",
  'trainSupervison': "Training Supervision",
  'profileSetting': "Account Settings",
  'trainCenter': "Training Center",
  'realTest': 'Real Test',
  'dataCollect': 'Data Collect',
  'business': 'Business',
  'inActive': 'Inactive',
  'active': 'Active',
  'unReleased': 'Unreleased',
  'released': 'Released',
  'archive': 'Archive',
  'email': 'Email',
  'submit': 'Submit',
  'submitOther': 'Submit',
  'cancel': 'Cancel',
  'role': 'Role',
  'phone': 'Phone',
  'delete': 'Delete',
  'china': 'China',
  'israel': 'Israel',
  'mexico': 'Mexico',
  'unitedState': 'USA',
  'getFailedChildInfo': "Failed to access child's information",
  'scheduleEditingRange': 'Schedule editing range',
  'oneTimeClass': 'One time',
  'followUpClass': 'Follow-up',
  'mother': 'Mother',
  'father': 'Father',
  'grandfather': 'Grandfather',
  'grandmother': 'Grandmother',
  'otherLegalGuardian': 'Other Legal Guardian',
  'countryCode': 'Phone Area Code',
  'previous': 'Previous',
  'accountActive': 'Activated',
  'activeFailMsg': 'Activation link error/ expire, replacement second(s) later will automatically return to login page',
  'accountActived': 'Activated, replacement second(s) later will automatically return to login page',
  'accountExist': 'Activation successful! Your email has been registered in our system and has set up personal information and password before, please log in directly, replacement second(s) later will automatically return to login page',
  'accountActiveFail': 'Unable to activate, replacement second(s) later will automatically return to login page',
  'confirm': 'Confirm',
  'areaCodeMessage': 'Please enter the area code and phone number',
  'passwordMessage': 'Those passwords didn’t match,try again',
  'codeSendSuccess': 'Sent',
  'titleTwo': 'Phone Number & Password Set Up ',
  'save': 'Save',
  'company': 'Company',
  'accountSystem': 'Account System',
  'centerName': 'Center',
  'locationName': 'Location',
  'Apply for Cancellation': 'Apply for Cancellation',
  'reviewTheApplication': 'Review the application for FocusEDTx cancellation',
  'reviewResult': 'The result of your review of the FocusEDTx cancellation for {name} is:',
  'Allow': 'Allow',
  'Reject': 'Reject',
  'Apply for FocusEDTx Cancellation': 'Apply for FocusEDTx Cancellation',
  'ReasonAndNotes': 'Reason and notes',
  'FocusEDTx is Cancelled': 'FocusEDTx is Cancelled.',
  'Cancelled Time': 'Cancelled Time: ',
  'caseId': 'Case Id',
  'subjectName': 'Children Name',
  'dataError': '--',
  'locationAddress': 'Location Address',
  'locationStatus': 'Location Status',
  'centerLogo': 'Center Logo',
  'beforeSchool': 'Before School',
  'notAttendingSchool': 'Not Attending School',
  'statisticsTotal': 'Total',
  'reportingWait': 'Reporting, please wait...',
  'isRequired': ' is required',
  'exportPDF': 'Export PDF',
  'deletedSuccess': 'Deleted',
  'activityFeedback': 'Activity feedback',
  'changeChildMusic': '{name} is currently {oldStatus}, are you sure to switch to {newStatus}?',
  'relationShipRequired': 'Relationship is required',
  'subjectRequired': 'Please select a subject',
  'deleteGuardianConfirm': 'Are you sure to delete this guardian?',
  'smsCode': 'SMS Code',
  'backLowercase': 'Back',
  'y': 'y',
  'm': 'm',
  'testAge': 'Test Age',
  'home': 'Home',
  'photo': 'Photo',
  'checkInStatus': 'Check-in Status',
  'notCheckedIn': 'Not Checked In',
  'checkedIn': 'Checked In',
  'checkIn': 'Check In',
  'checkInList': 'Check-in List',
  'scanCheck': 'Scan Code to Check In',
  'addTime': 'Added Time',
  'authorized': 'Authorized',
  'trainingPlan': 'Training Plan',
  'vartTest': 'vCAT',
  'developTest': 'Assessment',
  'all': 'All',
  'creatTime': 'Creation Time',
  'trainingPlanName': 'Training Plan',
  'status': 'Status',
  'currentSession': 'Current Session',
  'allSession': 'Number of Sessions',
  'preStauts': 'Pre-assessment Status',
  'realStartTime': 'Start Time',
  'endTime': 'End Time',
  'arrangeTrainingPlan': 'Arrange Training Plan',
  'TheAmountAssessmentNotSet': 'The amount of this assessment is not set.',
  'classEnd': 'End session',
  'feedback': 'Feedback',
  'notRealStart': 'Not Start',
  'realStart': 'Start',
  'testPartName': 'Location',
  'testStatus': 'Status',
  'attentionNum': 'Attention Index',
  'actionNumber': 'Motion Index',
  'lookDetail': 'Details',
  'lookReport': 'View Report',
  'addTest': 'New Assessment',
  'testName': 'Assessment Name',
  'assessmentStatus': 'Status',
  'reported': 'Reported',
  'ilsMusic': 'HFS music',
  'thisClassEnd': 'The session has ended',
  'lookMoreActivity': 'View Other Activities',
  'addMoreActivity': 'Add Other Activities',
  'haveChoiceActivity': 'Added Activites',
  'trainBeforeAllCompelete': 'Please complete the pre-assessment(s) before starting the training plan',
  'sureNotDo': "Didn't do this activity?",
  'cannotUpdate': 'Cannot be edited after submission.',
  'progress': 'Progress',
  'ok': 'OK',
  'yes': 'Yes',
  'no': 'No',
  'done': 'Done',
  'notDone': 'Not Done',
  'ongoing': 'Ongoing',
  'notStart': 'Untested',
  'checkInPeopleList': 'vThe subject who will be checked in is',
  'sureCheckIn': 'Confirm',
  'sureConfirm': 'Confirm',
  'checkInSuccess': 'Check in successfully！',
  'checkInFailed': 'Check-in Failed',
  'growthTrack': "'s  Growth Track",
  'thisChildHasProgram': 'The subject already has at least one training plan in progress.',
  'start': 'Start Time',
  'currentTime': 'Current Session',
  'arrangeSuccess': 'Arrange successfully!',
  'isSureThisProPlan': 'Start the training plan?',
  'tips': 'Tips',
  'checkoutThisChild': 'Check out the subject?',
  'noLongerAuthorized': 'Deauthorized',
  'startExerise': 'Start Training Plan',
  'created': 'Created',
  'cbtCompleted': 'CBT Completed',
  'finished': 'Finished',
  'finishedPre': 'Finished',
  'doneFeek': 'Done',
  'sureInfoAgain': "Please confirm the profile of child.",
  'areYouSureTo': 'Are you sure to ',
  'staff': 'staff',
  'trainer': 'Coach',
  'assignTrainers': 'Assign Coach',
  'teamManagement': 'Manage Team',
  'addNewEmployee': 'Add new employee',
  'sendActivationEmail': 'Send activation email',
  'resend': 'Resend',
  'mailSentSuccessfully': 'Email has been sent',
  'failedToSendMail': 'Failed to send mail',
  'trainerDatabase': 'Trainer database',
  'reset': 'Reset',
  'pleaseEnterKeys': 'Keyword',
  'noCourses': 'There is no data yet',
  'sleep': 'Sleep',
  'nutrition': 'Nutrition',
  'exercise': 'Training',
  'chartsAndFeedbackRecords': 'Records',
  'preDetail': 'Pre-assessment Detail',
  'chart': 'Chart',
  'trainerFeedback': 'Coach feedback ',
  'overallFeedback': 'Feedback',
  'parentFeedback': 'Parent feedback',
  'noOpenTraining': 'There is no data yet',
  'viewRecord': 'View the check in record',
  'notOpen': 'Not open',
  'startTime': 'Start time',
  'punchTotalDay': 'Total check in',
  'continueClockDay': 'Continuous check in',
  'isActive': 'Actived',
  'isSend': 'isSend',
  'effective': 'Valid',
  'invalid': 'Invalid',
  'roleName': 'Role',
  'picture': 'picture',
  'switchStatus': 'Switch status',
  'evaluationList': 'Test List',
  'questionnaireEvaluationList': 'Assessment List',
  'childUserAnalysis': 'Children Analysis',
  'parentUserAnalysis': 'Guardian Analysis',
  'VRATAnalysis': 'vCAT Analysis',
  'brainTrainAnalysis': 'Training Plan List',
  'courseAnalysis': 'Course',
  'messageCenter': 'Message Center',
  'school': 'School',
  'bound': 'Authorized',
  'cancelled': 'Logged off',
  'unauthorized': 'Unauthorized',
  'canUseTestCount': 'Available test quantity',
  'consumptionTest': 'Test consumption',
  'testName': 'Test',
  'startDate': 'Start date',
  'endDate': 'End date',
  'sendToParents': 'Send to parents',
  'hasSend': 'Sent',
  'keyIndicators': 'Key Indicators',
  'trendAnalysis': 'Trending Analysis',
  'newChildrenToday': 'New children (daily)',
  'signedChildrenToday': 'Total checkin (today)',
  'newChildrenMonth': 'New children (monthly)',
  'trainChildCount': 'Total training chidren',
  'unboundChildCount': 'Total deauthorized children',
  'anthorChildCount': 'Total authorized children',
  'newChildren': 'Number of new children',
  'unboundChildNumber': 'Total deauthorized children',
  'year': 'Year',
  'month': 'Month',
  'cycle': 'Cycle',
  'pleaseSelectMonth': 'Please select month',
  'pleaseSelectYear': 'Please select year',
  'evaluationQrCode': 'Assessment QR code',
  'know': 'OK',
  'subjectNotFound': 'Child not found',
  'addEvaluationQrCode': 'Add assessment QR code',
  'courseName': 'Course name',
  'keyword': 'Keyword',
  'openingTime': 'Opening time',
  'classHour': 'Number of class hours',
  'exportList': 'Export list',
  'trainingProgramType': 'Training program type',
  'user': 'User',
  'ageDistribution': 'Age distribution',
  'genderDistribution': 'Gender distribution',
  'designation': 'Gender',
  'designationOther': 'School type',
  'number': 'Quantity',
  'proportion': 'Proportion',
  'newParentToday': 'New parents (daily)',
  'newParentMonth': 'New parents (monthly)',
  'anthorParentCount': 'Total authorized parents',
  'newParent': 'Number of new parents',
  'trainingPlanList': 'Training plan list',
  'trainingProgramStatistics': 'Training plan',
  'open': 'Unfold',
  'putItAway': 'Fold',
  'newReportToday': 'New reports (daily)',
  'newReportMonth': 'New reports (monthly)',
  'anthorReportGenerationCount': 'Total reports',
  'reportGeneration': 'Total generated reports',
  'userMessage': 'User messages',
  'systemNotification': 'System notifications',
  'closed': 'Closed',
  'copyTestCode': 'Copy Test Code',
  'testCode': 'Test Code',
  'testFailed': 'Failed',
  'spain': 'Spain',
  'stage': 'Stage',
  'city': 'City',
  'recreate': 'Recreate Case',
  "availableTest": 'Available Test(s)',
  "usedTest": 'Used Test(s)',
  "expiredDate": 'Valid Until:',
  "courseDetail": 'Details',
  "planDetail": 'Plan Details',
  "noYet": 'None',
  "unlimited": 'Unlimited',
  'selectDeletionType': 'Please select the deletion type',
  'thisSchedule': 'This schedule',
  'subsequentSchedules': 'This and subsequent schedules',
  'allSchedules': 'All schedules',
  'createSchedule': 'Create a schedule',
  'submitTheTest': 'Submit the test',
  'unlimitedTime': 'Unlimited',
  'assignVratTest': 'Are you sure to add a vCAT test for {name}?',
  'checkPhoneNumber': 'Verify Phone Number',
  'addOrChiceChild': 'Add users',
  'addAndCode': 'Add users and generate vCAT test code',
  'numberIsEnroll': '{number} has been registered, please go to [Children List] to add a vCAT test.',
  'gotoChildList': '[Children List]',
  "sendCodeFirst": 'Please send the verification code first',
  "pleaseCheckCarefully": 'Please check carefully, this cannot be modified after submission.',
  "locatedCity": 'City',
  "schoolNature": 'School type',
  'addVratError': 'vCAT test is not available at the moment, please contact Cogleap staff',
  "kindergarten": 'Kindergarten',
  "public": 'Public',
  "private": 'Private',
  "international": 'International',
  "sureCenterAddSameChild": 'The center has added children with the same name. Please confirm that they are not the same child',
  "sureCenterAddSameChildContent": "Children's information may have been added by parents and authorized to our center",
  "sameNamePrompt": 'Prompt with the same name!',
  "Confirm Add": 'Confirm Add',
  "overPlan": 'End Plan',
  "return": 'Return to editing',
  "pleaseResureInfo": 'Please reconfirm the information of parents and children',
  "childrenInformation": "Children's information",
  "parentInformation": "Parents' information",
  "contactInformation": "contact information",
  "archives": 'Profile',
  "questionnaireEvaluation": 'Questionnaire',
  "motion": 'Motion',
  'changeDes': 'After the coach switches to a different role, the child under this account needs to reassign a new coach',
  "HFSFees": 'HFS Fee',
  'timetable': 'Timetable',
  "HFSFeeOverview": 'Overview',
  'switchRoles': 'Switch role',
  "OrderList": 'Order History',
  "Balance": "Balance",
  "AccumulativeConsumption": "Total spending",
  "Recharge": 'Recharge',
  "assessment": "Assessment",
  "ConsumptionThisMonth": 'Monthly spending',
  "course": 'Course',
  "LastSubmissionTime": 'Last Submission Time',
  "validity": 'Vaild until',
  "ReminderThreshold": 'Reminder Threshold',
  "AddCreditCharge": 'Add Credit/ Charge',
  "Consumption": "Consumption",
  "DeactivationTime": 'Deactivation Time',
  "UnitPrice": 'Unit Price',
  "TrainingName": 'Training name',
  "NotActive": 'Not Active',
  "Effectiveing": 'Effective',
  "Terminated": 'Terminated',
  "Suspended": 'Suspended',
  "canada": 'Canada',
  "LevelOfDifficultyOneDes": 'Extremely easy. Too easy for children.',
  "LevelOfDifficultyTwoDes": 'Relatively easy.',
  "LevelOfDifficultyThreeDes": 'Moderate difficult. Not so easy for children to complete.',
  "LevelOfDifficultyFourDes": 'Relatively difficult. It is more difficult for children, but it can still be completed.',
  "LevelOfDifficultyFiveDes": 'Very difficult. The child can hardly finish it.',
  "LevelOfLikeabilityOneDes": 'Extremely dislike.',
  "LevelOfLikeabilityTwoDes": 'Dislike.',
  "LevelOfLikeabilityThreeDes": 'Moderate, not disgusting.',
  "LevelOfLikeabilityFourDes": 'Like.',
  "LevelOfLikeabilityFiveDes": 'Extremely like.',
  "AttentionOneDes": 'Extremely inattention, The child is distracted almost all the time.',
  "AttentionTwoDes": 'Inattention, 75% of the time is distracted.',
  "AttentionThreeDes": 'Average concentration, 50% of the time can focus.',
  "AttentionFourDes": 'Focused, 75% of the time can focus.',
  "AttentionFiveDes": 'Extremely focused, The child is almost fully focused.',
  "FollowTheInstructionsOneDes": 'Very uncooperative, The child does not follow instructions almost all the time.',
  "FollowTheInstructionsTwoDes": 'Uncooperative, 75% of the time does not follow instructions.',
  "FollowTheInstructionsThreeDes": 'Moderate cooperation, Follow instructions 50% of the time.',
  "FollowTheInstructionsFourDes": 'Cooperative, Follow instructions 75% of the time.',
  "FollowTheInstructionsFiveDes": 'Very cooperative, The child follow instructions all the time.',
  "HyperactivityOneDes": 'High impulsive, The child is impulsive almost all the time.',
  "HyperactivityTwoDes": 'Impulsive, 75% of the time the child is impulsive.',
  "HyperactivityThreeDes": 'Average Self-controlled, 50% of the time the child has self-control.',
  "HyperactivityFourDes": 'Self-controlled, 75% of the time the child has self-control.',
  "HyperactivityFiveDes": 'High self-controlled, The child has self-control almost all the time.',
  'note': 'Note: ',
  'User Configuration': 'User Configuration',
  "Training": 'Training',
  'Remaining Sessions': 'Remaining Sessions',
  'Used Sessions': 'Used Sessions',
  'Total Sessions': 'Total Sessions',
  "Training Sessions": 'Training Sessions',
  'Add Sessions': 'Add Sessions',
  'Add Training Sessions': 'Add Training Sessions',
  'Select Child': 'Select Child',
  'Enter and search to select': 'Enter and search to select.',
  'Child NameAssessment': 'Child Name/Assessment',
  'Remaining Times': 'Remaining Times',
  'Used Times': 'Used Times',
  'Total Times': 'Total Times',
  'Amount of Assessment': 'Amount of Assessment',
  'Add Times': 'Add Times',
  'Add assessment count': 'Add assessment count',
  'The amount of sessions for this child is not set': 'The amount of sessions for this child is not set! ',
  'The amount of this assessment is not set': 'The amount of this assessment is not set! ',
  'Please contact the admin': 'Please contact the admin.',
  'Settings': 'Settings',
  'The assessments has been used up': 'The assessments has been used up.',
  'The assessments for this child has been used up': 'The assessments for this child has been used up!',
  'The sessions for this child has been used up': 'The sessions for this child has been used up!',
  'The remaining sessions for this child is': 'The remaining sessions for this child is {time}.',
  'Add Sessions Class': 'Add Sessions',
  'Add sessions for': 'Add sessions to {trainPlanName} for {childName}: ',
  'Add sessions for Code': 'Add hours for current {code} music module',
  'Added hours limit': '{minNum}≤Added hours≤{maxNum}',
  'FAS assessment result': 'Add hours based on latest FAS assessment result: {musicText} hrs',
  'The music for the next session': 'The music for the next session will be replaced by the new music module. Once submitted, it cannot be withdrawn. Please operate with caution!',
  'The actual amount of additional': 'Note: The actual amount of additional sessions depends on the duration of HFS music for each session.',
  'Are you sure to add sessions': 'Are you sure to add sessions?',
  'Please operate with caution': 'Once submitted, it cannot be withdrawn. Please operate with caution!',
  'There is already an active one for this project': 'There is already an active one for this project, please end it before starting a new one.',
  'Note that the replaced': 'Note that the replaced music module {courseCode} is the same as the current one.',
  'Please input numbers': 'Please input numbers.',
  'Hours': 'Hours',
  'serverError': 'Internal server error',
  'taiwan': 'Taiwan',
  'deleteTrainDes': 'Are you sure to delete this training plan?',
  'musicPause': 'Music paused.',
  'clickContinue': 'Click to continue playing.',
  'musicBuffering': 'Network may be unstable, Music buffering {audioLoadProgress}%.',
  'gotIt': 'Got it.',
  'musicRequestFailed': 'Request failed. Check network or refresh.',
  'Refresh': 'Refresh',
  'codeSendSucess': 'Code Sent Successfully!',
  'Contact Us': 'Contact Us',
  'Title': 'Title',
  'New Message': 'New Message',
  'suggestions and questions': 'If you have suggestions and questions about the use of the system, please contact us. We will reply you as soon as possible.',
  'Sent time': 'Sent time',
  'Failed Please try again later': 'Failed. Please try again later.',
  'Detailed Description Allow image pasting': 'Detailed Description. Allow image paste.',
  'themeSubject': 'Subject',
  'sent': 'Sent',
  'sureSendMes': 'Are you sure to send the message?',
  'sure': 'Sure',
  'Hong Kong': 'Hong Kong',
  'serverError': 'Internal server error',
  'classAnalysis': 'Class Analysis',
  'classNum': 'Total Classes',
  'monthlyUsedClass': 'Used session(s) (monthly)',
  'DeviceManagement': 'Device Management',
  'EnterDeviceSN': 'Enter a device S/N',
  'EnterCenterName': "Enter a center's name",
  'DeviceSN': 'Device S/N',
  'DeviceName': 'Device Name',
  'PendingRegistration': 'Pending Registration',
  'UnderReview': 'Under Review',
  'Registered': 'Registered',
  'TimeOfRegistration': 'Time of Registration',
  'Clinician Edition': 'Clinician Edition',
  'Educator Edition': 'Educator Edition',
  'Parent Edition': 'Parent Edition',
  'Generate the ID code': 'Generate the ID code',
  'Refresh the ID code': 'Refresh the ID code',
  'Schedule Reminder': 'Schedule Reminder',
  'Guardians Punch Reminder': 'Guardians Punch Reminder',
  'Coach Configuration': 'Coach Configuration',
  'Coach Name': 'Coach Name',
  'Customize': 'Customize',
  'Custom Amount': 'Custom Amount',
  'Configure training session': 'It is used to configure training session hours for independent coaches. Newly added independent coaches will be synced to this list after activation.',
  'Personal Configuration': 'Personal Configuration',
  'Here is the configuration': 'Here is the configuration and usage of the center for your training session hours.',
  'watched too many': 'You have watched this video too many times and cannot watch any more.',
  'ClassHFSMusic': 'Class HFS music',
  'planAlreadyInProgress': 'This plan already has one or several in progress, no new ones could be opened.',
  'sessionsUsedUp': 'The sessions for this child has been used up! Please contact the admin to set up.',
  'sessionsAmount': 'The remaining sessions for this child is {time}. Please contact the admin to configure in time.',
  'Dont enter Zero': "Please don't enter 0.",
  'musicCountMoreThree': 'You have listened to the music for this session many times. Please start the next session.',
  'sureEndSession': 'Are you sure to end the session?',
  'sureEnd': 'End',
  'VratFeeOverView': 'VRAT Fee Overview',
  'VratOrderHistory': 'VRAT Order History',
  'VratRecharge': 'Recharge(¥)',
  'VratSpending': 'Spending(¥)',
  'FreeTime': 'Free tests amount',
  'FreeTimeAmount': 'Remaining free tests',
  'VratUnitPrice': 'Unit price (¥)',
  'VratRecharged': 'Recharge',
  'TestsRemaining': 'About {time} tests remaining',
  'VaildUntil': 'Vaild until',
  'orderNumberName': 'Order number/Name',
  'Deduction successful': 'Deduction successful',
  'Deduction failed': 'Deduction failed',
  'To be deducted': 'To be deducted',
  'Deducting': 'Deducting',
  'NegativeBalanceDisabled': 'Negative balance or service disabled. Please contact our staff in time.',
  'Get Report failed': 'Get report failed',
  'Deactivated frozen': 'Deactivated frozen',
  "ConnectedPlayFormalMusic": 'Connected. Play formal music.',
  "NotIntoFormalMusic": 'Not into formal music.',
  'Date of issue': 'Date of issue',
  'willLogout': ', and you will be logged out soon.....',
  "Core session Library": 'Core Session Library',
  "Week Order": 'Week Order',
  "Week Topic": 'Week Topic',
  "Day Order": 'Day Order',
  "Session Title": 'Session Title ',
  "Type": 'Type',
  "Action": 'Action',
  "Day": 'Day{day}',
  "Video": 'Video',
  "Activity": 'Activity',
  "Add Thoughts": 'Add Thoughts',
  "Load More": 'Load More',
  "Loaded all": 'Loaded all.',
  "core-Submit": 'Submit',
  "Submitted": 'Submitted!',
  "Show More": 'Show More',
  "Show Less": 'Show Less',
  "No data yet": 'No data yet.',
  "core-Details": 'Details',
  "Thoughts": 'Thoughts',
  "your Thoughts": 'Your Thoughts',
  "core-weekDay": 'Thoughts for Week {week} Day {day}',
  "core-week": 'Week{week}',
  "add Thought": 'Add your thoughts',
  "require thought": 'Your Thoughts is required',
  'telMessage': 'The format of the phone number is wrong. Please fill in again.',
  'startPlan': 'Reopen',
  'successStart': 'Reopened!',
  'Guardian Name': 'Guardian Name',
  'backTop': 'Back to top',
  'Operation Management': 'Operation Management ',
  'Manage Centers': 'Manage Centers',
  'Add Center': 'Add Center',
  'Please enter center/enterprise': 'Please enter center/enterprise',
  'Clear': 'Clear',
  'Display Name': 'Display Name',
  'Enterprise': 'Enterprise',
  'vCAT Status': 'vCAT Status',
  'Rocket Status': 'Rocket Status',
  'PDT Status': 'PDT Status',
  'vCAT Method': 'vCAT Method',
  'HFS Method': 'HFS Method',
  'Month': 'Month',
  'Contract recharge' : 'Contract recharge',
  'Is Active' : 'Is Active?',
  'Is Send': 'Is Send?',
  'Edit Center': 'Edit Center',
  'Authorize': 'Authorize',
  'Change Email': 'Change Email',
  'Send An Invitation Email': 'Send An Invitation Email',
  'Name': 'Name',
  'Logo': 'Logo',
  'Used for app': 'Used for app scanning. 200x200px.',
  'brand promotion': 'For brand promotion. It mainly presented in reports. Please communicate with the designer and confirm.',
  'Address': 'Address',
  'Admin Email': 'Admin Email',
  'Admin Phone': 'Admin Phone',
  'Institution Type': 'Institution Type',
  'Education': 'Education',
  'Education_US': 'Education_US',
  'Select the US version': 'Select the US version, and the basic version Chinese VRAT report will be displayed.',
  'vCAT Report Type': 'vCAT Report Type',
  'Please select': 'Please select the type(s) of vCAT report to be used by this center.',
  'vCAT QR code': 'vCAT QR code',
  'vCAT report': 'Choose whether to display the product website QR code at the end of the new version of vCAT report.',
  'Project Status': 'Project Status',
  'Perform on/off operations': 'Perform on/off operations on vCAT and FocusEDTx services. Vrat=vCAT，PDT=FocusEDTx',
  'Is Show Cl Logo': 'Is Show Cl Logo?',
  'Is Show Center Logo': 'Is Show Center Logo?',
  'Whether the CL Logo': 'Whether the CL Logo is displayed in the FAS report, VRAT basic version / standard version report',
  'Whether the Center Logo': 'Whether the Center Logo is displayed in the FAS report, VRAT basic version / standard version report',
  'Verification Code': 'Verification Code',
  'TheraLeap': 'TheraLeap',
  'vCAT_Assessment': 'vCAT_Assessment',
  'vCAT_Amount': 'vCAT_Amount',
  'Log Off': 'Log Off',
  'Reject': 'Reject',
  'Export': 'Export',
  'Device Information': 'Device Information',
  'Device Registration Record': 'Device Registration Record',
  'Device Usage Records': 'Device Usage Records',
  'Operation Record': 'Operation Record',
  'Application approved': 'Application approved.',
  'Apply to register a device': 'Apply to register a device.',
  'Revoked on the web': 'Revoked on the web.',
  'Cancel the application on the client side': 'Cancel the application on the client side',
  'Operation Source' : 'Operation Source',
  'Operation Time': 'Operation Time',
  'Device Management List': 'Device Management List',
  'Test List': 'Test List',
  'Date created': 'Date created',
  'Case IP': 'Case IP',
  'IP Location': 'IP Location',
  'IP Address': 'IP Address',
  'Date created times': 'Date created',
  'Available Test(s)': 'Available Test(s)',
  'Valid Until': 'Valid Until',
  'Statistics': 'Statistics',
  'Analysis': 'Analysis',
  'State': 'State',
  'chargingMethod': 'chargingMethod',

  'DelDisplayName': 'Are you sure to delete {displayName} ? Once deleted it cannot be restored!',
  'Consultation methods': 'Consultation',
  'Can fill in address, etc': 'Please fill in address, phone or other information, which will be displayed in assessment reports.',
  'Database is a database': 'Vrat=vCAT, Rocket=HFS, PDT=FocusEDTx',
  'timesSetting': 'Times Setting',
  'Frequency limit': 'Limit the number of assessments and training sessions for children.',
  'Training courses': 'Email for 8 training sessions',
  'Training hours': 'When a child`s training sessions = 8, a reminder email will be sent to the center admin.',
  'Class class': 'Email for 8 class sessions',
  'Accumulated class hours': 'When a child`s class sessions = 8, a reminder email will be sent to the center admin.',
  'Counting and settlement': 'The contract recharge is automatically deducted every month, and manual settlement is required every month.',
  'Limiters': 'They must be letters with no spaces or other symbols.',
  'valid email address': 'Please enter a valid email address',
  'Cover Image': 'Cover Image',
  'UserList': 'UserList',
  'Number of trained children': 'Number of trained children',
  'Number of trained children (total)': 'Number of trained children (total)',
  'intensive classes': 'Number of children trained (30 days of intensive classes)',
  'Period': 'Cycle:',
  'searchSchool': 'Search School',
  'Freeze Users': 'Freeze',
  'birthDay': 'Birthday',
  'nickName': 'Nickname',
  'appVersion': 'App Version',
  'emailConfirmed': 'Email Confirmed',
  'vipStatus': 'VIP Status',
  'upgrade VIP': 'Upgrade to VIP',
  'confirm upgrade VIP': 'Are you sure to make this user a VIP?',
  'phoneConfirmed': 'phone Confirmed',
  'current state': 'Status',
  'Device IP': 'Device IP',
  'IP Home': 'IP Location',
  'Return': 'Return',
  'Answer results': '{correntCount} correct questions. Correct rate: {correntRate}%',
  'Edit Authorization': 'Edit',
  'Test quantity': 'Test Amount',
  'Billing method': 'Billing Method',
  'available': 'Available or not',
  'School Name': 'School Name',
  'Choose school': 'Select School',
  'new contract': 'Confirm creating new contract information?',
  'modify contract': 'Confirm modifying contract information?',
  'Deduction': 'Deduct',
  'Add by quantity': 'Add by Quantity',
  'unlimited quantity': 'No Quantity Limit on Scheduled',
  'Edit Notes': 'Edit Note',
  'Disable': 'Disable',
  'New test volume': 'Create New Test Quantity',
  'Current validity period': 'Current Valid Until:',
  'Reminder value': 'Reminder Amount:',
  'reminder values': 'Modify Reminder Value',
  'childDetail': 'Child Details',
  'creationTime': 'creationTime',
  'assessmentName': 'assessmentName',
  'Add New Assessment': 'Add New Assessment',
  'agent': 'agent',
  'Create vCAT test': 'Create vCAT test',
  'attentionIndex': 'attentionIndex',
  'motionIndex': 'motionIndex',
  'less than': 'Please enter a positive integer not less than 1',
  'Elapsed class hours': 'Elapsed class hours',
  'MissingPatientID': '[MissingPatientID]',
  'MissingName': '[MissingName]',
  'telMessage': 'The format of the phone number is wrong. Please fill in again.',
  'backTop': 'BackTop',
  'sendReport': 'SendReport',
  'selectLanguage' : 'Switch language >',
  'downLoadReport': 'DownLoadReport',
  'sure': 'Sure',
  'getAssessmentQrCode': 'Get the assessment QR code',
  'assessmentExpiryDate':	'Assessment is valid until: ',
  'Acquire Report': 'Acquire Report',
}
