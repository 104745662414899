export default {
  'report.basicInfo.title': 'vCAT虚拟教室注意力测评报告',
  'report.overview.title': '总体表现',
  'report.overview.api': '注意力指数',
  'report.overview.api.desc1': '注意力指数分反映了儿童相比于他/她的年龄/性别段，整体注意力的表现。根据13分钟的持续注意力测试（CPT）相关数据计算而得。',
  'report.overview.api.desc2': '分数越高，表示测试过程中，越能集中注意力。',
  'report.overview.api.desc3_1': '注意力指数为',
  'report.overview.api.desc3_2': '，表示儿童在注意力的表现超过了',
  'report.overview.api.desc3_3': '的同年龄段/同性别人群。',
  'report.overview.mpi': '动作指数',
  'report.overview.mpi.desc1': '动作指数反映了儿童相比于他/她的年龄/性别段，多动的程度。根据头部运动数据计算而得。',
  'report.overview.mpi.desc2': '分数越高，表示在测试过程中，越少出现动作，越能保持静止状态。',
  'report.overview.mpi.desc3_1': '动作指数为',
  'report.overview.mpi.desc3_2': '，表示儿童的动作稳定程度超过了',
  'report.overview.mpi.desc3_3': '的同年龄段/同性别人群。',
  'report.realTime.title': '实时表现',
  'report.realTime.chart.legend': '测试过程中的头部运动幅度',
  'report.realTime.chart.xAxis': '分钟',
  'report.realTime.chart.yAxisl': '反应时间（毫秒）',
  'report.realTime.chart.yAxisr': '运动幅度',
  'report.realTime.chart.title': '注意力和头部运动的实时表现（13分钟）',
  'report.realTime.desc1': '正确次数：',
  'report.realTime.desc2': '漏击次数（未集中注意力）：',
  'report.realTime.desc3': '错击次数（冲动，在不需要应答时扣动手柄 ）：',
  'report.attentionIndex.title': '注意力指数',
  'report.attentionIndex.chart.title': '与注意力相关的脑区分析',
  'report.attentionSkill.title': '注意力维度',
  'report.attentionSkill.chart.legendW': '警戒线',
  'report.attentionSkill.chart.legendU': '你',
  'report.attentionSkill.chart.title': '注意力水平的六大维度',
  'report.attentionSkill.chart.attention': '目标专注力',
  'report.attentionSkill.chart.self': '行为自控力',
  'report.attentionSkill.chart.reactivity': '反应力',
  'report.attentionSkill.chart.reactSta': '反应稳定性',
  'report.attentionSkill.chart.sensitivity': '分辨力',
  'report.attentionSkill.chart.continuous': '持续注意力',
  'report.attentionSkill.attention': '测试中对目标的专注程度。分数越高，在13分钟测试中的正确应答次数越多。',
  'report.attentionSkill.self': '测试中注意力的自我控制能力水平。分数越高，说明能更好地抑制自己的冲动，减少不应该的按键应答。',
  'report.attentionSkill.reactivity': '测试中作出正确应答的平均反应速度。分数越高，反应越快。',
  'report.attentionSkill.reactSta': '测试中正确应答的反应时间的稳定程度。分数越高，反应时间波动越小。',
  'report.attentionSkill.sensitivity': '从所有字母刺激中辨别出目标字母的能力。分数越高，在干扰字母刺激中区分出目标的能力越强。',
  'report.attentionSkill.continuous': '测试过程中注意力的专注持久度。分数越高，表示注意力在较长时间里仍能维持较高水平。',
  'report.continuous.title': '持续注意力',
  'report.continuous.chart.title': '注意力表现随时间的变化',
  'report.continuous.chart.xAxis': '区间',
  'report.continuous.chart.yAxis': '次数',
  'report.continuous.chart.legendCA': '正确击中',
  'report.continuous.chart.legendOE': '漏击',
  'report.continuous.chart.legendCE': '错击',
  'report.continuous.notes': '备注:',
  'report.continuous.desc1': '每个区间的时间为3.25分钟, 4个区间 x 3.25分钟 = 13分钟测试',
  'report.continuous.desc2': '正确击中：在该作应答的时候扣动了手柄',
  'report.continuous.desc3': '漏击：在该作应答的时候没有扣动手柄',
  'report.continuous.desc4': '错击：在不该作应答的时候扣动了手柄',
  'report.motionIndex.title': '动作指数',
  'report.motionIndex.chart.title': '运动和情绪管理的相关脑区',
  'report.motionIndex.left.p1.title': '基底神经节：',
  'report.motionIndex.left.p1.desc1': '负责运动调节功能，与动作，行动计划，动机行为，奖励处理等相关。',
  'report.motionIndex.left.p1.desc2': '如果基底神经节功能障碍，会遇到：',
  'report.motionIndex.left.p1.desc3': 'a）无法正确计算风险回报',
  'report.motionIndex.left.p1.desc4': 'b）找不到采取行动的内部动机',
  'report.motionIndex.left.p2.title': '丘脑：',
  'report.motionIndex.left.p2.desc1': '控制反应抑制。 如果丘脑出现了问题，会出现冲动抑制障碍。',
  'report.motionIndex.left.p3.title': '杏仁核：',
  'report.motionIndex.left.p3.desc1': '与情绪的产生，识别和调节相关。',
  'report.motionIndex.left.p3.desc2': '如果杏仁核功能障碍，可能会出现：',
  'report.motionIndex.left.p3.desc3': 'a）无法识别外界刺激并产生相应的情绪反应',
  'report.motionIndex.left.p3.desc4': 'b）不能调节情绪',
  'report.motionIndex.right.desc1': 'vCAT系统会测量儿童在测试期间的动作变化。由于注意力不足可能与多动/冲动行为一起出现，儿童的运动数据有助于我们了解儿童移动身体的幅度，次数，是否忽略了任务等。',
  'report.motionIndex.right.desc2': '我们收集了头部和手部两个位置的运动数据，并全面计算儿童的动作指数。',
  'report.movementPath.title': '路径图',
  'report.movementPath.chart.title': '不同身体部位的运动轨迹随时间的变化',
  'report.movementPath.chart.des': '图中绿色框的边长，与虚拟教室中课桌的长度一致。',
  'report.movementPath.chart.head': '头',
  'report.movementPath.chart.hand': '手',
  'report.movementPath.chart.xAxis': '区间',
  'report.movementPath.chart.yAxis': '运动轨迹',
  'report.pathLength.title': '路径长度',
  'report.pathLength.head': '头部',
  'report.pathLength.hand': '手部',
  'report.pathLength.chart.subtitle': '运动路径长度随时间的变化',
  'report.pathLength.chart.xAxis': '区间',
  'report.pathLength.chart.yAxis': '路径长度',
  'report.pathLength.chart.legendU': '你',
  'report.pathLength.chart.legendM': '中位数',
  'report.pathLength.percentile': '百分比 ： ',
  'report.directionTracking.title': '注视方向追踪',
  'report.directionTracking.chart.title': '你在看哪里？',
  'report.directionTracking.p1.desc1': '注视白板方向的总时长为 ',
  'report.directionTracking.p1.desc2': ' 分钟，比 ',
  'report.directionTracking.p1.desc3': ' 的同龄人更集中在白板上。',
  'report.directionTracking.p2': '此图显示了在测试过程中，你在虚拟教室中的注视方向及位置。',
  'report.directionTracking.p3': '图中蓝色的线越长，表示你在相应的方向注视得越久。',
  'report.directionTracking.p4': '图中蓝色区域越宽，表示在测试过程中，你的观察范围越远离中间白板的位置。',
  'report.motionCharacter.title': '动作特点',
  'report.motionCharacter.hyperactivity': '多动指数',
  'report.motionCharacter.motionArea': '动作范围指数',
  'report.motionCharacter.chart.title': '不同运动指数与同龄人的对比情况',
  'report.motionCharacter.you': ' ',
  'report.motionCharacter.hyperactivity.desc': '在测试过程你多动的程度。若可长时间保持不动，则百分比接近0%，若会持续动不停，则百分比接近100%。',
  'report.motionCharacter.motionArea.desc': '测试过程中头部运动的范围大小。在测试过程若动作的范围集中，则百分比接近0%，若运动的范围较大，则百分比接近100%。',
  'report.offlinePlanInfo.sure.title': '确定给该儿童安排训练计划?',
  'report.offlinePlanInfo.cancel.title': '确定结束该次课程吗？',

}
