import { RAF } from './utils/raf.js';

const { rafSetInterval, rafClearInterval } = RAF;

// 用于记录时间戳的变量，时间戳是响应头中的etag和last-modified字段其中之一
let previousTimeTag: string | null;

(async function () {
  // 通过立即执行函数，记录首次请求的时间戳，以便与后面轮询得出的时间戳进行对比
  // 本地不轮询
  if (!window.location.host.includes('localhost')) {
    previousTimeTag = await getTimeTag();
    window.versionMonitor && rafClearInterval();
    // 开启轮询执行judge函数
    window.versionMonitor = rafSetInterval(() => {
      judge();
    }, 40 * 1000);
  }
})();

async function getTimeTag() {
  const response = await fetch(
    `${window.location.protocol}//${window.location.host}`,
    {
      method: 'HEAD',
      cache: 'no-cache',
    },
  );
  // 以响应体的etag或者last-modified为时间戳
  return response.headers.get('etag') || response.headers.get('last-modified');
}

async function judge() {
  // 获取当前最新的时间戳
  const currentTimeTag = await getTimeTag();

  // 检测到最新请求的时间戳和上一次不一致，即文件发生变化
  if (previousTimeTag !== currentTimeTag) {
    // 这里编写更新逻辑
    localStorage.setItem('CurrentVersion', 'old');
    window.versionMonitor && rafClearInterval();
  }
}

export const dva = {
  config: {
    onError(e: Error) {
      console.log(e.message)
    },
  },
};