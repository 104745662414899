export default {
  centerName: 'Center',
  locationCount: 'Location Count',
  firstTestTime: 'First Test Date',
  lastTestTime: 'Last Test Date',
  subjectCount: 'Number of children',
  realTestCount: 'Real Test Count',
  reportReadyCount: 'Reports Ready',
  reportReadyCount5_13: 'Report Ready (5-13)',
  trailTestCount: 'Trial Test Count',
  'cms.statistics.searchForCenterName': 'Search for center',
  'cms.statistics.title.adminStatistics': 'Admin Statistics',
  'testList.title': 'Test List',
  locationName: 'Location',
  'cms.testList.caseID': 'Case ID',
  status: 'Status',
  'cms.testList.testDate': 'Test Date',
  'cms.testList.testType': 'Test Type',
  'cms.statistics.centerDetail.title': 'Center Detail',
  'cms.testCenterList.title': 'Test Center List',
  createdTime: 'Date created',
  'cms.testCenterList.centerType': 'Center Type',
  newCenter: 'New Center',
  'productList.title': 'Product List',
  productName: 'Product',
  version: 'Version',
  releaseDate: 'Release Date',
  changeLog: 'Change Log',
  editChangeLog: 'Edit Change Log',
  viewChangeLog: 'View',
  invalidUrl: 'Invalid url',
  testInformation: 'Test Information',
  testStatus: 'Status',
  testType: 'Test Type',
  consentForm: 'Consent Form',
  guardianInformation: 'Guardian Information',
  subjectInfo: 'Subject Info',
  snapShort: 'Snap Short',
  testSummary: 'Test Summary',
  doctorComment: 'Doctor Comment',
  trailTest: 'Trial Test',
  sendReport: 'Send Report',
  dataValidation: 'Data Validation',
  consentFormChecked: 'Consent Form Checked',
  noGuardian: 'No Guardian',
  finishedProgress: 'Finished Progress',
  updated: 'Updated',
  notEditedYet: 'Not Edited Yet',
  add: 'Add',
  addComments: 'Add Test Summary',
  commentTemplate: 'Template',
  edited: 'Edited',
  noReport: 'No report',
  'center.statistics.title': 'Statistics',
  newTest: 'New Test',
  subject: 'Child',
  report: 'Report',
  chooseSubject: 'Choose a Subject',
  searchSubject: "Search child's name",
  searchSubjectAndSchool: "Search child's name/school",
  subjectInformation: 'Subject Information',
  pleaseChooseSubject: 'Please choose a subject',
  chooseLocation: 'Choose a Location',
  chooseSubjectGrade: 'Choose Subject Grade',
  subjectList: 'Children List',
  guardian: 'Guardian',
  newSubject: 'New child',
  editSubject: 'Edit Subject',
  editUser: 'Edit User',
  childInformation: 'Child Information',
  name: 'Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  dominantHand: 'Dominant hand',
  addGuardian: 'Add Guardian',
  addSubject: 'Add Child',
  addUser: 'Add User',
  left: 'Left',
  right: 'Right',
  searchGuardian: 'Search guardian name/cell-phone number',
  newGuardian: 'New Guardian',
  editGuardian: 'Edit Guardian',
  guardianEmail: 'Guardian Email',
  guardianPhone: 'Guardian Phone Number',
  relationship: 'Relationship',
  typeInEmail: 'Please Type in Email!',
  typeInPhone: 'Please input your phone number!',
  addLocation: 'Add Location',
  editLocation: 'Edit Location',
  locationManage: 'Manage Locations',
  updateSuccessfully: 'Update Successful!',
  error: 'error',
  sendReportSuccessfully: 'Send report successfully!',
  'menu.testManagement': 'Test Management',
  'menu.centerManagement': 'Center Management',
  'menu.Statistics': 'Statistics',
  'menu.Training': 'Training Management',
  'menu.Class': 'Class List',
  'menu.TrainingList': 'Training Plan List',
  'menu.testList': 'Test List',
  'menu.subjectList': 'Children List',
  'menu.productList': 'Product List',
  'menu.locationManagement': 'Location Management',
  'menu.productManagement': 'Product Management',
  'menu.questionnaireManagement': 'Questionnaire Management',
  'menu.userManagement': 'User Management',
  'menu.overview': "Overview",
  'menu.guardianList': 'Guardian List',
  'menu.VRATStatistics': 'vCAT Statistics',
  'menu.VRATTestList': 'vCAT List',
  'menu.operationsManagement': 'Operation Management',
  'menu.content': 'Content',
  'menu.offlineTrainingPlan': 'Offline Plan',
  'cms.editTestCenter': 'Edit Test Center',
  'cms.newTestCenter': 'New Test Center',
  testCenterInformation: 'Test center information',
  creationTime: 'Creation Time',
  password: 'Password',
  initialPassword: 'Initial Password',
  confirmPassword: 'Confirm Password',
  setPassword: 'Please set password',
  displayName: 'Display Name',
  picture: 'Picture',
  upload: 'Upload',
  locationInformation: 'Location Information',
  location: 'Location',
  newLocation: 'Add location',
  questionnaireList: 'Assessment List',
  newQuestionnaire: 'New Questionnaire',
  editQuestionnaire: 'Edit Questionnaire',
  language: 'Language',
  type: 'Type',
  english: 'English',
  chinese: 'Chinese',
  doctor: 'Doctor',
  jsonString: 'JsonString',
  noContent: 'No Content',
  notTestYet: 'Not Test Yet',
  consentFormCheckedMsg: 'Did the guardian sign the CONSENT FORM for CURRENT test?',
  areYouSureToChoose: 'Are you sure to choose',
  chooseCenterType: 'center type',
  canNotChangeLater: 'You may not be able to change the type later',
  attentionStatistics: 'Attention Index(Green|Yellow|Red)',
  motionStatistics: 'Motion Index(Green|Yellow|Red)',
  correctEmailAddress: 'Please enter the correct email address!',
  downloadAllStatistic: 'Download Statistics',
  downloadCenterStatistic: 'Download Center Statistic',
  editAllowQuestionnaire: 'Edit Questionnaire',
  questionnairePermission: 'Questionnaire Permisstion',
  nameExtra: "English word or '_' is allowed",
  QRCode: 'QR Code',
  showQRCode: 'Show QR Code',
  copyToClipboard: 'Copy To Clipboard',
  modalWillBeDestroyed: 'This modal will be destroyed after',
  second: 'second',
  testQRCode: 'Test QR Code',
  attentionIndex: 'Attention Index',
  motionIndex: 'Motion Index',
  'guardian.guardianList': 'Guardian List',
  'guardian.areYouSureToDeleteSubject': 'Are you sure to delete?',
  'guardian.canBeModified': 'The information can be modified by editing.',
  'guardian.checkMobileNumber': "Check Guardian's Mobile Number",
  'guardian.information': "Guardian Information",
  'guardian.nameLimit': 'Matches any string between 2 and 30 characters in length.',
  'guardian.atLeast1': 'Please add at least 1 child.',
  'guardian.sendCode': 'Send',
  'guardian.sendCodeTips': "Creating a Rocket baby APP account for the guardian, and he could easily view the children's reports.",
  'subject.newVRATTest': 'New vCAT Test',
  pleaseEnter: 'Please enter',
  pleaseSelectGender: 'Please select a gender',
  pleaseEnterSMSCode: 'Please enter SMS code',
  resendAfterSeconds: 's Resend',
  enterSMSCode: 'SMS Code',
  downloadQRCode: 'Center Code',
  downloadAppQRCode: 'App Download Code',
  subjectDetail: 'Subject Detail',
  subjectInfo: 'Subject Info',
  dominantHand: 'Dominant hand',
  lastModificationTime: 'Last modification time',
  rocketAssessment: 'Rocket assessment',
  vratTest: 'vCAT test',
  newAssessment: 'New assessment',
  securityCodeTips1: 'If ',
  securityCodeTips2: "you haven't received your verification code",
  securityCodeTips3: ', it may be due to:',
  securityCodeTips4: '• In [Blocked / Junk SMS];',
  securityCodeTips5: '• The phone number entered is not yours;',
  securityCodeTips6: '• Signal error, try again after 60s;',
  submitTipAddUser: 'After submitting, you can edit the parent information on the parent details page.',
  submitTip: 'Please fill in the correct information. If need to modify after submission, please go to Rocket Baby APP.',
  subTip: 'Please note that the date of birth and gender cannot be modified after submission!',
  subTipInfo:' Other information can be modified by users on the Rocket Baby app',
  risk: 'I understand the risks',
  addSubjectSuccess: 'Successful',
  platform: 'Platform',
  editVersionInfo: 'Edit Version Info',
  addNewVersion: 'Add New Version',
  termsOfService: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  region: 'Region',
  downloadLink: 'Download Link',
  rocketReleaseList: 'Rocket Release List',
  available: 'Available',
  versionFormat: 'The version number should conform to the format a.b.c',
  qRcodeErrorAndTry: 'Wrong QR code, please check and try again.',

}
