export default {
  centerName: '中心名称',
  locationCount: '测试地点计数',
  firstTestTime: '首次测试时间',
  lastTestTime: '最后测试时间',
  subjectCount: '儿童总数',
  realTestCount: '真实测试计数',
  reportReadyCount: '报告获取计数',
  reportReadyCountTime: '报告获取时间',
  reportReadyCount5_13: '报告生成计数（5-13）',
  trailTestCount: '内部测试计数',
  'cms.statistics.searchForCenterName': '搜索中心名称',
  'cms.statistics.title.adminStatistics': 'CL管理端统计',
  'testList.title': 'vCAT测试列表',
  locationName: '测试地点名称',
  'cms.testList.caseID': '测试ID',
  status: '状态',
  'cms.testList.testDate': '测试日期',
  'cms.testList.testType': '测试类型',
  'cms.statistics.centerDetail.title': '中心详情页',
  'cms.testCenterList.title': '测试中心列表',
  createdTime: '创建时间',
  'cms.testCenterList.centerType': '中心类型',
  newCenter: '新建中心',
  'productList.title': '产品列表',
  productName: '产品名称',
  version: '版本',
  releaseDate: '发布日期',
  changeLog: '变更记录',
  editChangeLog: '编辑变更记录',
  viewChangeLog: '查看变更记录',
  invalidUrl: '无效Url地址',
  testInformation: '测试信息',
  testStatus: '测试状态',
  testType: '测试类型',
  consentForm: '知情同意书',
  guardianInformation: '家长信息',
  subjectInfo: '儿童信息',
  snapShort: '问卷',
  testSummary: '测试概要',
  doctorComment: '医生意见',
  trailTest: '内部测试',
  sendReport: '发送报告',
  dataValidation: '数据校验',
  consentFormChecked: '是否已确认',
  noGuardian: '无监护人',
  finishedProgress: '已完成进度',
  updated: '更新',
  notEditedYet: '尚未编辑',
  add: '添加',
  addComments: '添加意见',
  commentTemplate: '意见模板',
  edited: '已编辑',
  noReport: '无',
  'center.statistics.title': 'vCAT数据统计',
  newTest: '新建测试',
  subject: '儿童',
  report: '报告',
  chooseSubject: '选择儿童',
  searchSubject: '搜索儿童姓名',
  searchSubjectAndSchool: '搜索儿童姓名/学校',
  subjectInformation: '儿童信息',
  pleaseChooseSubject: '请先选择儿童',
  chooseLocation: '选择测试地点',
  chooseSubjectGrade: '选择儿童年级',
  subjectList: '儿童列表',
  guardian: '家长',
  newSubject: '新建儿童',
  editSubject: '编辑儿童',
  childInformation: '儿童信息',
  name: '姓名',
  firstName: '名字',
  lastName: '姓',
  dominantHand: '惯用手',
  addGuardian: '添加家长',
  addSubject: '添加儿童',
  addUser: '添加员工',
  left: '左',
  right: '右',
  searchGuardian: '搜索家长姓名/手机号',
  newGuardian: '新建家长',
  editGuardian: '编辑家长',
  editUser: '编辑员工',
  guardianEmail: '家长邮箱',
  guardianPhone: '家长手机号',
  relationship: '关系',
  typeInEmail: '请输入邮箱!',
  typeInPhone: '请输入手机号!',
  addLocation: '添加测试地点',
  editLocation: '编辑测试地点',
  locationManage: '测试地点管理',
  updateSuccessfully: '更新成功!',
  error: '发生错误',
  sendReportSuccessfully: '发送报告成功!',
  'menu.testManagement': '测试管理',
  'menu.centerManagement': '中心管理',
  'menu.Statistics': '统计',
  'menu.Training': '训练管理',
  'menu.TrainingList': '训练计划列表',
  'menu.Class': '班级列表',
  'menu.testList': '测试列表',
  'menu.subjectList': '儿童列表',
  'menu.productList': '产品列表',
  'menu.locationManagement': '测试地点管理',
  'menu.productManagement': '产品管理',
  'menu.questionnaireManagement': '问卷管理',
  'menu.userManagement': '用户管理',
  'menu.guardianList': '家长列表',
  'menu.VRATStatistics': 'vCAT数据统计',
  'menu.VRATTestList': 'vCAT测试列表',
  'menu.operationsManagement': '运营管理',
  'menu.content': '内容查看',
  'menu.offlineTrainingPlan': '线下训练计划',
  'menu.overview': "概况总览",
  'cms.editTestCenter': '编辑测试中心',
  'cms.newTestCenter': '新建测试中心',
  testCenterInformation: '测试中心信息',
  creationTime: '创建时间',
  password: '密码',
  initialPassword: '初始密码',
  confirmPassword: '确认密码',
  setPassword: '请设置登录密码',
  displayName: '显示名称',
  picture: '图片',
  upload: '上传',
  locationInformation: '测试地点信息',
  location: '测试地点',
  newLocation: '新建测试地点',
  questionnaireList: '问卷列表',
  newQuestionnaire: '新建问卷',
  editQuestionnaire: '编辑问卷',
  language: '语言',
  type: '类型',
  english: '英文',
  chinese: '中文',
  doctor: '医生',
  jsonString: 'Json字符串',
  noContent: '无内容',
  notTestYet: '尚未测试',
  consentFormCheckedMsg: '确定家长在此测试的知情同意书上签字了吗？',
  areYouSureToChoose: '你确定要选择',
  chooseCenterType: '中心类型',
  canNotChangeLater: '你以后可能无法更改类型',
  attentionStatistics: '注意力指数(绿|黄|红)',
  motionStatistics: '动作指数(绿|黄|红)',
  correctEmailAddress: '请输入正确的邮箱地址',
  downloadAllStatistic: '下载报表',
  downloadCenterStatistic: '下载中心报表',
  editAllowQuestionnaire: '编辑可用问卷',
  questionnairePermission: '问卷可用情况',
  nameExtra: '只允许包含英文字母或者下划线',
  QRCode: '二维码',
  showQRCode: '显示二维码',
  copyToClipboard: '拷贝到剪贴板',
  modalWillBeDestroyed: '弹窗关闭倒计时',
  second: '秒',
  testQRCode: '测试二维码',
  attentionIndex: '注意力指数',
  motionIndex: '动作指数',
  'guardian.guardianList': '家长列表',
  'guardian.areYouSureToDeleteSubject': '确定要删除该儿童?',
  'guardian.canBeModified': '信息填错可以通过编辑来完成修改',
  'guardian.checkMobileNumber': "校验家长手机号 ",
  'guardian.information': "填写家长信息",
  'guardian.nameLimit': '2-30个字符',
  'guardian.atLeast1': '请添加至少1个儿童',
  'guardian.sendCode': '发送短信验证码',
  'guardian.sendCodeTips': '为家长创建Rocket baby APP 账号，手机便捷查看儿童各项报告',
  'subject.newVRATTest': '添加vCAT测试',
  pleaseEnter: '请输入',
  pleaseSelectGender: '请选择性别',
  pleaseEnterSMSCode: '请输入验证码',
  resendAfterSeconds: 's后重发',
  enterSMSCode: '填写短信验证码',
  downloadQRCode: '中心二维码',
  downloadAppQRCode: 'APP下载二维码',
  subjectDetail: '儿童详情',
  subjectInfo: '儿童信息',
  dominantHand: '惯用手',
  lastModificationTime: '最后修改时间',
  rocketAssessment: 'Rocket测评',
  vratTest: 'vCAT测试',
  newAssessment: '添加测评',
  securityCodeTips1: '如',
  securityCodeTips2: '未收到验证码',
  securityCodeTips3: ',可能是以下情况导致，请注意：',
  securityCodeTips4: '• 在[拦截/过滤短信]中；',
  securityCodeTips5: '• 所输入的手机号码不是您的；',
  securityCodeTips6: '• 信号出错，60s后再发一次试试；',
  submitTipAddUser: '提交后可在家长详情页修改家长信息',
  submitTip: '请核实信息准确后在提交! 提交后出生日期、性别等不可修改',
  subTip: '请核实信息准确后在提交!',
  subTipInfo: '提交后出生日期、性别等不可修改',
  risk: '我已了解风险',
  addSubjectSuccess: '添加儿童成功',
  platform: '平台',
  editVersionInfo: '编辑版本信息',
  addNewVersion: '添加新版本',
  termsOfService: '服务条款',
  privacyPolicy: '隐私政策',
  region: '区域',
  downloadLink: '下载连接',
  rocketReleaseList: 'Rocket发布列表',
  available: '有效',
  versionFormat: '版本号应该符合格式 a.b.c 检验正则表达式',
  qRcodeErrorAndTry: '二维码格式错误，请检查后重试',

}
