import * as Sentry from "@sentry/react";

const ignoreErrorsList = [
  'ResizeObserver loop',
  'Blocked a frame with origin',
  'NetworkError when attempting to fetch resource',
  'null is not an object',
  'Cannot read property',
  'Cannot set property',
  'please invoke this method after ready event',
  'Load failed'
]

const replay = new Sentry.Replay({
    maskAllText: false,
    maskAllInputs: false,
    networkDetailAllowUrls: [''],
    networkRequestHeaders: ['X-Custom-Header'],
    networkResponseHeaders: ['X-Custom-Header'],
});

const browserTracing = new Sentry.BrowserTracing();

Sentry.init({
    enabled: process.env.UMI_ENV === 'prod',
    dsn: process.env.sentryDsn,
    integrations: [replay, browserTracing],
    beforeSend(event, hint) {
        const errArray: string[] = [];
        const { message, exception } = event || {};
        const { values = [] } = exception || {};

        if (message) {
          errArray.push(message);
        } else {
          values.forEach(item => {
            const { value } = item || {}
            if (value) errArray.push(value);
          })
        }

        if (errArray.length && ignoreErrorsList.filter(item => errArray.filter(errItem => errItem.includes(item)).length).length) {
          return null;
        }

        // Check if it is an exception, and if so, show the report dialog
        // if (event.exception) {
        //   Sentry.showReportDialog({ eventId: event.event_id });
        // }

        setTimeout(() => {
          replay?.stop()
        }, 5000);

        return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    maxBreadcrumbs: 50
});