export default {
  'report.attentionIndex.chart.title': 'Áreas del cerebro involucradas en la atención.',
  'report.attentionIndex.title': 'Índice de Atención',
  'report.attentionSkill.attention': 'Qué tan bien usted se enfocó durante el test. Un puntaje alto significa mayor precisión.',
  'report.attentionSkill.chart.attention': 'Atención',
  'report.attentionSkill.chart.continuous': 'Atención sostenida',
  'report.attentionSkill.chart.legendU': 'Usted',
  'report.attentionSkill.chart.legendW': 'Línea de advertencia (50)',
  'report.attentionSkill.chart.reactSta': 'Estabilidad de la reacción',
  'report.attentionSkill.chart.reactivity': 'Reactividad',
  'report.attentionSkill.chart.self': 'Autocontrol',
  'report.attentionSkill.chart.sensitivity': 'Sensibilidad',
  'report.attentionSkill.chart.title': 'Seis dimensiones de las habilidades de atención',
  'report.attentionSkill.continuous': 'Cuanto tiempo puede estar focalizado durante el test. Entre más alto el puntaje, por más tiempo sostiene un nivel alto de atención.',
  'report.attentionSkill.reactSta': 'Qué tan consistentes fueron las respuestas precisas.Entre más alto el puntaje, más consistente fue uno contestando correctamente.',
  'report.attentionSkill.reactivity': 'Qué tan rápido dio la respuesta correcta. Entre más alto el puntaje, más rápida fue la reacción.',
  'report.attentionSkill.self': 'Qué tan bien usted se contuvo de apretar el botón cuando se esperaba que no lo hiciera. Entre más alto el puntaje, mayor autocontrol mostró.',
  'report.attentionSkill.sensitivity': 'Qué tan habilidoso es usted para distinguir el blanco de otros estímulos. Entre más alto el puntaje, más fuerte es su habilidad para distinguir la tarea de otros  estímulos distractores.',
  'report.attentionSkill.title': 'Habilidades de atención',
  'report.basicInfo.title': 'Reporte vCAT de Cognitive Leap',
  'report.continuous.chart.legendCA': 'Respuesta Correcta',
  'report.continuous.chart.legendCE': 'Error por comisión',
  'report.continuous.chart.legendOE': 'Error por omisión',
  'report.continuous.chart.title': 'Cambios en la atención en el tiempo',
  'report.continuous.chart.xAxis': 'Bloques',
  'report.continuous.chart.yAxis': 'Tiempos',
  'report.continuous.desc1': '3.25 minutos por bloque, 4 bloques por 3.25 minutos = 13 minutos todo el test',
  'report.continuous.desc2': 'Respuesta Correcta: Presionó el botón que debía presionar',
  'report.continuous.desc3': 'Error por Omisión: No presionó el botón que debía presionar',
  'report.continuous.desc4': 'Error por Comisión: Presionó el botón que no debe ser presionado',
  'report.continuous.notes': 'Notas:',
  'report.continuous.title': 'Atención sostenida',
  'report.directionTracking.chart.title': '¿A dónde estaba mirando?',
  'report.directionTracking.p1.desc1': 'Su tiempo de atención a la pizarra es ',
  'report.directionTracking.p1.desc2': ' minutos, más tiempo que ',
  'report.directionTracking.p1.desc3': ' de los pares',
  'report.directionTracking.p2': 'Este gráfico muestra adónde estaba usted mirando durante el test.',
  'report.directionTracking.p3': 'Entre más largas las líneas azules, más tiempo usted pasó mirando en ese dirección.',
  'report.directionTracking.p4': 'Entre más ancha el área, más se alejó su mirada del centro de la pizarra durante el test.',
  'report.directionTracking.title': 'Dirección del seguimiento',
  'report.motionCharacter.chart.title': 'Cómo se compara usted con los demás, en diferentes Índices de movimiento',
  'report.motionCharacter.hyperactivity': 'Índice de Hiperactividad',
  'report.motionCharacter.hyperactivity.desc': 'Cuánto se movió usted durante el test. Los movimientos frecuentes por largos períodos se ubican más cercanos al área roja.',
  'report.motionCharacter.motionArea': 'Índice del Área de Movimiento',
  'report.motionCharacter.motionArea.desc': 'Área de movimientos de la cabeza. Si el rango de movimientos es concentrado, el percentil se ubicará más cercano al área verde.',
  'report.motionCharacter.title': 'Características del movimiento',
  'report.motionCharacter.you': ' ',
  'report.motionIndex.chart.title': 'Áreas del cerebro involucradas en el MOVIMIENTO y el MANEJO DE LA EMOCIÓN',
  'report.motionIndex.left.p1.desc1': 'Movimiento, planeamiento de la acción, acción motivacional y procesamiento de la recompensa.',
  'report.motionIndex.left.p1.desc2': 'Si hay disfunción de los Gangleos Basales:',
  'report.motionIndex.left.p1.desc3': 'a) No puede calcular el riesgo de tener la recompensa, apropiadamente',
  'report.motionIndex.left.p1.desc4': 'b) No puede encontrar su motivación interna para ponerse en acción',
  'report.motionIndex.left.p1.title': 'Gangleos Basales:',
  'report.motionIndex.left.p2.desc1': 'Control de la inhibición de respuesta. Si este puerto está interrumpido, es difícil suprimir el impulso."',
  'report.motionIndex.left.p2.title': 'Tálamo',
  'report.motionIndex.left.p3.desc1': 'Regulación emocional, procesamiento de estímulos emocionales.',
  'report.motionIndex.left.p3.desc2': 'Si hay disfunción de la Amígdala:',
  'report.motionIndex.left.p3.desc3': 'a) no puede regular las emociones',
  'report.motionIndex.left.p3.desc4': 'b) puede mostrar rasgos de dureza de sentimientos',
  'report.motionIndex.left.p3.title': 'Amígdala:',
  'report.motionIndex.right.desc1': 'El sistema de Realidad Virtual (RV) permite medir el movimiento del usuario, durante el test. Dado que la inatención puede ser expresada junto con la hiperactividad y la impulsividad, los datos del movimiento del usuario, ayudan a identificar qué tan a menudo estuvo moviendo su cuerpo, si estuvo inquieto y si no estuvo poniendo atención durante la tarea que debía realizar.',
  'report.motionIndex.right.desc2': 'Los datos del movimiento se toman de la cabeza y de la mano y se calcula un Índice Total del Desempeño del Movimiento del usuario.',
  'report.motionIndex.title': 'Índice de movimiento',
  'report.movementPath.chart.des': 'El largo de la estructura verde es el mismo que el escritorio en el aula de realidad virtual.',
  'report.movementPath.chart.hand': 'Mano',
  'report.movementPath.chart.head': 'Cabeza',
  'report.movementPath.chart.title': 'La trayectoria de movimiento de diferentes posiciones cambia a lo largo del tiempo',
  'report.movementPath.chart.xAxis': 'Bloques',
  'report.movementPath.chart.yAxis': 'Trayectoria de Movimiento',
  'report.movementPath.title': 'Trayectoria de Movimiento',
  'report.offlinePlanInfo.cancel.title': '¿Está seguro que desea finalizar la sesión?',
  'report.offlinePlanInfo.sure.title': '¿Desea diseñar el plan de entrenamiento para el usuario?',
  'report.overview.api': 'Índice de Atención (ÍA)',
  'report.overview.api.desc1': 'El IA (Índice de Atención) revela el desempeño de la atención, en relación con su grupo de edad y de género. Se deriva de los resultados de los 13 minutos del Test de Desempeño Continuo (CPT).',
  'report.overview.api.desc2': 'Entre más alto el puntaje, mejor es el desempeño para mantenerse focalizado durante el test.',
  'report.overview.api.desc3_1': 'El Índice de Atención indica ',
  'report.overview.api.desc3_2': ' que el desempeño de su atención es mejor que ',
  'report.overview.api.desc3_3': ' el de su grupo de edad y género.',
  'report.overview.mpi': 'El Índice del Desempeño del Movimiento (IDM)',
  'report.overview.mpi.desc1': 'EL IDM revela el grado de hiperactividad, en relación con su grupo de edad y género y es calculado con datos del movimiento de la cabeza y la mano.',
  'report.overview.mpi.desc2': 'Entre más alto el puntaje, menos se movió durante el test; o sea, menos hiperactividad.',
  'report.overview.mpi.desc3': 'El Índice de Desempeño del Movimiento es 83, indicando que el desempeño del movimiento es mejor que el del 83% de su grupo de edad y género.',
  'report.overview.mpi.desc3_1': 'El Índice de Desempeño del Movimiento indica ',
  'report.overview.mpi.desc3_2': ' que su desempeño del movimiento es mejor que ',
  'report.overview.mpi.desc3_3': ' el de su grupo de edad y género.',
  'report.overview.title': 'Resumen',
  'report.pathLength.chart.legendM': 'Mediana',
  'report.pathLength.chart.legendU': 'Usted',
  'report.pathLength.chart.subtitle': ' cambios del movimiento a lo largo del tiempo',
  'report.pathLength.chart.xAxis': 'Bloques',
  'report.pathLength.chart.yAxis': 'Longitud de la trayectoria',
  'report.pathLength.hand': 'Mano',
  'report.pathLength.head': 'Cabeza',
  'report.pathLength.percentile': 'Percentil: ',
  'report.pathLength.title': 'Longitud de la trayectoria',
  'report.realTime.chart.legend': 'Qué tanto se movió el niño a lo largo del tiempo',
  'report.realTime.chart.title': 'Desempeño de la Atención y del Movimiento, a través del tiempo',
  'report.realTime.chart.xAxis': 'Minutos ',
  'report.realTime.chart.yAxisl': 'Tiempo de Reacción (ms)',
  'report.realTime.chart.yAxisr': 'Índice de Movimiento',
  'report.realTime.desc1': 'Aciertos:',
  'report.realTime.desc2': 'Cantidad de tiempo que el niño no puso atención durante el test.',
  'report.realTime.desc3': 'Cantidad de tiempo que el niño se mostró impulsivo y dio una respuesta, incluso cuando no había nada que contestar.',
  'report.realTime.title': 'Desempeño en Tiempo Real',
}