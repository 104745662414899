// @ts-nocheck
import { Component } from 'react';
import { ApplyPluginsType } from 'umi';
import dva from 'dva';
// @ts-ignore
import createLoading from '/cl_base/node_modules/.pnpm/dva-loading@3.0.25_dva-core@2.0.4_redux@4.2.1_/node_modules/dva-loading/dist/index.esm.js';
import { plugin, history } from '../core/umiExports';
import dvaImmer, { enableES5, enableAllPlugins } from '/cl_base/node_modules/.pnpm/dva-immer@0.5.2_dva@2.6.0-beta.23_react-dom@17.0.2_react@17.0.2__react-router@5.2.0_react@16.14.0__react@17.0.2_/node_modules/dva-immer/dist/index.js';

let app:any = null;

export async function _onCreate(options = {}) {
  const runtimeDva = plugin.applyPlugins({
    key: 'dva',
    type: ApplyPluginsType.modify,
    initialValue: {},
  });
  const ModelGlobal0 = (await import('/cl_base/src/models/global.js')).default;
const ModelMusic1 = (await import('/cl_base/src/models/music.js')).default;
const ModelNotification2 = (await import('/cl_base/src/models/notification.js')).default;
const ModelUser3 = (await import('/cl_base/src/models/user.js')).default;
const ModelAdminChildrenAnalysis4 = (await import('/cl_base/src/pages/Admin/Analysis/ChildrenAnalysis/models/adminChildrenAnalysis.js')).default;
const ModelAdminGuardianAnalysis5 = (await import('/cl_base/src/pages/Admin/Analysis/GuardianAnalysis/models/adminGuardianAnalysis.js')).default;
const ModelILsAnalysis6 = (await import('/cl_base/src/pages/Admin/Analysis/iLsAnalysis/models/iLsAnalysis.js')).default;
const ModelUserClassAnalysis7 = (await import('/cl_base/src/pages/Admin/Analysis/UserClassAnalysis/models/userClassAnalysis.js')).default;
const ModelVratAnalysis8 = (await import('/cl_base/src/pages/Admin/Analysis/VRATAnalysis/models/vratAnalysis.js')).default;
const ModelBVersion9 = (await import('/cl_base/src/pages/Admin/Application/BVersion/models/bVersion.js')).default;
const ModelRocketVersion10 = (await import('/cl_base/src/pages/Admin/Application/RocketVersion/models/rocketVersion.js')).default;
const ModelApplyReview11 = (await import('/cl_base/src/pages/Admin/ApplyReview/models/applyReview.js')).default;
const ModelAreaList12 = (await import('/cl_base/src/pages/Admin/AreaList/models/areaList.js')).default;
const ModelAssessment13 = (await import('/cl_base/src/pages/Admin/Assessment/Assessment/models/assessment.js')).default;
const ModelEditReport14 = (await import('/cl_base/src/pages/Admin/Assessment/EditReport/models/editReport.js')).default;
const ModelQuestionnaire15 = (await import('/cl_base/src/pages/Admin/Assessment/Questionnaire/models/questionnaire.js')).default;
const ModelAuthorCenter16 = (await import('/cl_base/src/pages/Admin/AuthorCenter/models/authorCenter.js')).default;
const ModelCmsCaseList17 = (await import('/cl_base/src/pages/Admin/Case/models/cmsCaseList.js')).default;
const ModelCenterList18 = (await import('/cl_base/src/pages/Admin/CenterList/models/centerList.js')).default;
const ModelChangePassword19 = (await import('/cl_base/src/pages/Admin/ChangePassword/models/changePassword.js')).default;
const ModelBanner20 = (await import('/cl_base/src/pages/Admin/Content/Course/Banner/models/banner.js')).default;
const ModelCourse21 = (await import('/cl_base/src/pages/Admin/Content/Course/Course/models/course.js')).default;
const ModelCourseCategory22 = (await import('/cl_base/src/pages/Admin/Content/Course/CourseCategory/models/courseCategory.js')).default;
const ModelCourseItem23 = (await import('/cl_base/src/pages/Admin/Content/Course/CourseItem/models/courseItem.js')).default;
const ModelMeritMedal24 = (await import('/cl_base/src/pages/Admin/Content/Course/MeritMedal/models/meritMedal.js')).default;
const ModelTeacher25 = (await import('/cl_base/src/pages/Admin/Content/Course/Teacher/models/teacher.js')).default;
const ModelTrainingCourses26 = (await import('/cl_base/src/pages/Admin/Content/Course/TrainingCourses/models/trainingCourses.js')).default;
const ModelTrainingModule27 = (await import('/cl_base/src/pages/Admin/Content/Course/TrainingModule/models/trainingModule.js')).default;
const ModelCategory28 = (await import('/cl_base/src/pages/Admin/Content/Project/Category/models/category.js')).default;
const ModelFeedbackProperty29 = (await import('/cl_base/src/pages/Admin/Content/Project/FeedbackProperty/models/feedbackProperty.js')).default;
const ModelMaterial30 = (await import('/cl_base/src/pages/Admin/Content/Project/Material/models/material.js')).default;
const ModelOfflinePlan31 = (await import('/cl_base/src/pages/Admin/Content/Project/newOfflinePlan/models/offlinePlan.js')).default;
const ModelOfflinePlan32 = (await import('/cl_base/src/pages/Admin/Content/Project/OfflinePlan/models/offlinePlan.js')).default;
const ModelTarget33 = (await import('/cl_base/src/pages/Admin/Content/Project/Target/models/target.js')).default;
const ModelTrack34 = (await import('/cl_base/src/pages/Admin/Content/Project/Track/models/track.js')).default;
const ModelTrainingBlock35 = (await import('/cl_base/src/pages/Admin/Content/Project/TrainingBlock/models/trainingBlock.js')).default;
const ModelTrainingCourse36 = (await import('/cl_base/src/pages/Admin/Content/Project/TrainingCourse/models/trainingCourse.js')).default;
const ModelTrainingPlan37 = (await import('/cl_base/src/pages/Admin/Content/Project/TrainingPlan/models/trainingPlan.js')).default;
const ModelTrainingProgram38 = (await import('/cl_base/src/pages/Admin/Content/Project/TrainingProgram/models/trainingProgram.js')).default;
const ModelReminder39 = (await import('/cl_base/src/pages/Admin/Content/Reminder/models/reminder.js')).default;
const ModelScenario40 = (await import('/cl_base/src/pages/Admin/Content/Scenario/models/scenario.js')).default;
const ModelTag41 = (await import('/cl_base/src/pages/Admin/Content/Tag/models/tag.js')).default;
const ModelCenterDetail42 = (await import('/cl_base/src/pages/Admin/Detail/CenterDetail/models/centerDetail.js')).default;
const ModelClassCardDetail43 = (await import('/cl_base/src/pages/Admin/Detail/ClassCardDetail/models/classCardDetail.js')).default;
const ModelCourseCategoryDetail44 = (await import('/cl_base/src/pages/Admin/Detail/CourseCategoryDetail/models/courseCategoryDetail.js')).default;
const ModelCourseDetail45 = (await import('/cl_base/src/pages/Admin/Detail/CourseDetail/models/courseDetail.js')).default;
const ModelGroupOfflinePlanDetail46 = (await import('/cl_base/src/pages/Admin/Detail/GroupOfflinePlanDetail/models/groupOfflinePlanDetail.js')).default;
const ModelHfsOrderDetail47 = (await import('/cl_base/src/pages/Admin/Detail/HfsOrderDetail/models/hfsOrderDetail.js')).default;
const ModelOfflinePlanDetail48 = (await import('/cl_base/src/pages/Admin/Detail/OfflinePlanDetail/models/offlinePlanDetail.js')).default;
const ModelPlanDetail49 = (await import('/cl_base/src/pages/Admin/Detail/PlanDetail/models/planDetail.js')).default;
const ModelAdminClockInRecord50 = (await import('/cl_base/src/pages/Admin/Detail/SubjectDetail/models/adminClockInRecord.js')).default;
const ModelAssessment51 = (await import('/cl_base/src/pages/Admin/Detail/SubjectDetail/models/assessment.js')).default;
const ModelExercise52 = (await import('/cl_base/src/pages/Admin/Detail/SubjectDetail/models/exercise.js')).default;
const ModelOfflinePlan53 = (await import('/cl_base/src/pages/Admin/Detail/SubjectDetail/models/offlinePlan.js')).default;
const ModelSubjectDetail54 = (await import('/cl_base/src/pages/Admin/Detail/SubjectDetail/models/subjectDetail.js')).default;
const ModelSubjectNutrition55 = (await import('/cl_base/src/pages/Admin/Detail/SubjectDetail/models/subjectNutrition.js')).default;
const ModelSubjectSleep56 = (await import('/cl_base/src/pages/Admin/Detail/SubjectDetail/models/subjectSleep.js')).default;
const ModelVrat57 = (await import('/cl_base/src/pages/Admin/Detail/SubjectDetail/models/vrat.js')).default;
const ModelSuperviseList58 = (await import('/cl_base/src/pages/Admin/Detail/SuperviseList/models/superviseList.js')).default;
const ModelTrainingCourseDetail59 = (await import('/cl_base/src/pages/Admin/Detail/TrainingCourseDetail/models/trainingCourseDetail.js')).default;
const ModelTrainingStaffDetail60 = (await import('/cl_base/src/pages/Admin/Detail/TrainingStaffDetail/models/trainingStaffDetail.js')).default;
const ModelUserDetail61 = (await import('/cl_base/src/pages/Admin/Detail/UserDetail/models/userDetail.js')).default;
const ModelUserOfflinePlanDetail62 = (await import('/cl_base/src/pages/Admin/Detail/UserOfflinePlanDetail/models/userOfflinePlanDetail.js')).default;
const ModelVratCenterDetail63 = (await import('/cl_base/src/pages/Admin/Detail/VratCenterDetail/models/vratCenterDetail.js')).default;
const ModelHfsOrder64 = (await import('/cl_base/src/pages/Admin/Hfs/Order/models/hfsOrder.js')).default;
const ModelMedalList65 = (await import('/cl_base/src/pages/Admin/MedalList/models/medalList.js')).default;
const ModelOnline66 = (await import('/cl_base/src/pages/Admin/Online/models/online.js')).default;
const ModelOrgList67 = (await import('/cl_base/src/pages/Admin/OrgList/models/orgList.js')).default;
const ModelQuestionList68 = (await import('/cl_base/src/pages/Admin/Questionnaire/models/questionList.js')).default;
const ModelQuestionnaireList69 = (await import('/cl_base/src/pages/Admin/QuestionnaireList/models/questionnaireList.js')).default;
const ModelSchoolList70 = (await import('/cl_base/src/pages/Admin/SchoolList/models/schoolList.js')).default;
const ModelCmsStatistic71 = (await import('/cl_base/src/pages/Admin/Statistic/models/cmsStatistic.js')).default;
const ModelSupervisorList72 = (await import('/cl_base/src/pages/Admin/TrainerSupervisorList/models/supervisorList.js')).default;
const ModelTrainingStaffList73 = (await import('/cl_base/src/pages/Admin/TrainingStaffList/models/trainingStaffList.js')).default;
const ModelSubjectList74 = (await import('/cl_base/src/pages/Admin/User/Subject/models/subjectList.js')).default;
const ModelUserlist75 = (await import('/cl_base/src/pages/Admin/User/User/models/userlist.js')).default;
const ModelStaffList76 = (await import('/cl_base/src/pages/Admin/UserList/models/staffList.js')).default;
const ModelCenterListArea77 = (await import('/cl_base/src/pages/Area/Center/models/centerListArea.js')).default;
const ModelPersonalCenter78 = (await import('/cl_base/src/pages/Center/Account/PersonalCenter/models/personalCenter.js')).default;
const ModelSettings79 = (await import('/cl_base/src/pages/Center/Account/Settings/models/settings.js')).default;
const ModelChildrenAnalysis80 = (await import('/cl_base/src/pages/Center/Analysis/ChildrenAnalysis/models/childrenAnalysis.js')).default;
const ModelGuardianAnalysis81 = (await import('/cl_base/src/pages/Center/Analysis/GuardianAnalysis/models/guardianAnalysis.js')).default;
const ModelILsAnalysis82 = (await import('/cl_base/src/pages/Center/Analysis/iLsAnalysis/models/iLsAnalysis.js')).default;
const ModelUserClassAnalysis83 = (await import('/cl_base/src/pages/Center/Analysis/UserClassAnalysis/models/userClassAnalysis.js')).default;
const ModelStatistics84 = (await import('/cl_base/src/pages/Center/Analysis/VRATAnalysis/models/statistics.js')).default;
const ModelVratAnalysis85 = (await import('/cl_base/src/pages/Center/Analysis/VRATAnalysis/models/vratAnalysis.js')).default;
const ModelApplyExamine86 = (await import('/cl_base/src/pages/Center/ApplyExamine/models/applyExamine.js')).default;
const ModelClassCard87 = (await import('/cl_base/src/pages/Center/Detail/ClassCardDetail/models/classCard.js')).default;
const ModelCoursewareDetail88 = (await import('/cl_base/src/pages/Center/Detail/CoursewareDetail/models/coursewareDetail.js')).default;
const ModelGroupDetail89 = (await import('/cl_base/src/pages/Center/Detail/GroupDetail/models/groupDetail.js')).default;
const ModelGroupOfflinePlanDetail90 = (await import('/cl_base/src/pages/Center/Detail/GroupOfflinePlanDetail/models/groupOfflinePlanDetail.js')).default;
const ModelHfsOrderDetail91 = (await import('/cl_base/src/pages/Center/Detail/HfsOrderDetail/models/hfsOrderDetail.js')).default;
const ModelMultiCheck92 = (await import('/cl_base/src/pages/Center/Detail/MultiCheck/models/multiCheck.js')).default;
const ModelOfflinePlanDetail93 = (await import('/cl_base/src/pages/Center/Detail/OfflinePlanDetail/models/offlinePlanDetail.js')).default;
const ModelCenterClockInRecord94 = (await import('/cl_base/src/pages/Center/Detail/SubjectDetail/models/centerClockInRecord.js')).default;
const ModelExercise95 = (await import('/cl_base/src/pages/Center/Detail/SubjectDetail/models/exercise.js')).default;
const ModelNutrition96 = (await import('/cl_base/src/pages/Center/Detail/SubjectDetail/models/nutrition.js')).default;
const ModelOfflinePlan97 = (await import('/cl_base/src/pages/Center/Detail/SubjectDetail/models/offlinePlan.js')).default;
const ModelSleep98 = (await import('/cl_base/src/pages/Center/Detail/SubjectDetail/models/sleep.js')).default;
const ModelSubjectAssessment99 = (await import('/cl_base/src/pages/Center/Detail/SubjectDetail/models/subjectAssessment.js')).default;
const ModelSubjectDetail100 = (await import('/cl_base/src/pages/Center/Detail/SubjectDetail/models/subjectDetail.js')).default;
const ModelSubjectVrat101 = (await import('/cl_base/src/pages/Center/Detail/SubjectDetail/models/subjectVrat.js')).default;
const ModelTrainingDetail102 = (await import('/cl_base/src/pages/Center/Detail/TrainingDetail/models/trainingDetail.js')).default;
const ModelTrainingMaterial103 = (await import('/cl_base/src/pages/Center/Detail/TrainingMaterial/models/trainingMaterial.js')).default;
const ModelTrainingStaffDetail104 = (await import('/cl_base/src/pages/Center/Detail/TrainingStaffDetail/models/trainingStaffDetail.js')).default;
const ModelUserDetail105 = (await import('/cl_base/src/pages/Center/Detail/UserDetail/models/userDetail.js')).default;
const ModelUserOfflinePlanDetail106 = (await import('/cl_base/src/pages/Center/Detail/UserOfflinePlanDetail/models/userOfflinePlanDetail.js')).default;
const ModelHfsCost107 = (await import('/cl_base/src/pages/Center/Hfs/Cost/models/hfsCost.js')).default;
const ModelHfsOrder108 = (await import('/cl_base/src/pages/Center/Hfs/Order/models/hfsOrder.js')).default;
const ModelLocationList109 = (await import('/cl_base/src/pages/Center/LocationList/models/locationList.js')).default;
const ModelNotificationCenter110 = (await import('/cl_base/src/pages/Center/Notification/models/notificationCenter.js')).default;
const ModelOfflinePlan111 = (await import('/cl_base/src/pages/Center/Plan/OfflinePlan/models/offlinePlan.js')).default;
const ModelTrainingPlan112 = (await import('/cl_base/src/pages/Center/Plan/TrainingPlan/models/trainingPlan.js')).default;
const ModelProductList113 = (await import('/cl_base/src/pages/Center/ProductList/models/productList.js')).default;
const ModelQuestionnaireList114 = (await import('/cl_base/src/pages/Center/QuestionnaireList/models/questionnaireList.js')).default;
const ModelSubjectList115 = (await import('/cl_base/src/pages/Center/SubjectList/models/subjectList.js')).default;
const ModelTestList116 = (await import('/cl_base/src/pages/Center/TestList/models/testList.js')).default;
const ModelTrainerDatabase117 = (await import('/cl_base/src/pages/Center/TrainerDatabase/models/trainerDatabase.js')).default;
const ModelTrainingStaff118 = (await import('/cl_base/src/pages/Center/TrainingStaffList/models/trainingStaff.js')).default;
const ModelUserlist119 = (await import('/cl_base/src/pages/Center/UserList/models/userlist.js')).default;
const ModelActivate120 = (await import('/cl_base/src/pages/Common/Activate/models/activate.js')).default;
const ModelAddUser121 = (await import('/cl_base/src/pages/Common/AddUser/models/addUser.js')).default;
const ModelArchives122 = (await import('/cl_base/src/pages/Common/Archives/models/archives.js')).default;
const ModelCallback123 = (await import('/cl_base/src/pages/Common/Callback/models/callback.js')).default;
const ModelCompare124 = (await import('/cl_base/src/pages/Common/Compare/models/compare.js')).default;
const ModelUserOfflineCourseDetail125 = (await import('/cl_base/src/pages/Common/Detail/UserOfflineCourseDetail/models/userOfflineCourseDetail.js')).default;
const ModelGroup126 = (await import('/cl_base/src/pages/Common/Group/models/group.js')).default;
const ModelGroupDetail127 = (await import('/cl_base/src/pages/Common/GroupDetail/models/groupDetail.js')).default;
const ModelHfsTrainingRecord128 = (await import('/cl_base/src/pages/Common/HfsTrainingRecord/models/hfsTrainingRecord.js')).default;
const ModelHfsTrainingRecord129 = (await import('/cl_base/src/pages/Common/HfsTwoTrainingRecord/models/hfsTrainingRecord.js')).default;
const ModelMedicalChnVratReport130 = (await import('/cl_base/src/pages/Common/medicalChnVratReport/models/medicalChnVratReport.js')).default;
const ModelMedicalVratReport131 = (await import('/cl_base/src/pages/Common/medicalVratReport/models/medicalVratReport.js')).default;
const ModelParentReport132 = (await import('/cl_base/src/pages/Common/ParentReport/models/parentReport.js')).default;
const ModelQuestionnaire133 = (await import('/cl_base/src/pages/Common/Questionnaire/models/questionnaire.js')).default;
const ModelQuestionnaireView134 = (await import('/cl_base/src/pages/Common/QuestionnaireView/models/questionnaireView.js')).default;
const ModelReport2135 = (await import('/cl_base/src/pages/Common/Report/models/report2.js')).default;
const ModelIndex136 = (await import('/cl_base/src/pages/Common/ShowVideo/models/index.js')).default;
const ModelTestDetail137 = (await import('/cl_base/src/pages/Common/TestDetail/models/testDetail.js')).default;
const ModelILsAnalysis138 = (await import('/cl_base/src/pages/Common/TrainList/models/iLsAnalysis.js')).default;
const ModelUpdateEmail139 = (await import('/cl_base/src/pages/Common/UpdateEmail/models/updateEmail.js')).default;
const ModelCenterList140 = (await import('/cl_base/src/pages/Enterprise/Center/models/centerList.js')).default;
  app = dva({
    history,
    
    ...(runtimeDva.config || {}),
    // @ts-ignore
    ...(typeof window !== 'undefined' && window.g_useSSR ? { initialState: window.g_initialProps } : {}),
    ...(options || {}),
  });
  
  app.use(createLoading());
  app.use(dvaImmer());
  (runtimeDva.plugins || []).forEach((plugin:any) => {
    app.use(plugin);
  });
  app.model({ namespace: 'global', ...ModelGlobal0 });
app.model({ namespace: 'music', ...ModelMusic1 });
app.model({ namespace: 'notification', ...ModelNotification2 });
app.model({ namespace: 'user', ...ModelUser3 });
app.model({ namespace: 'adminChildrenAnalysis', ...ModelAdminChildrenAnalysis4 });
app.model({ namespace: 'adminGuardianAnalysis', ...ModelAdminGuardianAnalysis5 });
app.model({ namespace: 'iLsAnalysis', ...ModelILsAnalysis6 });
app.model({ namespace: 'userClassAnalysis', ...ModelUserClassAnalysis7 });
app.model({ namespace: 'vratAnalysis', ...ModelVratAnalysis8 });
app.model({ namespace: 'bVersion', ...ModelBVersion9 });
app.model({ namespace: 'rocketVersion', ...ModelRocketVersion10 });
app.model({ namespace: 'applyReview', ...ModelApplyReview11 });
app.model({ namespace: 'areaList', ...ModelAreaList12 });
app.model({ namespace: 'assessment', ...ModelAssessment13 });
app.model({ namespace: 'editReport', ...ModelEditReport14 });
app.model({ namespace: 'questionnaire', ...ModelQuestionnaire15 });
app.model({ namespace: 'authorCenter', ...ModelAuthorCenter16 });
app.model({ namespace: 'cmsCaseList', ...ModelCmsCaseList17 });
app.model({ namespace: 'centerList', ...ModelCenterList18 });
app.model({ namespace: 'changePassword', ...ModelChangePassword19 });
app.model({ namespace: 'banner', ...ModelBanner20 });
app.model({ namespace: 'course', ...ModelCourse21 });
app.model({ namespace: 'courseCategory', ...ModelCourseCategory22 });
app.model({ namespace: 'courseItem', ...ModelCourseItem23 });
app.model({ namespace: 'meritMedal', ...ModelMeritMedal24 });
app.model({ namespace: 'teacher', ...ModelTeacher25 });
app.model({ namespace: 'trainingCourses', ...ModelTrainingCourses26 });
app.model({ namespace: 'trainingModule', ...ModelTrainingModule27 });
app.model({ namespace: 'category', ...ModelCategory28 });
app.model({ namespace: 'feedbackProperty', ...ModelFeedbackProperty29 });
app.model({ namespace: 'material', ...ModelMaterial30 });
app.model({ namespace: 'offlinePlan', ...ModelOfflinePlan31 });
app.model({ namespace: 'offlinePlan', ...ModelOfflinePlan32 });
app.model({ namespace: 'target', ...ModelTarget33 });
app.model({ namespace: 'track', ...ModelTrack34 });
app.model({ namespace: 'trainingBlock', ...ModelTrainingBlock35 });
app.model({ namespace: 'trainingCourse', ...ModelTrainingCourse36 });
app.model({ namespace: 'trainingPlan', ...ModelTrainingPlan37 });
app.model({ namespace: 'trainingProgram', ...ModelTrainingProgram38 });
app.model({ namespace: 'reminder', ...ModelReminder39 });
app.model({ namespace: 'scenario', ...ModelScenario40 });
app.model({ namespace: 'tag', ...ModelTag41 });
app.model({ namespace: 'centerDetail', ...ModelCenterDetail42 });
app.model({ namespace: 'classCardDetail', ...ModelClassCardDetail43 });
app.model({ namespace: 'courseCategoryDetail', ...ModelCourseCategoryDetail44 });
app.model({ namespace: 'courseDetail', ...ModelCourseDetail45 });
app.model({ namespace: 'groupOfflinePlanDetail', ...ModelGroupOfflinePlanDetail46 });
app.model({ namespace: 'hfsOrderDetail', ...ModelHfsOrderDetail47 });
app.model({ namespace: 'offlinePlanDetail', ...ModelOfflinePlanDetail48 });
app.model({ namespace: 'planDetail', ...ModelPlanDetail49 });
app.model({ namespace: 'adminClockInRecord', ...ModelAdminClockInRecord50 });
app.model({ namespace: 'assessment', ...ModelAssessment51 });
app.model({ namespace: 'exercise', ...ModelExercise52 });
app.model({ namespace: 'offlinePlan', ...ModelOfflinePlan53 });
app.model({ namespace: 'subjectDetail', ...ModelSubjectDetail54 });
app.model({ namespace: 'subjectNutrition', ...ModelSubjectNutrition55 });
app.model({ namespace: 'subjectSleep', ...ModelSubjectSleep56 });
app.model({ namespace: 'vrat', ...ModelVrat57 });
app.model({ namespace: 'superviseList', ...ModelSuperviseList58 });
app.model({ namespace: 'trainingCourseDetail', ...ModelTrainingCourseDetail59 });
app.model({ namespace: 'trainingStaffDetail', ...ModelTrainingStaffDetail60 });
app.model({ namespace: 'userDetail', ...ModelUserDetail61 });
app.model({ namespace: 'userOfflinePlanDetail', ...ModelUserOfflinePlanDetail62 });
app.model({ namespace: 'vratCenterDetail', ...ModelVratCenterDetail63 });
app.model({ namespace: 'hfsOrder', ...ModelHfsOrder64 });
app.model({ namespace: 'medalList', ...ModelMedalList65 });
app.model({ namespace: 'online', ...ModelOnline66 });
app.model({ namespace: 'orgList', ...ModelOrgList67 });
app.model({ namespace: 'questionList', ...ModelQuestionList68 });
app.model({ namespace: 'questionnaireList', ...ModelQuestionnaireList69 });
app.model({ namespace: 'schoolList', ...ModelSchoolList70 });
app.model({ namespace: 'cmsStatistic', ...ModelCmsStatistic71 });
app.model({ namespace: 'supervisorList', ...ModelSupervisorList72 });
app.model({ namespace: 'trainingStaffList', ...ModelTrainingStaffList73 });
app.model({ namespace: 'subjectList', ...ModelSubjectList74 });
app.model({ namespace: 'userlist', ...ModelUserlist75 });
app.model({ namespace: 'staffList', ...ModelStaffList76 });
app.model({ namespace: 'centerListArea', ...ModelCenterListArea77 });
app.model({ namespace: 'personalCenter', ...ModelPersonalCenter78 });
app.model({ namespace: 'settings', ...ModelSettings79 });
app.model({ namespace: 'childrenAnalysis', ...ModelChildrenAnalysis80 });
app.model({ namespace: 'guardianAnalysis', ...ModelGuardianAnalysis81 });
app.model({ namespace: 'iLsAnalysis', ...ModelILsAnalysis82 });
app.model({ namespace: 'userClassAnalysis', ...ModelUserClassAnalysis83 });
app.model({ namespace: 'statistics', ...ModelStatistics84 });
app.model({ namespace: 'vratAnalysis', ...ModelVratAnalysis85 });
app.model({ namespace: 'applyExamine', ...ModelApplyExamine86 });
app.model({ namespace: 'classCard', ...ModelClassCard87 });
app.model({ namespace: 'coursewareDetail', ...ModelCoursewareDetail88 });
app.model({ namespace: 'groupDetail', ...ModelGroupDetail89 });
app.model({ namespace: 'groupOfflinePlanDetail', ...ModelGroupOfflinePlanDetail90 });
app.model({ namespace: 'hfsOrderDetail', ...ModelHfsOrderDetail91 });
app.model({ namespace: 'multiCheck', ...ModelMultiCheck92 });
app.model({ namespace: 'offlinePlanDetail', ...ModelOfflinePlanDetail93 });
app.model({ namespace: 'centerClockInRecord', ...ModelCenterClockInRecord94 });
app.model({ namespace: 'exercise', ...ModelExercise95 });
app.model({ namespace: 'nutrition', ...ModelNutrition96 });
app.model({ namespace: 'offlinePlan', ...ModelOfflinePlan97 });
app.model({ namespace: 'sleep', ...ModelSleep98 });
app.model({ namespace: 'subjectAssessment', ...ModelSubjectAssessment99 });
app.model({ namespace: 'subjectDetail', ...ModelSubjectDetail100 });
app.model({ namespace: 'subjectVrat', ...ModelSubjectVrat101 });
app.model({ namespace: 'trainingDetail', ...ModelTrainingDetail102 });
app.model({ namespace: 'trainingMaterial', ...ModelTrainingMaterial103 });
app.model({ namespace: 'trainingStaffDetail', ...ModelTrainingStaffDetail104 });
app.model({ namespace: 'userDetail', ...ModelUserDetail105 });
app.model({ namespace: 'userOfflinePlanDetail', ...ModelUserOfflinePlanDetail106 });
app.model({ namespace: 'hfsCost', ...ModelHfsCost107 });
app.model({ namespace: 'hfsOrder', ...ModelHfsOrder108 });
app.model({ namespace: 'locationList', ...ModelLocationList109 });
app.model({ namespace: 'notificationCenter', ...ModelNotificationCenter110 });
app.model({ namespace: 'offlinePlan', ...ModelOfflinePlan111 });
app.model({ namespace: 'trainingPlan', ...ModelTrainingPlan112 });
app.model({ namespace: 'productList', ...ModelProductList113 });
app.model({ namespace: 'questionnaireList', ...ModelQuestionnaireList114 });
app.model({ namespace: 'subjectList', ...ModelSubjectList115 });
app.model({ namespace: 'testList', ...ModelTestList116 });
app.model({ namespace: 'trainerDatabase', ...ModelTrainerDatabase117 });
app.model({ namespace: 'trainingStaff', ...ModelTrainingStaff118 });
app.model({ namespace: 'userlist', ...ModelUserlist119 });
app.model({ namespace: 'activate', ...ModelActivate120 });
app.model({ namespace: 'addUser', ...ModelAddUser121 });
app.model({ namespace: 'archives', ...ModelArchives122 });
app.model({ namespace: 'callback', ...ModelCallback123 });
app.model({ namespace: 'compare', ...ModelCompare124 });
app.model({ namespace: 'userOfflineCourseDetail', ...ModelUserOfflineCourseDetail125 });
app.model({ namespace: 'group', ...ModelGroup126 });
app.model({ namespace: 'groupDetail', ...ModelGroupDetail127 });
app.model({ namespace: 'hfsTrainingRecord', ...ModelHfsTrainingRecord128 });
app.model({ namespace: 'hfsTrainingRecord', ...ModelHfsTrainingRecord129 });
app.model({ namespace: 'medicalChnVratReport', ...ModelMedicalChnVratReport130 });
app.model({ namespace: 'medicalVratReport', ...ModelMedicalVratReport131 });
app.model({ namespace: 'parentReport', ...ModelParentReport132 });
app.model({ namespace: 'questionnaire', ...ModelQuestionnaire133 });
app.model({ namespace: 'questionnaireView', ...ModelQuestionnaireView134 });
app.model({ namespace: 'report2', ...ModelReport2135 });
app.model({ namespace: 'index', ...ModelIndex136 });
app.model({ namespace: 'testDetail', ...ModelTestDetail137 });
app.model({ namespace: 'iLsAnalysis', ...ModelILsAnalysis138 });
app.model({ namespace: 'updateEmail', ...ModelUpdateEmail139 });
app.model({ namespace: 'centerList', ...ModelCenterList140 });
  return app;
}

export function getApp() {
  return app;
}

/**
 * whether browser env
 * 
 * @returns boolean
 */
function isBrowser(): boolean {
  return typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
}

export class _DvaContainer extends Component {
  constructor(props: any) {
    super(props);
    // run only in client, avoid override server _onCreate()
    if (isBrowser()) {
      _onCreate()
        .then(() => {
          // force update
          this.forceUpdate();
        });
    }
  }

  componentWillUnmount() {
    let app = getApp();
    app._models.forEach((model:any) => {
      app.unmodel(model.namespace);
    });
    app._models = [];
    try {
      // 释放 app，for gc
      // immer 场景 app 是 read-only 的，这里 try catch 一下
      app = null;
    } catch(e) {
      console.error(e);
    }
  }

  render() {
    let app = getApp();
    if (!app) {
      return null;
    }
    app.router(() => this.props.children);
    return app.start()();
  }
}
